import { usePermissions } from "react-admin";
import { Grid } from "@mui/material";
import CharMonthsFinancial from "../components/charts/chart-months-financial";
import ChartMonthsClients from "../components/charts/chart-months-clients";
import EnterpriseChartsMonthsClientsPerRegion from "../components/charts/chart-months-clients-per-region";
import EnterpriseChartsMonthsClientsPerNiche from "../components/charts/chart-months-clients-per-niche";
import ChartMonthsContracts from "../components/charts/chart-months-contracts";
import ChartMonthsContractsPerBillingPeriod from "../components/charts/chart-months-contracts-per-billing-period";
import ChartMonthsWithdrawalsPerType from "../components/charts/chart-months-withdrawals-per-type";
import ChartMonthsWithdrawalsPerCustomer from "../components/charts/chart-months-withdrawals-per-customer";
import ChartMonthsInvoicesPerType from "../components/charts/chart-months-invoices-per-type";
import config from "../config";

const EnterpriseChartsMonthsDashboard = () =>
{
  const { permissions } = usePermissions();

  // VERIFY IS ENTERPRISE AND HAS PERMISSION
  if (permissions && permissions.type_user === config.type_user.enterprise)
  {
    return (
      <Grid container rowSpacing={2} columnSpacing={2}>
        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.financial)) ?
          <Grid item xs={12} sm={12}>
            <CharMonthsFinancial />
          </Grid> : null
        }

        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.clients)) ?
          <Grid item xs={12} sm={12}>
            <ChartMonthsClients />
          </Grid> : null
        }

        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.clients_per_region)) ?
          <Grid item xs={12} sm={12}>
            <EnterpriseChartsMonthsClientsPerRegion />
          </Grid> : null
        }

        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.clients_per_niche)) ?
          <Grid item xs={12} sm={12}>
            <EnterpriseChartsMonthsClientsPerNiche />
          </Grid> : null
        }

        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.contracts)) ?
          <Grid item xs={12} sm={12}>
            <ChartMonthsContracts />
          </Grid> : null
        }

        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.contracts_per_period)) ?
          <Grid item xs={12} sm={12}>
            <ChartMonthsContractsPerBillingPeriod />
          </Grid> : null
        }

        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.withdrawals_per_type)) ?
          <Grid item xs={12} sm={12}>
            <ChartMonthsWithdrawalsPerType />
          </Grid> : null
        }

        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.withdrawals_per_customer)) ?
          <Grid item xs={12} sm={12}>
            <ChartMonthsWithdrawalsPerCustomer />
          </Grid> : null
        }

        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.invoices_per_type)) ?
          <Grid item xs={12} sm={12}>
            <ChartMonthsInvoicesPerType />
          </Grid> : null
        }
      </Grid>
    );
  }
  else
    return;
}

export default EnterpriseChartsMonthsDashboard;
