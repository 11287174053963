const rowEmployeeStyle = () => (record, index, defaultStyle = {}) =>
{
  let style = defaultStyle;
  if (record.employee_situation_type === 0)
    return {
      ...style,
      background: '#FFFFFF',
      borderLeftColor: '#FFDB9A',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  else if (record.employee_situation_type === 1)
    return {
      ...style,
      background: '#FFFFFF',
      borderLeftColor: '#D2F8D2',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  else if (record.employee_situation_type === 2)
    return {
      ...style,
      background: '#FFFFFF',
      borderLeftColor: '#FFC0CB',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  return style;
};

export default rowEmployeeStyle;
