import { SearchInput, ReferenceInput, SelectInput } from "react-admin";
import { Chip } from "@mui/material";

const FilterEnterpriseClientNiche = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Status do Contrato" source="status_contract_type" reference="setup/enterprise/status-contract">
    <SelectInput
      label="Status do Contrato"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <Chip source="with_contract" label="Com Contrato" defaultValue={true} />,
  <Chip source="with_out_contract" label="Sem Contrato" defaultValue={true} />,
  <Chip source="activated" label="Ativos" defaultValue={true} />,
  <Chip source="disabled" label="Desativados" defaultValue={true} />,
];

export default FilterEnterpriseClientNiche;
