import {
  Create,
  SimpleForm,
  TextInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue } from "../../../utils/schemas";

const Schema = Yup.object({
  name: hasValue()
});

const EnterpriseTypeInvoiceCreate = () => (
  <Create
    title="Novo Tipo de Conta"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Nome" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EnterpriseTypeInvoiceCreate;
