import { PatternFormat } from "react-number-format";
import { useInput } from "react-admin";
import { TextField } from "@mui/material";
import React from "react";

const MaskTextInput = React.forwardRef((props, ref) =>
{
  const { mask, onChange, onBlur, label, fullWidth, isRequired, ...rest } =
    props;

  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted }
  } = useInput({ onChange, onBlur, ...rest });

  const showError = (isTouched || isSubmitted) && invalid;

  return (
    <PatternFormat
      {...field}
      name={field.name}
      ref={ref}
      label={label}
      helperText={showError ? error?.message : ""}
      error={showError}
      fullWidth={fullWidth}
      isrequired={(isRequired !== undefined ? isRequired.toString() : "false")}
      customInput={TextField}
      format={mask}
      mask="_"
      {...rest}
    />
  );
});

export default MaskTextInput;
