import { SearchInput } from "react-admin";
import { Chip } from "@mui/material";

const FilterPaymentForm = [
  <SearchInput source="q" alwaysOn />,
  <Chip source="deposited_enterprise_bank_account" label="Depoistado na Conta Bancária" defaultValue={true} />,
  <Chip source="allow_enterprise_installment" label="Empresa Parcelas" defaultValue={true} />,
  <Chip source="allow_customer_installment" label="Colaborador Parcelas" defaultValue={true} />,
  <Chip source="allow_enterprise_penalty" label="Empresa Multas" defaultValue={true} />,
  <Chip source="allow_customer_penalty" label="Colaborador Multas" defaultValue={true} />,
  <Chip source="activated" label="Ativos" defaultValue={true} />,
  <Chip source="disabled" label="Desativados" defaultValue={true} />,
];

export default FilterPaymentForm;
