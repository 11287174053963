import { useRecordContext, usePermissions } from "react-admin";
import { Settings, Cancel } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DialogLossContract from "./dialog-loss-contract";
import DialogReactivateContract from "./dialog-reactivate-contract";
import DialogCancelContract from "./dialog-cancel-contract";
import useToggle from "../../ra/hook/useToggle";
import Button from "../buttons/button";
import WrapperDirection from "../wrapper-direction";
import config from "../../config";

function DialogContractActions()
{
  const { permissions } = usePermissions();
  const record = useRecordContext();
  const { on, toggle } = useToggle();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  // IF STATUS IS FINISHED OR CANCEL
  if (record.status_contract_type === config.status_contract.finished ||
      record.status_contract_type === config.status_contract.canceled)
    return null;
  // IF STAUS IS IN DAY OR OVERDUE AND NOT PERMISSION
  else if ((record.status_contract_type === config.status_contract.in_day ||
            record.status_contract_type === config.status_contract.overdue) &&
           (!permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.contracts.loss)) &&
            !permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.contracts.cancel))))
    return null;
  // IF STAUS IS LOSS AND NOT PERMISSION
  else if (record.status_contract_type === config.status_contract.loss &&
           !permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.contracts.reactivate)))
    return null;
  else
    return (
      <>
        <Button label="Ações" color="success" onClick={handleClick}>
          <Settings />
        </Button>

        <Dialog
          open={on}
          onClose={handleCloseClick}
          aria-label="Ações">
          <DialogTitle>Selecione uma opção</DialogTitle>
          <DialogContent>
            <WrapperDirection direction="column">
              { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.contracts.loss)) && <DialogLossContract /> }
              { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.contracts.reactivate)) && <DialogReactivateContract /> }
              { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.contracts.cancel)) && <DialogCancelContract /> }
            </WrapperDirection>
          </DialogContent>
          <DialogActions>
            <Button label="Fechar" color="error" onClick={handleCloseClick}>
              <Cancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
}

export default DialogContractActions;
