import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import FilterEnterpriseClientProspection from "../../../filters/enterprise/client-prospection";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsClientProspection from "../../../components/group-buttons/buttons-client-prospection";
import rowClientStyle from '../../../ra/datagrid/row/client';

const ClientProspectionList = (props) => (
  <List
    title="Clientes Agendados"
    sort={{ field: 'c.id', order: 'DESC' }}
    filters={ FilterEnterpriseClientProspection }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      rowStyle={rowClientStyle()}>
      <TextField source="id" sortBy="c.id" />
      <TextField source="name" label="Nome" sortBy="c.name" />
      <TextField source="niche.name" label="Nicho" sortBy="ecn.name" />
      <TextField source="enterprise_region.name" label="Região" sortBy="er.name" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="c.created_at" />
      <ButtonsClientProspection />
    </Datagrid>
  </List>
);

export default ClientProspectionList;
