import { SearchInput, ReferenceInput, SelectInput } from "react-admin";

const FilterEnterpriseEmployee = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Situação" source="employee_situation_type" reference="setup/enterprise/employee-situations">
    <SelectInput
      label="Situação"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>
];

export default FilterEnterpriseEmployee;
