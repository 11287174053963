import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField
} from 'react-admin';
import FilterInstallmentPenalty from "../../../filters/customer/installment-penalty";
import Actions from "../../../ra/list/actions";

const InstallmentPenaltyList = (props) => (
  <List
    title="Multas"
    sort={{ field: 'ipe.date_payment', order: 'DESC' }}
    filters={ FilterInstallmentPenalty }
    actions={ <Actions hasCreate={false} hasExport={false} /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="ipe.id" />
      <FunctionField render={record => `${record.installment_number} - ${record.installment_count_installments}`} label="Número da Parcela" sortBy="installment_number" />
      <TextField source="installment_client_name" label="Cliente" sortBy="cl.name" />
      <TextField source="installment_contract_name" label="Contrato" sortBy="c.name" />
      <TextField source="value_format" label="Valor Recebido" sortBy="ipe.value" />
      <TextField source="enterprise_payment_form_name" label="Forma" sortBy="pf.name" />
      <DateField source="date_payment" label="Data do Pagamento" locales="pt-BR" showTime sortBy="ipe.date_payment" />
    </Datagrid>
  </List>
);

export default InstallmentPenaltyList;
