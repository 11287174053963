import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue } from "../../../utils/schemas";
import MaskTextInput from "../../../components/inputs/mask-text-input";

const Schema = Yup.object({
  name: hasValue()
});

const EnterpriseEdit = () => (
  <Edit
    title={<Title subtitle="Editar Empresa" source="name" />}
    redirect="list"
    mutationMode="pessimistic">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <TextInput source="id" label="Id" fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={10} />

        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Nome" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item md={12}>
          <BooleanInput source="active" label="Ativo" />
        </Grid>
        <Grid className='mb-4' item md={12}>
          <h5 className='mb-0'>Link de Pagamento</h5>
          <span>Configurações exclusivas e necessárias para a ativação do link de pagamento</span>
        </Grid>
        <Grid item md={12}>
          <BooleanInput source="link_payment_active" label="Ativar Link de Pagamento" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput source="link_payment_chave_pix" label="Chave PIX (Exclusiva para o Link de Pagamentos)" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput source="link_payment_client_id" label="BANKING_CLIENT_ID (Consultar no seu Internet Banking)" fullWidth />
        </Grid>
        <Grid item md={12}>
          <BooleanInput source="whatsapp_active" label="Ativar Integração WhatsApp (Consultar o escritório para obter)" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput source="whatsapp_authkey" label="AUTHKEY" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput source="whatsapp_appkey" label="APPKEY" fullWidth />
        </Grid>
        <Grid className='mb-4' item md={12}>
          <h5 className='mb-0'>WhatsApp Interno</h5>
          <span>Número que receberá notificações sobre finalizações de contratos e outros avisos internos.</span>
        </Grid>
        <Grid item xs={12} sm={3}>
          <MaskTextInput source="whatsapp_internal_use" label="WhatsApp interno (Opcional)" mask="(##) #####-####" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);
// TODO: add input file to this
// link_payment_certificate
// link_payment_private_key

export default EnterpriseEdit;
