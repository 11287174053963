import {
  Form,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { EventNote, Cancel, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import * as Yup from "yup";
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import Button from "../buttons/button";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue } from "../../utils/schemas";
import config from '../../config';

const Schema = Yup.object({
  note: hasValue()
});

function DialogInstallmentNote({ isEnterprise = true })
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { on, toggle } = useToggle();
  const { loading, action } = useForRequest();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleSubmit = async (values) =>
  {
    const { error } = await action(`customer-installment/${record.id}/add/note`, 'POST', values);
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    refresh();
    toggle();
    notify('Observação cadastrada com sucesso!', { type: "success" });
  };

  if (!isEnterprise &&
      (record.status_installment_type === config.status_installment.in_day ||
       record.status_installment_type === config.status_installment.overdue))
  {
    return (
      <>
        <Button onClick={handleClick} color="info" label="Observação">
          <EventNote />
        </Button>

        <Dialog
          fullWidth
          open={on}
          onClose={handleCloseClick}
          aria-label="Nova Observação para a Parcela">
          <Form
            resolver={yupResolver(Schema)}
            onSubmit={handleSubmit}>
            <DialogTitle>
              Nova Observação para a Parcela
            </DialogTitle>
            <DialogContent>
              <TextInput source="note" label="Observação" fullWidth isRequired />
            </DialogContent>
            <DialogActions>
              <Button
                label="Cancelar"
                disabled={loading}
                color="error"
                onClick={handleCloseClick}>
                <Cancel />
              </Button>
              <Button
                label="Salvar"
                color="success"
                type="submit"
                disabled={loading}>
                <Save />
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    );
  }
  else
    return null;
}

export default DialogInstallmentNote;
