import {
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import CustomRichTextInput from "../../../components/inputs/custom-rich-text-input";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue } from "../../../utils/schemas";

const Schema = Yup.object({
  title: hasValue(),
  report_text: hasValue()
});

const ReportDayEdit = () => (
  <Edit
    title={ <Title subtitle="Editar Relatório Diário" source="id" /> }
    redirect="list"
    mutationMode="pessimistic">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={ <ToolbarSave /> }>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <TextInput source="id" label="Id" fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={10} />

        <Grid item xs={12} sm={6}>
          <TextInput source="title" label="Título" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <CustomRichTextInput source="report_text" label="Texto" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default ReportDayEdit;
