import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField
} from 'react-admin';
import FilterEnterpriseVehicle from "../../../filters/enterprise/vehicle";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsVehicle from "../../../components/group-buttons/buttons-vehicle";

const EnterpriseVehicleList = () => (
  <List
    title="Veículos"
    sort={{ field: 'ev.id', order: 'DESC' }}
    filters={ FilterEnterpriseVehicle }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="ev.id" />
      <TextField source="type" label="Tipo" sortBy="ev.type" />
      <TextField source="plate" label="Placa" sortBy="ev.plate" />
      <ArrayField label="Condutores" source="conductors" fieldKey="uuid" sortable={false}>
        <SingleFieldList
          linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="ev.created_at" />
      <ButtonsVehicle />
    </Datagrid>
  </List>
);

export default EnterpriseVehicleList;
