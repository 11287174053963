import {
  Form,
  ReferenceInput,
  SelectInput,
  NumberInput,
  BooleanInput,
  TextInput,
  FormDataConsumer,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { AttachMoney, Cancel, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import * as Yup from "yup";
import config from '../../config';
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import Button from "../buttons/button";
import { setCurrentUser } from '../../utils/api-params';
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, number, hasValue } from "../../utils/schemas";

const Schema = Yup.object({
  enterprise_bank_account_id: leastOneOption(),
  enterprise_payment_form_id: leastOneOption(),
  value: number(),
  value_fees: number(),
  value_discount: number(),
  enterprise_type_withdrawal_id: Yup.bool()
    .nullable()
    .when("is_withdrawal", { is: true, then: () => leastOneOption() }),
  name_withdrawal: Yup.bool()
    .nullable()
    .when("is_withdrawal", { is: true, then: () => hasValue() })
});

function DialogInvoicePay()
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { on, toggle } = useToggle();
  const { loading, action } = useForRequest();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleSubmit = async (values) =>
  {
    const { error } = await action(`enterprise-invoice/${record.id}/pay`, 'POST', values);
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    // GET USER FOR VERIFY IF INVOICE PENDING TODAY
    const { response, errorGetMe } = await action('user-enterprise/me', 'GET', {});
    if (!errorGetMe)
    {
      response.data.result.type_user = config.type_user.enterprise;
      setCurrentUser(response.data.result);
    }

    refresh();
    toggle();
    notify('Baixa na conta realizada com sucesso!', { type: "success" });
  };

  if (record.status_invoice_type === config.status_invoice.in_day ||
      record.status_invoice_type === config.status_invoice.overdue)
  {
    return (
      <>
        <Button onClick={handleClick} color="success" label="Dar Baixa">
          <AttachMoney />
        </Button>

        <Dialog
          fullWidth
          open={on}
          onClose={handleCloseClick}
          aria-label="Pagamento">
          <Form
            resolver={yupResolver(Schema)}
            onSubmit={handleSubmit}>
            <DialogTitle>
              Pagamento
            </DialogTitle>
            <DialogContent>
              <ReferenceInput source="enterprise_bank_account_id" sort={{ field: 'name', order: 'ASC' }} reference={"setup/enterprise/bank-accounts"}>
                <SelectInput label="Conta Bancária" optionText="name" optionValue="id" fullWidth isRequired />
              </ReferenceInput>
              <ReferenceInput source="enterprise_payment_form_id" sort={{ field: 'name', order: 'ASC' }} reference={"setup/enterprise/payment-forms"}>
                <SelectInput label="Forma de Pagamento" optionText="name" optionValue="id" fullWidth isRequired />
              </ReferenceInput>
              <NumberInput source="value" label="Valor" step={1} defaultValue={record.value_total} fullWidth isRequired />
              <NumberInput source="value_fees" label="Valor Juros" step={1} defaultValue={0} fullWidth isRequired />
              <NumberInput source="value_discount" label="Valor Desconto" step={1} defaultValue={0} fullWidth isRequired />
              <BooleanInput source="is_withdrawal" label="Gerar Retirada" />
              <FormDataConsumer>
              {
                ({ formData, ...rest }) => formData.is_withdrawal === true ?
                <>
                  <ReferenceInput source="enterprise_type_withdrawal_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/types-withdrawal" >
                    <SelectInput label="Tipo" optionText="name" optionValue="id" fullWidth isRequired />
                  </ReferenceInput>
                  <TextInput source="name_withdrawal" label="Nome" defaultValue={record.description} fullWidth isRequired />
                  <BooleanInput source="is_enterprise_cash" label="Retirado do Caixa da Empresa" defaultValue={true} />
                </>
                : null
              }
              </FormDataConsumer>
            </DialogContent>
            <DialogActions>
              <Button
                label="Cancelar"
                disabled={loading}
                color="error"
                onClick={handleCloseClick}>
                <Cancel />
              </Button>
              <Button
                label="Salvar"
                color="success"
                type="submit"
                disabled={loading}>
                <Save />
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    );
  }
  else
    return null;
}

export default DialogInvoicePay;
