import { SearchInput, ReferenceInput, SelectInput, DateInput } from "react-admin";

const FilterCustomerWithdrawal = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Tipo" source="enterprise_type_withdrawal_id" reference="setup/customer/types-withdrawal">
    <SelectInput
      label="Tipo"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <DateInput source="date_withdrawal" label="Data de Início Retirada" />,
  <DateInput source="date_end_withdrawal" label="Data Final Retirada" />
];

export default FilterCustomerWithdrawal;
