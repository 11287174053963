import { usePermissions } from "react-admin";
import WelcomeDashboard from "./welcome-dashboard";
import EnterpriseDashboard from "./enterprise-dashboard";
import EnterpriseMonthlyDashboard from "./enterprise-monthly-dashboard";
import config from "../config";

const Dashboard = () =>
{
  const { permissions } = usePermissions();

  // VERIFY IS ENTERPRISE AND HAS PERMISSION
  if (permissions && permissions.type_user === config.type_user.enterprise)
  {
    return (
      <WelcomeDashboard>
      {
        permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.dashboard.general)) ?
        <EnterpriseDashboard /> : null
      }
      {
        permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.dashboard.monthly)) ?
        <EnterpriseMonthlyDashboard /> : null
      }
      </WelcomeDashboard>
    );
  }
  else
    return (
      <WelcomeDashboard />
    );
};

export default Dashboard;
