import { SearchInput, ReferenceInput, SelectInput, DateInput } from "react-admin";

const FilterEnterpriseLog = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Usuário" source="user_enterprise_id" reference="setup/enterprise/users">
    <SelectInput
      label="Usuário"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Colaborador" source="customer_id" reference="setup/enterprise/customers">
    <SelectInput
      label="Colaborador"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Tipo" source="type_log" reference="setup/enterprise/types-log">
    <SelectInput
      label="Tipo"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      limitChoicesToValue={true}
      shouldRenderSuggestions={(val) => { return val ? val.trim().length > 1 : false }}
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Tipo Usuário" source="type_user_log" reference="setup/enterprise/types-user-log">
    <SelectInput
      label="Tipo Usuário"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      limitChoicesToValue={true}
      shouldRenderSuggestions={(val) => { return val ? val.trim().length > 1 : false }}
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Categoria" source="category_log" reference="setup/enterprise/categories-log">
    <SelectInput
      label="Categoria"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <DateInput source="date_log_init" label="Data Inicial" />,
  <DateInput source="date_log_end" label="Data Final" />
];

export default FilterEnterpriseLog;
