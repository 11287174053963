import { useState } from "react";

function useToggle(initialValue = false)
{
  const [on, setOn] = useState(initialValue);

  /** @param {boolean} [v] */
  const toggle = (v) =>
  {
    setOn((on) => v ?? !on);
  };

  return {
    on,
    toggle
  };
}

export default useToggle;
