import { useRecordContext } from 'react-admin';
import { Button } from "@mui/material";
import IconHome from '@mui/icons-material/Home';

function ButtonClientAddress()
{
  const record = useRecordContext();

  const btnClick = (e) =>
  {
    e.stopPropagation();

    window.open("https://maps.google.com?q=" +
    (record.client != null ? record.client.address.lat : record.address.lat) +
    "," +
    (record.client != null ? record.client.address.lng : record.address.lng));
  };

  return (
    <Button
      color="primary"
      onClick={btnClick}>
      <IconHome />
    </Button>
  );
}

export default ButtonClientAddress;
