import {
  useRecordContext,
  useRedirect
} from 'react-admin';
import Button from "./button";
import IconDetails from '@mui/icons-material/Visibility';

function ShowInstallmentButton({ isEnterprise = true })
{
  const record = useRecordContext();
  const redirect = useRedirect();

  const btnClick = (e) =>
  {
    e.stopPropagation();
    
    redirect(('/customer-client/' + record.client.id + '/show'));
  };

  if (!isEnterprise)
  {
    return (
      <Button label="Dados do Cliente" color="info" onClick={btnClick}>
        <IconDetails />
      </Button>
    );
  }
  else
    return null;
}

export default ShowInstallmentButton;
