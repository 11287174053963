import { useRecordContext, useRedirect } from "react-admin";
import IconDetails from "@mui/icons-material/Visibility";
import Button from "./button";

export default function ShowInstallementPendingPenaltyButton()
{
  const record = useRecordContext();
  const redirect = useRedirect();

  const btnClick = () =>
  {
    redirect(`/enterprise-installment-pending-penalty/${record?.id}/show`);
  };

  return (
    <Button label="Detalhes" color="primary" onClick={btnClick}>
      <IconDetails />
    </Button>
  );
}
