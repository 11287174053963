import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import FilterReportDay from "../../../filters/customer/report-day";
import Actions from "../../../ra/list/actions";
import WrapperDirection from "../../../components/wrapper-direction";
import DetailsButton from "../../../components/buttons/details-button";
import EditButton from "../../../components/buttons/edit-button";

const ReportDayList = () => (
  <List
    title="Relatórios Diário"
    sort={{ field: 'crd.id', order: 'DESC' }}
    filters={ FilterReportDay }
    actions={ <Actions hasExport={false} /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="crd.id" />
      <TextField source="title" label="Título" sortBy="crd.title" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="crd.created_at" />
      <WrapperDirection>
        <DetailsButton />
        <EditButton />
      </WrapperDirection>
    </Datagrid>
  </List>
);

export default ReportDayList;
