import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue } from "../../../utils/schemas";

const Schema = Yup.object({
  name: hasValue()
});

const EnterpriseRegionEdit = () =>
{
  return (
    <Edit
      title={ <Title subtitle="Editar Região" source="name" /> }
      redirect="list"
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <ToolbarSave /> }>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <TextInput source="id" label="Id" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={10} />

          <Grid item xs={12} sm={6}>
            <TextInput source="name" label="Nome" fullWidth isRequired />
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={6}>
            <BooleanInput source="active" label="Ativo" />
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default EnterpriseRegionEdit;
