import {
  TextField,
  FunctionField,
  DateField,
  SimpleShowLayout
} from 'react-admin';
import formatValue from "../../utils/currency";

const ContractPanel = () => (
  <SimpleShowLayout>
    <TextField source="id" />
    <FunctionField source="value_initial" label="Valor Emprestado" render={record => `${formatValue(record.value_initial)}`} />
    <FunctionField source="value_fees" label="Valor Juros" render={record => `${formatValue(record.value_fees)}`} />
    <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    <TextField source="user_enterprise.name" label="Criado Por" />
  </SimpleShowLayout>
);

export default ContractPanel;
