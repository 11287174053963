import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  BooleanField
} from 'react-admin';
import FilterDefault from "../../../filters/default";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import DetailEditWithPermission from "../../../components/detail-edit-with-permission";

const EnterpriseBillingPeriodList = () => (
  <List
    title="Períodos de Pagamento"
    sort={{ field: 'ebp.id', order: 'DESC' }}
    filters={ FilterDefault }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="ebp.id" />
      <TextField source="name" label="Nome" sortBy="ebp.name" />
      <NumberField source="number_installments" label="Número de Parcelas" sortBy="ebp.number_installments" />
      <NumberField source="interval_days" label="Intervalo de Dias" sortBy="ebp.interval_days" />
      <BooleanField source="active" label="Ativo" sortBy="ebp.active" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="ebp.created_at" />
      <DetailEditWithPermission />
    </Datagrid>
  </List>
);

export default EnterpriseBillingPeriodList;
