const rowBankingFlowStyle = () => (record, index, defaultStyle = {}) =>
{
  let style = defaultStyle;
  if (record.type === 0)
    return {
      ...style,
      background: "#FFC0CB",
      borderLeftColor: "#FFC0CB",
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };
  else if (record.type === 1)
    return {
      ...style,
      background: "#D2F8D2",
      borderLeftColor: "#D2F8D2",
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };
  return style;
};

export default rowBankingFlowStyle;
