import { Layout, CheckForApplicationUpdate } from "react-admin";
import CustomSidebar from "./sidebar";
import CustomAppBar from "./appbar";
import Menu from "./menu";

export const MyLayout = ({ children, props }) => (
    <Layout
      {...props}
      sidebar={CustomSidebar}
      appBar={CustomAppBar}
      menu={Menu}>
        {children}
        <CheckForApplicationUpdate />
    </Layout>
);
