import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField
} from 'react-admin';

const EnterprisePaymentFormShow = () => (
  <Show
    title="Forma de Pagamento">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" label="Nome" />
      <BooleanField source="deposited_enterprise_bank_account" label="Depositado na Conta Bancária" />
      <BooleanField source="allow_enterprise_installment" label="Empresa Parcelas" />
      <BooleanField source="allow_customer_installment" label="Colaborador Parcelas" />
      <BooleanField source="allow_enterprise_penalty" label="Empresa Multas" />
      <BooleanField source="allow_customer_penalty" label="Colaborador Multas" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default EnterprisePaymentFormShow;
