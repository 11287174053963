import {
  useRecordContext,
  useRedirect
} from 'react-admin';
import Button from "./button";
import IconEdit from '@mui/icons-material/Edit';

function EditClientButton({ isEnterprise = true, isClient = true })
{
  const record = useRecordContext();
  const redirect = useRedirect();

  const btnClick = (e) =>
  {
    e.stopPropagation();

    if (isEnterprise)
    {
      if (isClient)
        redirect(('/client/' + record.id));
      else
        redirect(('/client-prospection/' + record.id));
    }
    else
    {
        if (isClient)
          redirect(('/customer-client/' + record.id));
        else
          redirect(('/customer-client-prospection/' + record.id));
    }
  };

  return (
    <Button label="Editar" color="info" onClick={btnClick}>
      <IconEdit />
    </Button>
  );
}

export default EditClientButton;
