import { SearchInput, ReferenceInput, SelectInput } from "react-admin";

const FilterEnterpriseEmployeeCost = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Tipo de Custo" source="enterprise_type_employee_cost_id" reference="setup/enterprise/types-employee-cost">
    <SelectInput
      label="Tipo de Custo"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Funcionário" source="enterprise_employee_id" reference="setup/enterprise/employees">
    <SelectInput
      label="Funcionário"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>
];

export default FilterEnterpriseEmployeeCost;
