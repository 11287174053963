import TextField from '@mui/material/TextField';
import { useWatch } from "react-hook-form";
import { useInput } from 'react-admin';
import formatValue from "../utils/currency";

const ValuePossession = ({ value, balance, ...props }) =>
{
  var value_deposited = useWatch({ name: "value_deposited" });
  const { onChange, onBlur, label, helperText, ...rest } = props;
  const { field } = useInput({ onChange, onBlur, ...rest });

  if (value_deposited === null || value_deposited < 0)
    value_deposited = 0;

  return (
      <TextField
        {...field}
        label={label}
        value={formatValue(((balance - value_deposited) + value))}
        disabled={true}
        {...rest}
      />
  );
};

export default ValuePossession;
