import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue } from "../../../utils/schemas";

const Schema = Yup.object({
  name: hasValue()
});

const EnterprisePaymentFormCreate = () => (
  <Create
    title="Nova Forma de Pagamento"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Nome" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <BooleanInput source="deposited_enterprise_bank_account" label="Depositado na Conta Bancária" />
        </Grid>
        <Grid item xs={12} sm={6} />
        
        <Grid item xs={12} sm={6}>
          <BooleanInput source="allow_enterprise_installment" label="Empresa Parcelas" />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <BooleanInput source="allow_customer_installment" label="Colaborador Parcelas" />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <BooleanInput source="allow_enterprise_penalty" label="Empresa Multas" />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <BooleanInput source="allow_customer_penalty" label="Colaborador Multas" />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EnterprisePaymentFormCreate;
