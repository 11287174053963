import {
  useRecordContext,
  useResourceContext,
  useRedirect
} from "react-admin";
import Button from "./button";
import IconEdit from "@mui/icons-material/Edit";
import wrapperButtonForRedirect from "../../ra/hoc/wrapperButtonForRedirect";

function EditButton()
{
  const record = useRecordContext();
  const resource = useResourceContext();
  const redirect = useRedirect();

  const btnClick = (e) =>
  {
    e.stopPropagation();

    redirect('edit', resource, record.id);
  };

  return (
    <Button label="Editar" color="info" onClick={btnClick}>
      <IconEdit />
    </Button>
  );
}

export default wrapperButtonForRedirect(EditButton);
