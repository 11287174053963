import { usePermissions, useRecordContext } from "react-admin";
import WrapperDirection from "../wrapper-direction";
import DetailsButton from "../buttons/details-button";
import DialogInstallmentActions from "../dialogs/dialog-installment-actions";
import config from "../../config";
import LinkInstallementButton from "../buttons/link-installment-button";

const ButtonsInstallment = () =>
{
  const { permissions } = usePermissions();
  const record = useRecordContext();

  const canShowInstallment = permissions?.permissions.some((e) =>
    e.keys.some((k) => k.key === config.permissions.installments.show),
  );

  const hasPendingPenalty = !!record?.value_penalty_pending;

  return (
    <WrapperDirection align="flex-end">
      {canShowInstallment && <LinkInstallementButton />}
      {canShowInstallment && hasPendingPenalty && <LinkInstallementButton withPenalty />}
      {canShowInstallment && <DetailsButton />}
      <DialogInstallmentActions isEnterprise={true} isMainInstallment={true} />
    </WrapperDirection>
  );
};

export default ButtonsInstallment;
