import {
  Create,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import { Grid } from '@mui/material';
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, number } from "../../../utils/schemas";

const Schema = Yup.object({
  enterprise_type_employee_cost_id: leastOneOption(),
  enterprise_employee_id: leastOneOption(),
  value: number()
});

const EnterpriseEmployeCostCreate = () => (
  <Create
    title="Novo Custo"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={3}>
          <ReferenceInput source="enterprise_type_employee_cost_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/types-employee-cost">
            <SelectInput label="Tipo de Custo" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ReferenceInput source="enterprise_employee_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/employees">
            <SelectInput label="Funcionário" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={3}>
          <NumberInput source="value" label="Valor" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={9} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EnterpriseEmployeCostCreate;
