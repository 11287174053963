import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Stack, Button } from "@mui/material";
import { useTranslate, useLogin, useNotify } from "react-admin";
import { Avatar, IconButton, InputAdornment, TextField, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const schema = yup.object({
  email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
  password: yup.string().required("Campo obrigatório")
});

const Login = () =>
{
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();

  const handleClickShowPassword = () =>
  {
    setShowPassword(!showPassword);
  };

  const onSubmit = (data) =>
  {
    setLoading(true);
    login(data).catch(() =>
    {
      setLoading(false);
      notify("E-mail ou senha inválido!", { type: "error" });
    });
  };

  return (
    <Stack
      minHeight="100svh"
      alignItems="center"
      justifyContent="center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        mode="all"
        style={{ minWidth: "400px" }}
        revalidatemode="onChange"
        noValidate>
        <Stack
          bgcolor="white"
          padding={4}
          gap={5}
          borderRadius="1em">
          <Stack
            alignItems="center"
            gap={1}>
            <Avatar sx={{ bgcolor: "primary.main" }}>
              <Lock />
            </Avatar>
            <Typography
              sx={{
                textAlign: "center",
                color: (theme) => theme.palette.grey[500],
              }}>
              Faça o login
            </Typography>
          </Stack>

          <Stack gap={3}>
            <RadioGroup
              row

              defaultValue="admin">
              <FormControlLabel
                {...register("type_user")}
                value="admin"
                label="Administrador"
                disabled={loading}
                control={<Radio />} />
              <FormControlLabel
                {...register("type_user")}
                value="user-enterprise"
                label="Empresa"
                disabled={loading}
                control={<Radio />} />
              <FormControlLabel
                {...register("type_user")}
                value="customer"
                label="Colaborador"
                disabled={loading}
                control={<Radio />} />
            </RadioGroup>

            <TextField
              autoFocus
              {...register("email")}
              size="small"
              disabled={loading}
              label={translate("ra.auth.email")}
              error={errors.email}
              helperText={ errors.email && errors.email.message }/>

            <TextField
              id="password"
              {...register("password")}
              size="small"
              type={showPassword ? "text" : "password"}
              disabled={loading}
              label={translate("ra.auth.password")}
              error={errors.password}
              helperText={ errors.password && errors.password.message }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={ handleClickShowPassword }
                      edge="end">
                      { showPassword ? <VisibilityOff /> : <Visibility /> }
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Button
              color="primary"
              type="submit"
              disabled={loading}>
              Entrar
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default Login;
