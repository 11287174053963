import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  DateField
} from 'react-admin';

const ReportDayShow = () => (
  <Show
    title="Relatório Diário">
    <TabbedShowLayout>
      <Tab label="Dados">
        <TextField source="id" />
        <TextField source="title" label="Título" />
        <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
      </Tab>
      <Tab label="Texto">
        <RichTextField source="report_text" label="" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default ReportDayShow;
