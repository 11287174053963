import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  Datagrid,
  TextField,
  FunctionField,
  useListController
} from 'react-admin';
import { Card } from '@mui/material';
import FilterEnterpriseVehicleMaintenance from "../../../filters/enterprise/vehicle-maintenance";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsVehicleMaintenance from "../../../components/group-buttons/buttons-vehicle-maintenance";
import formatValue from "../../../utils/currency";
const VehicleMaintenancePagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100, 250, 500, 1000 ]} {...props} />;

const CustomList = ({ children, actions, filters, title, ...props }) =>
{
  const { data, loading } = useListController();

  if (!loading && data)
  {
    var total = 0;

    data.forEach((item, i) => { total += parseFloat(item.value); });

    return (
      <ListBase {...props}>
        <Title title={title}/>
        <ListToolbar filters={filters} actions={actions}/>
        <Card>
        {children}
        </Card>
        <br/>
        <div>
          <b>
            Valor Total: { formatValue(total) }
          </b>
        </div>
        <VehicleMaintenancePagination />
      </ListBase>
    );
  }
  else
    return null;
}

const EnterpriseVehicleMaintenanceList = () => (
  <CustomList
    title="Manutenções"
    sort={{ field: 'evm.id', order: 'DESC' }}
    filters={ FilterEnterpriseVehicleMaintenance }
    perPage={25}
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="evm.id" />
      <TextField source="enterprise_vehicle_name" label="Veículo" sortBy="enterprise_vehicle_name" />
      <TextField source="name" label="Nome" sortBy="evm.name" />
      <FunctionField source="value" label="Valor" render={record => `${formatValue(record.value)}`} sortBy="evm.value" />
      <TextField source="date_maintenance_format" label="Data Manutenção" sortBy="evm.date_maintenance" />
      <ButtonsVehicleMaintenance />
    </Datagrid>
  </CustomList>
);

export default EnterpriseVehicleMaintenanceList;
