import { SearchInput, ReferenceInput, SelectArrayInput, DateInput } from "react-admin";
import { Chip } from "@mui/material";

const FilterEnterpriseClient = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Regiões"
    source="regions"
    reference="setup/enterprise/regions">
    <SelectArrayInput label="Regiões" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput
    label="Nichos"
    source="niches"
    reference="setup/enterprise/client/niches">
    <SelectArrayInput label="Nichos" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput
    label="Classificações"
    source="classifications"
    reference="setup/enterprise/client/classifications">
    <SelectArrayInput label="Classificações" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <DateInput source="date_init_created_at" label="Data Criação Inicial" />,
  <DateInput source="date_end_created_at" label="Data Criação Final" />,
  <Chip source="with_contract" label="Com Contrato" defaultValue={true} />,
  <Chip source="with_out_contract" label="Sem Contrato" defaultValue={true} />,
  <Chip source="activated" label="Ativos" defaultValue={true} />,
  <Chip source="disabled" label="Desativados" defaultValue={true} />
];

export default FilterEnterpriseClient;
