import { usePermissions } from 'react-admin';
import WrapperDirection from "../wrapper-direction";
import DetailsButton from "../buttons/details-button";
import DialogEmployeeActions from "../dialogs/dialog-employee-actions";
import config from "../../config";

const ButtonsEmployee = () =>
{
  const { permissions } = usePermissions();

  return (
    <WrapperDirection>
      { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.employees.show)) && <DetailsButton /> }
      <DialogEmployeeActions />
    </WrapperDirection>
  );
};

export default ButtonsEmployee;
