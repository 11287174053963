import { Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetIdentity, useRefresh } from "react-admin";
import { setCurrentDate, getCurrentDate } from "../../utils/api-params";

function SelectDateInput()
{
  const [selectedDate, setSelectedDate] = useState("");
  const { data, isLoading } = useGetIdentity();
  const refresh = useRefresh();

  const handleClick = (e) =>
  {
    e.stopPropagation();
  };

  const handleChange = (e) =>
  {
    const monthYear = e.target.value.toString();
    if (monthYear !== getCurrentDate())
    {
      e.stopPropagation();

      setCurrentDate(monthYear);
      setSelectedDate(monthYear);
      refresh();
    }
  };

  useEffect(() =>
  {
    if (data)
      setSelectedDate(getCurrentDate());

  }, [data]);

  return (
    <Select
      size="small"
      variant="outlined"
      value={selectedDate}
      disabled={isLoading}
      style={{ marginLeft: "10px" }}
      sx={{ backgroundColor: "white" }}
      onClick={handleClick}
      onChange={handleChange}>
      {
        data?.dates_activity?.map((item, index) => (
          <MenuItem key={index} value={item.month_year}>
            {item.date}
          </MenuItem>
        ))
      }
    </Select>
  );
}

export default SelectDateInput;
