import {
  Form,
  ReferenceInput,
  SelectInput,
  NumberInput,
  useNotify,
  useRecordContext,
  useRefresh,
  usePermissions
} from "react-admin";
import { CalendarMonth, Cancel, Save } from "@mui/icons-material";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import * as Yup from "yup";
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import Button from "../buttons/button";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, number } from "../../utils/schemas";
import { getCustomersId } from '../../utils/utils';
import config from '../../config';

const Schema = (isEnterprise, permissions) =>
{
  var obj =
  {
    enterprise_payment_form_id: leastOneOption(),
    value: number()
  };

  if (isEnterprise)
    obj.customer_id = leastOneOption();

  return Yup.object(obj);
};

function DialogInstallmentPartialReceived({ isEnterprise = true })
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const { on, toggle } = useToggle();
  const [showAlert, setShowAlert] = useState(false);
  const [extendValues, setExtendValues] = useState({});
  const { loading, action } = useForRequest();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const closeAlert = (e) =>
  {
    e.stopPropagation();
    setShowAlert(false);
  };

  const getInstallment = async (values) =>
  {
    var url;

    if (isEnterprise)
      url = `enterprise-installment/${record.id}`;
    else
      url = `customer-installment/${record.id}`;

    const { response, error } = await action(url, 'GET', {});
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    // CHECK INSTALLMENT EXTENDED
    if (response.data.result.extended)
    {
      setExtendValues(values);
      setShowAlert(true);
    }
    else
      extendInstallment(values);
  };

  const submitExtendInstallment = async () =>
  {
    extendInstallment(extendValues);
  };

  const extendInstallment = async (values) =>
  {
    var url;

    if (isEnterprise)
      url = `enterprise-installment/${record.id}/extend`;
    else
      url = `customer-installment/${record.id}/extend`;

    const { error } = await action(url, 'POST', values);
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    refresh();
    toggle();
    setShowAlert(false);
    notify('Parcela prorrogada com sucesso!', { type: "success" });
  };

  if (record.status_installment_type === config.status_installment.in_day ||
      record.status_installment_type === config.status_installment.overdue)
  {
    return (
      <>
        <Button onClick={handleClick} color="secondary" label="Prorrogar Parcela">
          <CalendarMonth />
        </Button>

        <Dialog
          fullWidth
          open={on}
          onClose={handleCloseClick}
          aria-label="Prorrogar Parcela">
          <Form
            resolver={yupResolver(Schema(isEnterprise, permissions))}
            onSubmit={getInstallment}>
            {
              record.extended ?
              <DialogTitle sx={{ background: "#FF5F15", marginBottom: "25px" }}>
                Prorrogar Parcela - Parcela já prorrogada
              </DialogTitle> :
              <DialogTitle>
                Prorrogar Parcela
              </DialogTitle>
            }
            <DialogContent>
              {
                isEnterprise &&
                (
                  <ReferenceInput
                    source="customer_id"
                    sort={{ field: 'name', order: 'ASC' }}
                    filter={{ customers: getCustomersId(permissions, config.permissions.installment_actions.key) }}
                    reference="setup/enterprise/customers" >
                    <SelectInput label="Colaborador" optionText="name" optionValue="id" fullWidth isRequired />
                  </ReferenceInput>
                )
              }

              <ReferenceInput source="enterprise_payment_form_id" sort={{ field: 'name', order: 'ASC' }} filter={isEnterprise ? { allow_enterprise_installment: true } : { allow_customer_installment: true }} reference={isEnterprise ? "setup/enterprise/payment-forms" : "setup/customer/payment-forms"}>
                <SelectInput label="Forma de Pagamento" optionText="name" optionValue="id" fullWidth isRequired />
              </ReferenceInput>
              <NumberInput source="value" label="Valor da Multa" step={1} fullWidth isRequired />
            </DialogContent>
            <DialogActions>
              <Button
                label="Cancelar"
                disabled={loading}
                color="error"
                onClick={handleCloseClick}>
                <Cancel />
              </Button>
              <Button
                label="Salvar"
                color="success"
                type="submit"
                disabled={loading}>
                <Save />
              </Button>
            </DialogActions>
          </Form>
        </Dialog>

        <Dialog
          fullWidth
          open={showAlert}
          onClose={closeAlert}
          aria-label="Tem certeza que deseja prorrogar a parcela?">
          <Form
            onSubmit={submitExtendInstallment}>
            <DialogTitle>
              Essa parcela já foi prorrogada uma ou mais vezes, tem certeza que deseja prorrogar a parcela novamente?
            </DialogTitle>
            <DialogActions>
              <Button
                label="Cancelar"
                disabled={loading}
                color="error"
                onClick={closeAlert}>
                <Cancel />
              </Button>
              <Button
                label="Prorrogar"
                color="success"
                type="submit"
                disabled={loading}>
                <Save />
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    );
  }
  else
    return null;
}

export default DialogInstallmentPartialReceived;
