import { Toolbar, SaveButton } from "react-admin";

const DefaultToolbar = ({ children }) => <Toolbar>{children}</Toolbar>;

export const Save = () =>
{
  return (
    <DefaultToolbar>
      <SaveButton alwaysEnable />
    </DefaultToolbar>
  );
};
