import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import FilterPaymentForm from "../../../filters/enterprise/payment-form";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import DetailEditWithPermission from "../../../components/detail-edit-with-permission";

const EnterprisePaymentFormList = () => (
  <List
    title="Formas de Pagamento"
    sort={{ field: 'epf.id', order: 'DESC' }}
    filters={ FilterPaymentForm }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="epf.id" />
      <TextField source="name" label="Nome" sortBy="epf.name" />
      <BooleanField source="deposited_enterprise_bank_account" label="Depositado na Conta Bancária" sortBy="epf.deposited_enterprise_bank_account" />
      <BooleanField source="allow_enterprise_installment" label="Empresa Parcelas" sortBy="epf.allow_enterprise_installment" />
      <BooleanField source="allow_customer_installment" label="Colaborador Parcelas" sortBy="epf.allow_customer_installment" />
      <BooleanField source="allow_enterprise_penalty" label="Empresa Multas" sortBy="epf.allow_enterprise_penalty" />
      <BooleanField source="allow_customer_penalty" label="Colaborador Multas" sortBy="epf.allow_customer_penalty" />
      <BooleanField source="active" label="Ativo" sortBy="epf.active" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="epf.created_at" />
      <DetailEditWithPermission />
    </Datagrid>
  </List>
);

export default EnterprisePaymentFormList;
