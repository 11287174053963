import {
  SimpleShowLayout,
  Datagrid,
  TextField,
  FunctionField,
  ArrayField
} from 'react-admin';
import WrapperDirection from "../../components/wrapper-direction";
import DetailsButton from "../../components/buttons/details-button";
import DialogInvoiceActions from "../../components/dialogs/dialog-invoice-actions";
import rowInvoiceStyle from '../../ra/datagrid/row/invoice';
import formatValue from "../../utils/currency";

const InvoicePanel = () => (
  <SimpleShowLayout>
    <ArrayField source="relations" label="">
      <Datagrid
        rowClick={false}
        bulkActionButtons={false}
        rowStyle={rowInvoiceStyle()}>
        <TextField source="id" sortable={false} />
        <TextField source="status_invoice_name" label="Status" sortable={false} />
        <TextField source="enterprise_type_invoice_name" label="Tipo" sortable={false} />
        <TextField source="description" label="Descrição" sortable={false} />
        <TextField source="date_issue_format" label="Data Emissão" sortable={false} />
        <TextField source="date_due_format" label="Data Vencimento" sortable={false} />
        <FunctionField source="value" label="Valor" render={record => `${formatValue(record.value)}`} sortable={false} />
        <TextField source="payment.user_enterprise_name" label="Paga Por" sortable={false} />
        <TextField source="payment.enterprise_bank_account_name" label="Conta" sortable={false} />
        <TextField source="payment.enterprise_payment_form_name" label="Forma" sortable={false} />
        <TextField source="payment.value_format" label="Valor Pago" sortable={false} />
        <TextField source="payment.date_payment_format" label="Data do Pagamento" sortable={false} />
        <WrapperDirection>
          <DetailsButton />
          <DialogInvoiceActions />
        </WrapperDirection>
      </Datagrid>
    </ArrayField>
  </SimpleShowLayout>
);

export default InvoicePanel;
