import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  BooleanField,
  usePermissions
} from 'react-admin';
import FilterEnterpriseClientNiche from "../../../filters/enterprise/client-niche";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import DetailEditWithPermission from "../../../components/detail-edit-with-permission";
import formatValue from "../../../utils/currency";
import config from "../../../config";

const EnterpriseClientNicheList = () =>
{
  const { permissions } = usePermissions();
  return (
    <List
      title="Nichos"
      sort={{ field: 'ecn.id', order: 'DESC' }}
      filters={ FilterEnterpriseClientNiche }
      actions={ <ActionsWithPermission /> }>
      <Datagrid
        rowClick={false}
        bulkActionButtons={false}>
        <TextField source="id" sortBy="ecn.id" />
        <TextField source="name" label="Nome" sortBy="ecn.name" />
        <TextField source="count_clients" label="Nº Clientes" sortBy="count_clients" />
        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.client_niches.show_value_invested)) &&
          (
            <FunctionField source="value_total_contracts" label="Valor Investido" render={record => `${formatValue(record.value_total_contracts)}`} sortBy="value_total_contracts" />
          )
        }
        <BooleanField source="active" label="Ativo" sortBy="ecn.active" />
        <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="ecn.created_at" />
        <DetailEditWithPermission />
      </Datagrid>
    </List>
  )
};

export default EnterpriseClientNicheList;
