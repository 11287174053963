import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField
} from 'react-admin';
import JsonTranslatedField from "../../../components/json-translated-field";

const EnterpriseLogShow = () => (
  <Show
    title="Log">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="type_log_name" label="Tipo" />
      <TextField source="type_user_log_name" label="Tipo Usuário" />
      <TextField source="category_log_name" label="Categoria" />
      <TextField source="user_name" label="Usuário" />
      <TextField source="message" label="Mensagem" />
      <JsonTranslatedField label="Dados" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default EnterpriseLogShow;
