import { usePermissions } from "react-admin";
import WrapperDirection from "../wrapper-direction";
import EditInstallmentPendingPenaltyButton from "../buttons/edit-installment-pending-penalty-button";
import config from "../../config";
import ShowInstallmentPendingPenaltyButton from "../buttons/show-installment-pending-penalty-button";
import { UserPermissions } from "../../provider/models/permissions";
import LinkInstallementPendingPenaltyButton from "../buttons/link-installment-pending-penalty-button";

export default function ButtonsInstallmentPendingPenalty()
{
  const { permissions } = usePermissions<UserPermissions>();

  return (
    <WrapperDirection align="flex-end">
      {permissions?.permissions.some((e) =>
        e.keys.some((k) => k.key === config.permissions.installment_penalties.show),
      ) && <LinkInstallementPendingPenaltyButton />}
      {permissions?.permissions.some((e) =>
        e.keys.some((k) => k.key === config.permissions.installment_penalties.show),
      ) && <ShowInstallmentPendingPenaltyButton />}
      {permissions?.permissions.some((e) =>
        e.keys.some((k) => k.key === config.permissions.installment_penalties.edit),
      ) && <EditInstallmentPendingPenaltyButton />}
    </WrapperDirection>
  );
}
