import {
  Edit,
  SimpleForm,
  TextInput,
  PasswordInput,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { email, hasValue, array } from "../../../utils/schemas";

const Schema = Yup.object({
  name: hasValue(),
  email: email(),
  enterprise_regions_id: array()
});

const CustomerEdit = () => (
  <Edit
    title={ <Title subtitle="Editar Colaborador" source="name" /> }
    redirect="list"
    mutationMode="pessimistic">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={ <ToolbarSave /> }>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <TextInput source="id" label="Id" fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={10} />

        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Nome" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={3}>
          <TextInput source="email" label="E-mail" type="email" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={3}>
          <PasswordInput source="password" label="Senha" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ReferenceArrayInput source="enterprise_regions_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/regions" >
            <SelectArrayInput label="Região(ões)" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceArrayInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <BooleanInput source="active" label="Ativo" />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default CustomerEdit;
