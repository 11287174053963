const rowContractStyle = () => (record, index, defaultStyle = {}) =>
{
  let style = defaultStyle;
  if (record.status_contract_type === 0)
    return {
      ...style,
      background: '#D2F8D2',
      borderLeftColor: (record.client_classification ? record.client_classification.color_base : '#D2F8D2'),
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  else if (record.status_contract_type === 1)
    return {
      ...style,
      background: '#FFC0CB',
      borderLeftColor: (record.client_classification ? record.client_classification.color_base : '#FFC0CB'),
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  else if (record.status_contract_type === 2)
    return {
      ...style,
      background: '#E8F4F8',
      borderLeftColor: (record.client_classification ? record.client_classification.color_base : '#E8F4F8'),
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  else if (record.status_contract_type === 3)
    return {
      ...style,
      background: '#FFDB9A',
      borderLeftColor: (record.client_classification ? record.client_classification.color_base : '#FFDB9A'),
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  else if (record.status_contract_type === 4)
    return {
      ...style,
      background: '#FFE9EC',
      borderLeftColor: (record.client_classification ? record.client_classification.color_base : '#FFE9EC'),
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  return style;
};

export default rowContractStyle;
