import { useSidebarState } from "react-admin";
import { Box } from "@mui/material";

const NewSidebar = ({ children }) =>
{
  const [open] = useSidebarState();
  return <Box sx={{ width: open ? "auto" : 50 }}>{children}</Box>;
};

export default NewSidebar;
