import { useRecordContext, useNotify } from 'react-admin';
import { Button } from "@mui/material";
import IconPhone from '@mui/icons-material/Phone';

function ButtonClientPhone()
{
  const record = useRecordContext();
  const notify = useNotify();
  var phone;

  if (record.client && record.client.phone)
    phone = record.client.phone.replace(/\D/g,'').replace(/ /g,'');
  else if (record.phone)
    phone = record.phone.replace(/\D/g,'').replace(/ /g,'');

  const btnClick = (e) =>
  {
    e.stopPropagation();

    if (phone)
      window.open("tel:" + phone);
    else
      notify("Cliente não tem telefone fixo cadastrado!", { type: "error" });
  };

  return (
    <Button
      color="primary"
      onClick={btnClick}>
      <IconPhone />
    </Button>
  );
}

export default ButtonClientPhone;
