import { usePermissions } from 'react-admin';
import WrapperDirection from "../wrapper-direction";
import DetailsButton from "../buttons/details-button";
import DialogApproveCashRegister from "../dialogs/dialog-approve-cash-register";
import DialogReproveCashRegister from "../dialogs/dialog-reprove-cash-register";
import config from "../../config";

const ButtonsCashRegister = () =>
{
  const { permissions } = usePermissions();

  return (
    <WrapperDirection>
      { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.cash_registers.show)) && <DetailsButton /> }
      { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.cash_registers.approve_reprove)) && <> <DialogApproveCashRegister /> <DialogReproveCashRegister /> </> }
    </WrapperDirection>
  );
};

export default ButtonsCashRegister;
