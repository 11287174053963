import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Datagrid,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";
import LinkField from '../../../components/link-field';

const CashRegisterShow = () => (
  <Show
    title="Fechamento de Caixa">
    <TabbedShowLayout>
      <Tab label="Dados">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="id" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Labeled>
              <ArrayField label="Formas de Pagamento" source="enterprise_payment_forms" fieldKey="uuid">
                <SingleFieldList
                  linkType={false}>
                  <ChipField source="name" />
                </SingleFieldList>
              </ArrayField>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="status_cash_register_name" label="Status" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="date_format" label="Data" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="value_installments_format" label="Valor em Parcelas" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="value_installment_penalties_format" label="Valor em Multas" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="value_withdrawals_format" label="Valor em Retiradas" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="value_balance_format" label="Saldo do Dia" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="value_deposited_format" label="Valor Depositado" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="value_possession_format" label="Valor em Posse" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </Tab>
      <Tab label="Arquivos">
        <ArrayField source="files" label="">
          <Datagrid
            rowClick={false}
            bulkActionButtons={false}>
            <TextField source="name" label="Nome" />
            <LinkField source="url" label="Arquivo" blank />
            <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default CashRegisterShow;
