import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  Datagrid,
  TextField,
  FunctionField,
  useListController
} from 'react-admin';
import { Card } from '@mui/material';
import Button from "../../../components/buttons/button";
import FilterCustomerInstallment from '../../../filters/customer/installment';
import Actions from "../../../ra/list/actions";
import IconMaps from '@mui/icons-material/MyLocation';
import WrapperDirection from "../../../components/wrapper-direction";
import ButtonClientAddress from '../../../components/buttons/button-client-address';
import ButtonClientEnterpriseAddress from '../../../components/buttons/button-client-enterprise-address';
import ButtonClientPhone from '../../../components/buttons/button-client-phone';
import ButtonClientPhoneWhatsapp from '../../../components/buttons/button-client-phone-whatsapp';
import DialogInstallmentActions from '../../../components/dialogs/dialog-installment-actions';
import InstallmentPanel from '../../../panels/customer/installment-panel';
import rowInstallmentStyle from '../../../ra/datagrid/row/installment';
import formatValue from "../../../utils/currency";
// import ButtonsContractInstallment from "../../../components/group-buttons/buttons-contract-installment";
import LinkInstallementButton from "../../../components/buttons/link-installment-button";
const InstallmentPagination = props => <Pagination rowsPerPageOptions={[100]} {...props} />;

const CustomList = ({ children, actions, filters, title, ...props }) => {
  const { data, loading } = useListController();

  const btnClick = () => {
    window.open(mapsUrl);
  };

  if (!loading && data) {
    var mapsUrl = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&';
    var wayPoints = '';

    data.forEach((item, i) => {
      if (i === (data.length - 1))
        mapsUrl += ("&destination=" + item.client.enterprise_address.lat + "," + item.client.enterprise_address.lng);
      else
        wayPoints += (item.client.enterprise_address.lat + "," + item.client.enterprise_address.lng + "|");
    });

    if (wayPoints !== '')
      mapsUrl += ("&waypoints=" + wayPoints);

    return (
      <ListBase {...props}>
        <Title title={title} />
        <ListToolbar filters={filters} actions={actions} />
        <Card>
          {children}
        </Card>
        <br />
        <div>
          <Button
            onClick={btnClick}
            label="Traçar Rota">
            <IconMaps />
          </Button>
        </div>
        <InstallmentPagination />
      </ListBase>
    );
  }
  else
    return null;
}

const CustomerInstallmentList = (props) => (
  <CustomList
    title="Parcelas"
    sort={{ field: 'i.date_billing', order: 'ASC' }}
    perPage={100}
    filters={FilterCustomerInstallment}
    actions={<Actions hasCreate={false} hasExport={false} />}>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      expandSingle
      expand={<InstallmentPanel />}
      rowStyle={rowInstallmentStyle()}>
      <FunctionField render={record => `${record.number}/${record.count_installments}`} label="Número" sortable={false} />
      <TextField source="client.name" label="Cliente" sortable={false} />
      <FunctionField source="value_total" label="Valor" render={record => `${formatValue(record.value_total)}`} sortable={false} />
      <TextField source="date_billing_format" label="Data de Cobrança" sortable={false} />
      <WrapperDirection>
        <LinkInstallementButton onlyIcon />
        <LinkInstallementButton withPenalty onlyIcon />
        <ButtonClientAddress />
        <ButtonClientEnterpriseAddress />
        <ButtonClientPhone />
        <ButtonClientPhoneWhatsapp />
        <DialogInstallmentActions isEnterprise={false} isMainInstallment={true} />
      </WrapperDirection>
    </Datagrid>
  </CustomList>
);

export default CustomerInstallmentList;
