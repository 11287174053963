import {
  Form,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { ThumbUpOffAlt, Cancel, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import config from '../../config';
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import { setCurrentUser } from '../../utils/api-params';
import Button from "../buttons/button";

function DialogApproveCashRegister()
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { on, toggle } = useToggle();
  const { loading, action } = useForRequest();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleSubmit = async () =>
  {
    const { error } = await action(`enterprise-cash-register/${record.id}/check`, 'POST', { approved: true });
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    // GET USER FOR VERIFY IF CASH REGISTER PENDING
    const { response, errorGetMe } = await action('user-enterprise/me', 'GET', {});
    if (!errorGetMe)
    {
      response.data.result.type_user = config.type_user.enterprise;
      setCurrentUser(response.data.result);
    }

    refresh();
    toggle();
    notify('Fechamento de caixa aprovado com sucesso!', { type: "success" });
  };

  if (record.status_cash_register === config.status_cash_register.waiting_check)
    return (
      <>
        <Button onClick={handleClick} color="success" label="APROVAR">
          <ThumbUpOffAlt />
        </Button>

        <Dialog
          fullWidth
          open={on}
          onClose={handleCloseClick}
          aria-label="Aprovar Fechamento de Caixa">
          <Form onSubmit={handleSubmit}>
            <DialogTitle>
              Tem certeza que deseja aprovar esse fechamento de caixa?
            </DialogTitle>
            <DialogActions>
              <Button
                label="Cancelar"
                disabled={loading}
                color="error"
                onClick={handleCloseClick}>
                <Cancel />
              </Button>
              <Button
                label="Confirmar"
                color="success"
                type="submit"
                disabled={loading}>
                <Save />
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    );
  else
    return null;
}

export default DialogApproveCashRegister;
