import { translateHeader } from "../utils/utils";
var ExcelJS = require('exceljs'),
    pdfJs = require('pdfjs'),
    Helvetica = require('pdfjs/font/Helvetica'),
    HelveticaBold = require('pdfjs/font/Helvetica-Bold');

const xlsx = async (dataArray) =>
{
  // KEYS
  var keys = Object.keys(dataArray[0]);

  // WORKBOOK
  const workbook = new ExcelJS.Workbook();
  const workSheet = workbook.addWorksheet('Data', {});
  var workSheetColumns = [];

  // HEARDES
  keys.forEach((item, i) =>
  {
    workSheetColumns.push(
    {
      header: translateHeader(item.toString()),
      key: item.toString()
    });
  });

  workSheet.columns = workSheetColumns;

  // ROWS
  dataArray.forEach((item, i) =>
  {
    keys.forEach((key, i) =>
    {
      if (key.startsWith("value"))
        item[key] = Number(item[key]);
    });

    workSheet.addRow(item);
  });

  workSheet.columns.forEach((column, i) =>
  {
    // FORMAT NUMBER MONETARY
    if (column.key.startsWith("value"))
      column.numFmt = '"R$" #,##0.00';

    // COLUMN WIDTH
    var lengths = column.values.map(v => v.toString().length);
    var maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
    column.width = (maxLength < column.header.length ? (column.header.length + 2) : (maxLength + 2));

    column.eachCell({ includeEmpty: false }, function(cell, cellNumber)
    {
      // HEADER BOLD AND TEXT ALIGNMENT
      if (cellNumber === 1)
      {
        cell.style = { font: { bold: true }};
        cell.alignment = { horizontal: 'center' };
      }

      // BORDER
      cell.border =
      {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
    });
  });

  return await workbook.xlsx.writeBuffer();
};

const pdf = async (dataArray) =>
{
  // DOC
  const doc = new pdfJs.Document({ font: Helvetica });

  // KEYS AND WIDTH
  var keys = Object.keys(dataArray[0]);
  var widths = new Array(keys.length).fill(null);

  const table = doc.table(
  {
    widths: widths,
    borderWidth: 0.3,
    backgroundColor: '#FFFFFF',
  });

  const fontHeader =
  {
    font: HelveticaBold,
    fontSize: 5,
    color: '#FFFFFF',
    textAlign: 'center'
  };

  const fontRow =
  {
    font: Helvetica,
    fontSize: 5,
    textAlign: 'center'
  };

  // HEARDES
  const tr = table.header(
  {
    font: HelveticaBold,
    backgroundColor: '#5F5C91'
  });

  keys.forEach((item, i) =>
  {
    tr.cell(translateHeader(item.toString()), fontHeader);
  });

  // ROWS
  dataArray.forEach((item, i) =>
  {
    const tr = table.row();

    keys.forEach((key, i) =>
    {
      if (item[key] != null)
      {
        if (key.startsWith("value"))
          tr.cell(Number(item[key]).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}), fontRow);
        else
          tr.cell(item[key].toString(), fontRow);
      }
      else
        tr.cell("", fontRow);
    });
  });

  return await doc.asBuffer();
};

export { xlsx, pdf };
