import {
  getRequestSign,
  getCurrentUser,
  setCurrentUser,
  setCurrentAuth,
  setCurrentDate,
  clearLocalStorage
} from "../utils/api-params";
import config from "../config";

const authProvider =
{
  login: ({ type_user, email, password }) =>
  {
    return fetch(getRequestSign(type_user, email, password))
      .then((response) =>
      {
        if (response.status === 400)
          return Promise.reject();

        return response.json();
      })
      .then((data) =>
      {
        // SET TYPE USER
        data.result.user.type_user = type_user;

        setCurrentUser(data.result.user);
        setCurrentAuth(data.result.auth);

        // ENTERPRISE - SET CURRENT DATE FILTER
        if (type_user === config.type_user.enterprise &&
            data.result.user.enterprise.dates_activity.length > 0)
          setCurrentDate(data.result.user.enterprise.dates_activity[0].month_year);

        return Promise.resolve();
      })
      .catch((error) =>
      {
        return Promise.reject();
      });
  },
  logout: () =>
  {
    clearLocalStorage();

    return Promise.resolve();
  },
  checkError: (error) =>
  {
    if (error !== null && error !== undefined &&
        (error.status === 401 || error.status === 403))
    {
      clearLocalStorage();

      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () =>
  {
    return getCurrentUser() !== null ? Promise.resolve() : Promise.reject();
  },
  getIdentity: () =>
  {
    const currentUser = getCurrentUser();
    if (currentUser !== null)
    {
      return Promise.resolve(
      {
        id: currentUser.id,
        fullName: currentUser.name,
        dates_activity: (currentUser.enterprise ? currentUser.enterprise.dates_activity : [])
      });
    }
    else
      return Promise.reject();
  },
  getPermissions: () =>
  {
    const currentUser = getCurrentUser();
    if (currentUser !== null)
    {
      var permissions =
      {
        type_user: currentUser.type_user,
        permissions: (currentUser.permissions ? currentUser.permissions : [])
      };

      return currentUser.type_user && currentUser.active ? Promise.resolve(permissions) : Promise.reject();
    }
    else
      return Promise.reject();
  }
};

export default authProvider;
