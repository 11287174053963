import {
  ListBase,
  Title,
  ListToolbar,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  useListController
} from 'react-admin';
import { Card, CardHeader } from '@mui/material';
import FilterCustomerReportPayment from '../../../filters/customer/report-payment';
import rowInstallmentStyle from '../../../ra/datagrid/row/installment';
import formatValue from "../../../utils/currency";

const CustomList = ({ children, actions, filters, title, ...props }) =>
{
  const { data, loading } = useListController();

  if (!loading && data)
  {
    var values = data[0].data;

    return (
      <ListBase {...props}>
        <Title title={title}/>
        <br/>
        <ListToolbar filters={filters} actions={actions}/>
        <br/>
        <Card>
          <CardHeader title={'Parcelas'} />
          <Datagrid
            data={values.installments}
            rowClick={false}
            bulkActionButtons={false}
            rowStyle={rowInstallmentStyle()}>
            <TextField source="id" sortable={false} />
            <TextField source="status_installment_name" label="Status" sortable={false} />
            <FunctionField render={record => `${record.number} - ${record.count_installments}`} label="Número da Parcela" sortable={false} />
            <TextField source="client.name" label="Cliente" sortable={false} />
            <TextField source="contract_name" label="Contrato" sortable={false} />
            <FunctionField source="value_cost" label="Valor Custo" render={record => `${formatValue(record.value_cost)}`} sortable={false} />
            <FunctionField source="value_fees" label="Valor Juros" render={record => `${formatValue(record.value_fees)}`} sortable={false} />
            <FunctionField source="value_total" label="Valor Total" render={record => `${formatValue(record.value_total)}`} sortable={false} />
            <TextField source="original_date_billing_format" label="Data de Cobrança Original" sortable={false} />
            <TextField source="date_billing_format" label="Data de Cobrança" sortable={false} />
            <TextField source="payment.enterprise_payment_form_name" label="Forma" sortable={false} />
            <TextField source="payment.value_format" label="Valor Recebido" sortable={false} />
            <FunctionField source="payment.value_gain" label="Valor Lucro" render={record => `${record.payment ? formatValue(record.payment.value_gain) : ''}`} sortable={false} />
            <DateField source="payment.date_payment" label="Data do Pagamento" locales="pt-BR" showTime sortable={false} />
          </Datagrid>
        </Card>
        <br/>
        <Card>
          <CardHeader title={'Multas'} />
          <Datagrid
            data={values.installmentPenalties}
            rowClick={false}
            bulkActionButtons={false}>
            <TextField source="id" sortable={false} />
            <FunctionField render={record => `${record.installment_number} - ${record.installment_count_installments}`} label="Número da Parcela" sortable={false} />
            <TextField source="installment_client_name" label="Cliente" sortable={false} />
            <TextField source="installment_contract_name" label="Contrato" sortable={false} />
            <TextField source="value_format" label="Valor Recebido" sortable={false} />
            <TextField source="enterprise_payment_form_name" label="Forma" sortable={false} />
            <DateField source="date_payment" label="Data do Pagamento" locales="pt-BR" showTime sortable={false} />
          </Datagrid>
        </Card>
        <br/>
        <Card>
          <CardHeader title={'Retiradas'} />
          <Datagrid
            data={values.withdrawals}
            rowClick={false}
            bulkActionButtons={false}>
            <TextField source="id" sortable={false} />
            <TextField source="enterprise_type_withdrawal_name" label="Tipo" sortable={false} />
            <TextField source="name" label="Nome" sortable={false} />
            <TextField source="value_format" label="Valor" sortable={false} />
            <DateField source="date_withdrawal" label="Data" locales="pt-BR" showTime sortable={false} />
          </Datagrid>
        </Card>
        <br/>
        <div>
          <b>Valor Recebido com Parcelas: </b>
          {formatValue(values.value_total_installments)}
          <br/>
          <br/>
          <b>Valor Recebido com Multas: </b>
          {formatValue(values.value_total_installment_penalties)}
          <br/>
          <br/>
          <b>Valor Retirado: </b>
          {formatValue(values.value_total_withdrawals)}
          <br/>
          <br/>
          <b>Saldo: </b>
          {formatValue(values.balance)}
        </div>
        <br/>
        <br/>
      </ListBase>
    );
  }
  else
    return null;
}

const ReportPaymentList = (props) => (
  <CustomList
    title="Parcelas"
    sort={{ field: 'id', order: 'ASC' }}
    filters={ FilterCustomerReportPayment }
    actions={ null } />
);

export default ReportPaymentList;
