import { usePermissions } from 'react-admin';
import WrapperDirection from "../wrapper-direction";
import DetailsButton from "../buttons/details-button";

import config from "../../config";
import LinkInstallementPendingPenaltyButton from "../../components/buttons/link-installment-pending-penalty-button";
import DialogInstallmentActions from "../dialogs/dialog-installment-actions";

const ButtonsInstallmentPenalty = () => {
  const { permissions } = usePermissions();

  return (
    <WrapperDirection>
      {permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_penalties.show)) && <LinkInstallementPendingPenaltyButton />}
      {permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_penalties.show)) && <DetailsButton />}
      <DialogInstallmentActions isEnterprise={true} isMainInstallment={false} />
    </WrapperDirection>
  );
};

export default ButtonsInstallmentPenalty;
