import { Typography } from "@mui/material";
import WrapperDirection from "../components/wrapper-direction";

const WelcomeContent = ({ children }) =>
{
  return (
    <WrapperDirection
      direction="column">
      <Typography
        variant="h1"
        fontSize="2em"
        fontWeight="bold">
        Olá, seja bem-vindo!
      </Typography>
      <Typography
        fontSize="0.875rem"
        marginBottom={2}>
        Qualquer dúvida sobre como administrar ou algo que queira desenvolver,
        entre em contato com a gente pelo e-mail:{" "}
        <a href="mailto:contato@physis.io?subject=Dashboard: Dúvidas">
          contato@physis.io
        </a>
      </Typography>
      {children}
      <Typography
        fontSize="0.675em"
        marginTop={2}
        marginRight={2}
        textAlign="right">
        Versão 2.0.0
      </Typography>
    </WrapperDirection>
  );
};

export default WelcomeContent;
