import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  TextInput,
  DateInput,
  NumberInput
} from 'react-admin';
import { Grid } from '@mui/material';
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, hasValue, validDate, number } from "../../../utils/schemas";

const Schema = Yup.object({
  client_id: leastOneOption(),
  enterprise_billing_period_id: leastOneOption(),
  name: hasValue(),
  date_init: validDate(),
  percentage: number(),
  value: number()
});

const ContractCreate = () => (
  <Create
    title="Novo Contrato"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <ReferenceInput source="client_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/clients" >
            <AutocompleteInput label="Cliente" optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 1 : false }} fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ReferenceInput source="enterprise_billing_period_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/billing-periods" >
            <SelectInput label="Período de Pagamento" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Nome" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <DateInput source="date_init" label="Data de início" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <NumberInput source="percentage" label="Porcentagem" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <NumberInput source="value" label="Valor" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default ContractCreate;
