import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue, number } from "../../../utils/schemas";

const Schema = Yup.object({
  name: hasValue(),
  number_installments: number(),
  interval_days: number()
});

const EnterpriseBillingPeriodCreate = () =>  (
  <Create
    title="Novo Período de Pagamento"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Nome" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <NumberInput source="number_installments" label="Número de Parcelas" min={0} step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <NumberInput source="interval_days" label="Intervalo de Dias" min={0} step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EnterpriseBillingPeriodCreate;
