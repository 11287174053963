import { createElement, useState } from 'react';
import { getCurrentUser } from '../../utils/api-params';
import { Box, Badge } from '@mui/material';
import SubMenu from './subMenu';
import {
  DashboardMenuItem,
  MenuItemLink,
  useSidebarState,
  usePermissions,
  useResourceDefinitions
} from 'react-admin';
import config from "../../config";

// ENTERPRISE
import ChartsIcon from "@mui/icons-material/BarChart";
import EnterpriseRegionIcon from "@mui/icons-material/Map";
import EnterpriseBillingPeriodIcon from "@mui/icons-material/Shop";
import EnterprisePaymentFormIcon from "@mui/icons-material/Payment";
import EnterpriseTypeWithdrawalIcon from "@mui/icons-material/PointOfSale";
import EnterpriseTypeInvoiceIcon from "@mui/icons-material/Receipt";
import EnterpriseBankAccountIcon from "@mui/icons-material/AccountBalance";
import EnterpriseClientNicheIcon from "@mui/icons-material/Groups";
import EnterpriseClientClassificationIcon from "@mui/icons-material/RateReview";
import EnterpriseReportDayIcon from "@mui/icons-material/Today";
import EnterpriseReportCommissionIcon from "@mui/icons-material/PriceCheck";
import EnterpriseVehicleIcon from "@mui/icons-material/DirectionsCar";
import EnterpriseVehicleMaintenanceIcon from "@mui/icons-material/Engineering";
import EnterpriseVehiclePenaltyIcon from "@mui/icons-material/Traffic";
import EnterpriseTypeEmployeeCostIcon from "@mui/icons-material/SensorOccupied";
import EnterpriseEmployeeIcon from "@mui/icons-material/Badge";
import EnterpriseEmployeeCostIcon from "@mui/icons-material/PriceChange";
import CustomerIcon from "@mui/icons-material/AccountCircle";
import EnterpriseClientSearchIcon from "@mui/icons-material/Search";
import ClientIcon from "@mui/icons-material/GroupAdd";
import ClientProspectionIcon from "@mui/icons-material/CalendarMonth";
import ContractIcon from "@mui/icons-material/Handshake";
import EnterpriseInstallmentIcon from "@mui/icons-material/AccountBalance";
import EnterpriseInstallmentPenaltyIcon from "@mui/icons-material/CurrencyExchange";
import EnterpriseWtihdrawalIcon from "@mui/icons-material/Paid";
import EnterpriseCashRegisterIcon from "@mui/icons-material/PointOfSale";
import EnterpriseInvoiceIcon from "@mui/icons-material/ReceiptLong";
import EnterpriseBankingFlowIcon from "@mui/icons-material/Savings";
import EnterpriseReportPaymentIcon from "@mui/icons-material/Assessment";
import EnterpriseLogIcon from "@mui/icons-material/Dvr";

// CUSTOMER
import CustomerWithdrawalIcon from "@mui/icons-material/Paid";
import CustomerInstallmentIcon from "@mui/icons-material/AccountBalance";
import CustomerClientProspectionIcon from "@mui/icons-material/CalendarMonth";
import CustomerInstallmentPenaltyIcon from "@mui/icons-material/CurrencyExchange";
import CustomerRouteIcon from "@mui/icons-material/AddLocationAlt";
import CustomerReportDayIcon from "@mui/icons-material/Report";
import CustomerReportCommissionIcon from "@mui/icons-material/PriceCheck";
import CustomerCashRegisterIcon from "@mui/icons-material/PointOfSale";
import CustomerReportPaymentIcon from "@mui/icons-material/Assessment";

type MenuName = 'menuSettings' | 'menuReports' | 'menuFleet' | 'menuPersonnelDepartment';

const MenuEnterpriseCharts = ({ permissions }) =>
{
  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.financial)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.clients)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.clients_per_region)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.clients_per_niche)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.contracts)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.contracts_per_period)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.withdrawals_per_type)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.withdrawals_per_customer)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.charts.invoices_per_type)))
  {
    return (
      <MenuItemLink
        key={'Gráficos'}
        to={'/charts'}
        primaryText={'Gráficos'}
        leftIcon={<ChartsIcon />} />
    );
  }
  else
    return null;
};

const MenuEnterpriseSettings = ({ state, handleToggle, permissions }) =>
{
  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.regions.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.billing_periods.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.payment_forms.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.types_withdrawal.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.types_invoice.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.bank_accounts.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.client_niches.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.client_classifications.list)))
  {
    var itemsMenu = [];

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.regions.list)))
      itemsMenu.push({ key: 'Regiões', to: '/enterprise-region', primaryText: 'Regiões', leftIcon: <EnterpriseRegionIcon /> });

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.billing_periods.list)))
      itemsMenu.push({ key: 'Períodos de Pagamento', to: '/enterprise-billing-period', primaryText: 'Períodos de Pagamento', leftIcon: <EnterpriseBillingPeriodIcon /> });

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.payment_forms.list)))
      itemsMenu.push({ key: 'Formas de Pagamento', to: '/enterprise-payment-form', primaryText: 'Formas de Pagamento', leftIcon: <EnterprisePaymentFormIcon /> });

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.types_withdrawal.list)))
      itemsMenu.push({ key: 'Tipos de Retirada', to: '/enterprise-type-withdrawal', primaryText: 'Tipos de Retirada', leftIcon: <EnterpriseTypeWithdrawalIcon /> });

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.types_invoice.list)))
      itemsMenu.push({ key: 'Tipos de Conta', to: '/enterprise-type-invoice', primaryText: 'Tipos de Conta', leftIcon: <EnterpriseTypeInvoiceIcon /> });

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.bank_accounts.list)))
      itemsMenu.push({ key: 'Contas Bancárias', to: '/enterprise-bank-account', primaryText: 'Contas Bancárias', leftIcon: <EnterpriseBankAccountIcon /> });

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.client_niches.list)))
      itemsMenu.push({ key: 'Nichos', to: '/enterprise-client-niche', primaryText: 'Nichos', leftIcon: <EnterpriseClientNicheIcon /> });

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.client_classifications.list)))
      itemsMenu.push({ key: 'Classificações', to: '/enterprise-client-classification', primaryText: 'Classificações', leftIcon: <EnterpriseClientClassificationIcon /> });

    return (
      <SubMenu
        handleToggle={() => handleToggle('menuSettings')}
        isOpen={state.menuSettings}
        name="Configurações">
        {
          itemsMenu.map((item, i) =>
            <MenuItemLink
              key={item.key}
              to={item.to}
              primaryText={item.primaryText}
              leftIcon={item.leftIcon} />
          )
        }
      </SubMenu>
    );
  }
  else
    return null;
};

const MenuEnterpriseReports = ({ state, handleToggle, permissions }) =>
{
  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.reports_day.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.reports_comission.list)))
  {
    var itemsMenu = [];

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.reports_day.list)))
      itemsMenu.push({ key: 'Diário', to: '/enterprise-report-day', primaryText: 'Diário', leftIcon: <EnterpriseReportDayIcon /> });

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.reports_comission.list)))
      itemsMenu.push({ key: 'Comissão', to: '/enterprise-report-commission', primaryText: 'Comissão', leftIcon: <EnterpriseReportCommissionIcon /> });

    return (
      <SubMenu
        handleToggle={() => handleToggle('menuReports')}
        isOpen={state.menuReports}
        name="Relatórios">
        {
          itemsMenu.map((item, i) =>
            <MenuItemLink
              key={item.key}
              to={item.to}
              primaryText={item.primaryText}
              leftIcon={item.leftIcon} />
          )
        }
      </SubMenu>
    );
  }
  else
    return null;
};

const MenuEnterpriseFleet = ({ state, handleToggle, currentUser, permissions }) =>
{
  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.vehicles.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.vehicles_maintenances.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.vehicles_penalties.list)))
  {
    var itemsMenu = [];

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.vehicles.list)))
      itemsMenu.push(<MenuItemLink key={'Veículos'} to={'/enterprise-vehicle'} primaryText={'Veículos'} leftIcon={<EnterpriseVehicleIcon />} />);

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.vehicles_maintenances.list)))
      itemsMenu.push(<MenuItemLink key={'Manutenções'} to={'/enterprise-vehicle-maintenance'} primaryText={'Manutenções'} leftIcon={<EnterpriseVehicleMaintenanceIcon />} />);

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.vehicles_penalties.list)))
      itemsMenu.push(<MenuItemLink
                      key={'Infrações'}
                      to={'/enterprise-vehicle-penalty'}
                      primaryText={'Infrações'}
                      leftIcon={<EnterpriseVehiclePenaltyIcon />}>
                      Infrações
                      <Badge
                        color="error"
                        overlap="circular"
                        variant="dot"
                        style={{transform: 'translate(10px, 0px)'}}
                        invisible={!currentUser.has_vehicle_penalty_pending} />
                      </MenuItemLink>);

    return (
      <SubMenu
        handleToggle={() => handleToggle('menuFleet')}
        isOpen={state.menuFleet}
        showBadge={currentUser.has_vehicle_penalty_pending}
        name="Frotas">
        {
          itemsMenu.map((item, i) => item)
        }
      </SubMenu>
    );
  }
  else
    return null;
};

const MenuEnterprisePersonnelDepartment = ({ state, handleToggle, permissions }) =>
{
  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.types_employee_cost.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.employees.list)) ||
      permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.employee_costs.list)))
  {
    var itemsMenu = [];

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.types_employee_cost.list)))
      itemsMenu.push(<MenuItemLink key={'Tipos de Custo'} to={'/enterprise-type-employee-cost'} primaryText={'Tipos de Custo'} leftIcon={<EnterpriseTypeEmployeeCostIcon />} />);

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.employees.list)))
      itemsMenu.push(<MenuItemLink key={'Funcionários'} to={'/enterprise-employee'} primaryText={'Funcionários'} leftIcon={<EnterpriseEmployeeIcon />} />);

    if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.employee_costs.list)))
      itemsMenu.push(<MenuItemLink key={'Custos'} to={'/enterprise-employee-cost'} primaryText={'Custos'} leftIcon={<EnterpriseEmployeeCostIcon />} />);

    return (
      <SubMenu
        handleToggle={() => handleToggle('menuPersonnelDepartment')}
        isOpen={state.menuPersonnelDepartment}
        name="Recursos Humanos">
        {
          itemsMenu.map((item, i) => item)
        }
      </SubMenu>
    );
  }
  else
    return null;
};

const MenusEnterprise = ({ currentUser, permissions }) =>
{
  var itemsMenu = [];

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.customers.list)))
    itemsMenu.push(<MenuItemLink key={'Colaboradores'} to={'/customer'} primaryText={'Colaboradores'} leftIcon={<CustomerIcon />} />);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.search_clients.list)))
    itemsMenu.push(<MenuItemLink key={'Pesquisar Clientes'} to={'/enterprise-client'} primaryText={'Pesquisar Clientes'} leftIcon={<EnterpriseClientSearchIcon />} />);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.clients.list)))
    itemsMenu.push(<MenuItemLink key={'Clientes'} to={'/client'} primaryText={'Clientes'} leftIcon={<ClientIcon />} />);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.clients_prospection.list)))
    itemsMenu.push(<MenuItemLink key={'Clientes Agendados'} to={'/client-prospection'} primaryText={'Clientes Agendados'} leftIcon={<ClientProspectionIcon />} />);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.contracts.list)))
    itemsMenu.push(<MenuItemLink key={'Contratos'} to={'/contract'} primaryText={'Contratos'} leftIcon={<ContractIcon />} />);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installments.list)))
    itemsMenu.push(<MenuItemLink key={'Parcelas'} to={'/enterprise-installment'} primaryText={'Parcelas'} leftIcon={<EnterpriseInstallmentIcon />} />);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_penalties.list)))
    itemsMenu.push(<MenuItemLink key={'Multas'} to={'/enterprise-installment-penalty'} primaryText={'Multas'} leftIcon={<EnterpriseInstallmentPenaltyIcon />} />);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.withdrawals.list)))
    itemsMenu.push(<MenuItemLink key={'Retiradas'} to={'/enterprise-withdrawal'} primaryText={'Retiradas'} leftIcon={<EnterpriseWtihdrawalIcon />} />);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.cash_registers.list)))
    itemsMenu.push(<MenuItemLink
                    key={'Fechamentos de Caixa'}
                    to={'/enterprise-cash-register'}
                    primaryText={'Fechamentos de Caixa'}
                    leftIcon={<EnterpriseCashRegisterIcon />}>
                    Fechamentos de Caixa
                    <Badge
                      color="error"
                      overlap="circular"
                      variant="dot"
                      style={{transform: 'translate(10px, 0px)'}}
                      invisible={!currentUser.has_cash_register_pending} />
                    </MenuItemLink>);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.invoices.list)))
    itemsMenu.push(<MenuItemLink
                    key={'Contas a Pagar'}
                    to={'/enterprise-invoice'}
                    primaryText={'Contas a Pagar'}
                    leftIcon={<EnterpriseInvoiceIcon />}>
                    Contas a Pagar
                    <Badge
                      color="error"
                      overlap="circular"
                      variant="dot"
                      style={{transform: 'translate(10px, 0px)'}}
                      invisible={!currentUser.has_invoice_pending} />
                    </MenuItemLink>);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.banking_flow.list)))
    itemsMenu.push(<MenuItemLink key={'Fluxo Bancário'} to={'/enterprise-banking-flow'} primaryText={'Fluxo Bancário'} leftIcon={<EnterpriseBankingFlowIcon />} />);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.report_payment.list)))
    itemsMenu.push(<MenuItemLink key={'Relatório'} to={'/enterprise-report-payment'} primaryText={'Relatório'} leftIcon={<EnterpriseReportPaymentIcon />} />);

  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.logs.list)))
    itemsMenu.push(<MenuItemLink key={'Logs'} to={'/enterprise-log'} primaryText={'Logs'} leftIcon={<EnterpriseLogIcon />} />);

  return (itemsMenu.map((item, i) => item));
};

const Menu = () =>
{
  const resources = useResourceDefinitions();
  const currentUser = getCurrentUser();
  const { permissions } = usePermissions();
  const [open] = useSidebarState();
  const handleToggle = (menu: MenuName) => { setState(state => ({ ...state, [menu]: !state[menu] })); };
  const [state, setState] = useState({ menuSettings: false, menuReports: false, menuFleet: false, menuPersonnelDepartment: false });

  // ADMIN
  if (permissions && permissions.type_user === config.type_user.admin)
  {
    return (
      <Box
        sx={
        {
          width: open ? 210 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: theme =>
          theme.transitions.create('width',
          {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
        }}>
        <DashboardMenuItem />
        {
          Object.keys(resources).map(name => (
            <MenuItemLink
              key={name}
              to={`/${name}`}
              primaryText={resources[name].options.label}
              leftIcon={createElement(resources[name].icon)}
            />
          ))
        }
      </Box>
    );
  }
  // ENTERPRISE
  else if (permissions && permissions.type_user === config.type_user.enterprise)
  {
    return (
      <Box
        sx={
        {
          width: open ? 270 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: theme =>
          theme.transitions.create('width',
          {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
        }}>
        <DashboardMenuItem />
        <MenuEnterpriseCharts
          permissions={permissions} />
        <MenuEnterpriseSettings
          state={state}
          handleToggle={handleToggle}
          permissions={permissions} />
        <MenuEnterpriseReports
          state={state}
          handleToggle={handleToggle}
          permissions={permissions} />
        <MenuEnterpriseFleet
          state={state}
          handleToggle={handleToggle}
          currentUser={currentUser}
          permissions={permissions} />
        <MenuEnterprisePersonnelDepartment
          state={state}
          handleToggle={handleToggle}
          permissions={permissions} />
        <MenusEnterprise
          currentUser={currentUser}
          permissions={permissions} />
      </Box>
    );
  }
  // CUSTOMER
  else if (permissions && permissions.type_user === config.type_user.customer)
  {
    return (
      <Box
        sx={
        {
          width: open ? 255 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: theme =>
          theme.transitions.create('width',
          {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
        }}>
        <DashboardMenuItem />
        <MenuItemLink
          key={'Retiradas'}
          to={'/customer-withdrawal'}
          primaryText={'Retiradas'}
          leftIcon={<CustomerWithdrawalIcon />}
        />
        <MenuItemLink
          key={'Parcelas'}
          to={'/customer-installment'}
          primaryText={'Parcelas'}
          leftIcon={<CustomerInstallmentIcon />}
        />
        <MenuItemLink
          key={'Clientes Agendados'}
          to={'/customer-client-prospection'}
          primaryText={'Clientes Agendados'}
          leftIcon={<CustomerClientProspectionIcon />}
        />
        <MenuItemLink
          key={'Multas'}
          to={'/customer-installment-penalty'}
          primaryText={'Multas'}
          leftIcon={<CustomerInstallmentPenaltyIcon />}
        />
        <MenuItemLink
          key={'Rotas'}
          to={'/customer-route'}
          primaryText={'Rotas'}
          leftIcon={<CustomerRouteIcon />}
        />
        <MenuItemLink
          key={'Relatório Diário'}
          to={'/customer-report-day'}
          primaryText={'Relatório Diário'}
          leftIcon={<CustomerReportDayIcon />}
        />
        <MenuItemLink
          key={'Relatório de Comissão'}
          to={'/customer-report-commission'}
          primaryText={'Relatório de Comissão'}
          leftIcon={<CustomerReportCommissionIcon />}
        />
        <MenuItemLink
          key={'Fechamento de Caixa'}
          to={'/customer-cash-register'}
          primaryText={'Fechamento de Caixa'}
          leftIcon={<CustomerCashRegisterIcon />}>
          Fechamento de Caixa
          <Badge
            color="error"
            overlap="circular"
            variant="dot"
            style={{transform: 'translate(10px, 0px)'}}
            invisible={!currentUser.has_cash_register_reproved} />
        </MenuItemLink>
        <MenuItemLink
          key={'Relatório Financeiro'}
          to={'/customer-report-payment'}
          primaryText={'Relatório Financeiro'}
          leftIcon={<CustomerReportPaymentIcon />}
        />
      </Box>
    );
  }
};

export default Menu;
