import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";

const EnterpriseWtihdrawalShow = () => (
  <Show
    title="Retirada">
    <SimpleShowLayout>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="id" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="enterprise_type_withdrawal_name" label="Tipo" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="customer_name" label="Colaborador" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="name" label="Nome" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="value_format" label="Valor" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <BooleanField source="is_enterprise_cash" label="Retirado do Caixa da Empresa" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <Labeled>
            <DateField source="date_withdrawal" label="Data" locales="pt-BR" showTime />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={8} />
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export default EnterpriseWtihdrawalShow;
