import {
  Form,
  TextInput,
  FileInput,
  FileField,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { AttachFile, Cancel, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import * as Yup from "yup";
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import Button from "../buttons/button";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue, file } from "../../utils/schemas";

const Schema = Yup.object({
  file_name: hasValue(),
  file: file()
});

function DialogVehiclePenaltyAddFile()
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { on, toggle } = useToggle();
  const { loading, action } = useForRequest();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleSubmit = async (values) =>
  {
    const { error } = await action(`enterprise-vehicle-penalty/${record.id}/add/file`, 'POST', values);
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    refresh();
    toggle();
    notify('Arquivo adicionado com sucesso!', { type: "success" });
  };

  return (
    <>
      <Button onClick={handleClick} label="ARQUIVO">
        <AttachFile />
      </Button>

      <Dialog
        fullWidth
        open={on}
        onClose={handleCloseClick}
        aria-label="Novo Arquivo">
        <Form
          resolver={yupResolver(Schema)}
          onSubmit={handleSubmit}>
          <DialogTitle>
            Novo Arquivo
          </DialogTitle>
          <DialogContent>
            <TextInput source="file_name" label="Nome" fullWidth isRequired />
            <FileInput source="file" label="Arquivo" isRequired>
              <FileField source="src" title="title" />
            </FileInput>
          </DialogContent>
          <DialogActions>
            <Button
              label="Cancelar"
              disabled={loading}
              color="error"
              onClick={handleCloseClick}>
              <Cancel />
            </Button>
            <Button
              label="Salvar"
              color="success"
              type="submit"
              disabled={loading}>
              <Save />
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

export default DialogVehiclePenaltyAddFile;
