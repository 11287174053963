import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ArrayField,
  Datagrid,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";

const EnterpriseReportCommissionShow = (props) => (
  <Show
    title="Relatório de Comissão">
    <TabbedShowLayout>
      <Tab label="Dados">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="id" label="Id" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="type_enterprise_report_commission_name" label="Tipo" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="customer_name" label="Colaborador" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="date_init_format" label="Data de Início" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="date_end_format" label="Data de Término" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="count_contracts" label="Nº Contratos" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="value_total_format" label="Valor Total" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="value_cost_format" label="Valor Despesas" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="percentage" label="Porcentagem" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="value_commission_format" label="Valor Comissão" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="user_enterprise.name" label="Criado Por" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </Tab>
      <Tab label="Contratos">
        <ArrayField source="enterprise_report_commission_contracts" label="">
          <Datagrid
            rowClick={false}
            bulkActionButtons={false}>
            <TextField source="id" label="Id" />
            <TextField source="client_name" label="Cliente" />
            <TextField source="value_format" label="Valor" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default EnterpriseReportCommissionShow;
