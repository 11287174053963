import { useRecordContext } from 'react-admin';
import { Button } from "@mui/material";
import IconEnterprise from '@mui/icons-material/Apartment';

function ButtonClientEnterpriseAddress()
{
  const record = useRecordContext();

  const btnClick = (e) =>
  {
    e.stopPropagation();

    window.open("https://maps.google.com?q=" +
    (record.client ? record.client.enterprise_address.lat : record.enterprise_address.lat) +
    "," +
    (record.client ? record.client.enterprise_address.lng : record.enterprise_address.lng));
  };

  return (
    <Button
      id="btn_enterprise_address"
      color="primary"
      onClick={btnClick}>
      <IconEnterprise />
    </Button>
  );
}

export default ButtonClientEnterpriseAddress;
