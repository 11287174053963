import { useState } from "react";
import axios from "axios";
import { getUrlApi, getHeadersFormData } from "../../utils/api-params";
import paramsToFormData from "../../utils/params-to-form-data";

function useForRequest()
{
  const [loading, setLoading] = useState(false);
  const action = async (endPoint, method, params) =>
  {
    const options =
    {
      url: `${getUrlApi()}/${endPoint}`,
      method: method,
      headers: getHeadersFormData(),
      data: paramsToFormData(params)
    };

    setLoading(true);

    try
    {
      const response = await axios(options);
      setLoading(false);

      return { response, error: false };
    }
    catch (error)
    {
      setLoading(false);

      return { response: null, error: error };
    }
  };

  return {
    loading,
    action,
  };
}

export default useForRequest;
