import { useNotify, useRecordContext } from "react-admin";
import AddLinkIcon from "@mui/icons-material/AddLink";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Button from "./button";
import { Button as ButtonIcon } from "@mui/material";
import config from "../../config";

type Props = {
  withPenalty?: boolean;
  onlyIcon?: boolean;
};
export default function LinkInstallementButton(props: Props)
{
  const record = useRecordContext();

  const notify = useNotify();

  if (
    (props.withPenalty && record && record.count_penalty_pending === 0) ||
    (record &&
      ["Cancelada", "Paga", "Paga Parcial"].includes(record.status_installment_name)) ||
    (record && record.type === 0)
  )
  {
    return;
  }

  const btnClick = () =>
  {
    if (!record) return;

    const search = new URLSearchParams();

    if (props.withPenalty)
    {
      search.set("penalty", "t");
    }

    navigator.clipboard.writeText(
      [
        config.link_pagamento.url,
        record.contract_hash,
        record.id,
        search.size ? `?${search}` : "",
      ]
        .filter(Boolean)
        .join("/"),
    );
    notify(
      `Link de pagamento ${props.withPenalty ? "C/Multa" : ""} copiado com sucesso!`,
      {
        type: "success",
      },
    );
  };

  return props.onlyIcon ? (
    <ButtonIcon color={props.withPenalty ? "error" : "secondary"} onClick={btnClick}>
      {props.withPenalty ? (
        <AddLinkIcon sx={{ margin: 0 }} />
      ) : (
        <InsertLinkIcon sx={{ margin: 0 }} />
      )}
    </ButtonIcon>
  ) : (
    <Button
      label={props.withPenalty ? "C/Multa" : "S/Multa"}
      color={props.withPenalty ? "error" : "secondary"}
      onClick={btnClick}
      sx={{ wordWrap: "no-break" }}>
      {props.withPenalty ? (
        <AddLinkIcon sx={{ margin: 0 }} />
      ) : (
        <InsertLinkIcon sx={{ margin: 0 }} />
      )}
    </Button>
  );
}
