import { usePlacesWidget } from "react-google-autocomplete";
import { useInput, InputHelperText } from "react-admin";
import TextField from "@mui/material/TextField";
import config from "../../config";

const GooglePlacesInput = ({ setAddress, ...props }) =>
{
  const { onChange, onBlur, label, helperText, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired
  } = useInput({ onChange, onBlur, ...rest });

  const { ref: inputRef } = usePlacesWidget(
  {
    apiKey: config.google_places.api_key,
    options:
    {
      fields: ["geometry.location", "formatted_address", "address_components"],
      types: ["geocode"],
      componentRestrictions: { country: "br" },
    },
    language: "pt",
    onPlaceSelected: async (place) =>
    {
      if (place)
      {
        var addressRet = [];
        const { address_components, formatted_address, geometry } = place;

        address_components.forEach((item, i) =>
        {
          addressRet.push({ long_name: item.long_name, short_name: item.short_name, types: item.types});
        });

        addressRet.push({ long_name: geometry.location.lat(), short_name: geometry.location.lat(), types: [ "latitude" ]});
        addressRet.push({ long_name: geometry.location.lng(), short_name: geometry.location.lng(), types: [ "longitude" ]});

        setAddress(addressRet);
        field.onChange(formatted_address);
      }
    }
  });

  return (
    <TextField
      {...field}
      label={label}
      error={(isTouched || isSubmitted) && invalid}
      helperText={
        helperText !== false || ((isTouched || isSubmitted) && invalid) ? (
          <InputHelperText
            touched={isTouched || isSubmitted}
            error={error?.message}
            helperText={helperText}
          />
        ) : ("")
      }
      required={isRequired}
      inputProps={{ ref: inputRef }}
      {...rest}
    />
  );
};

export default GooglePlacesInput;
