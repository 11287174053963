import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField
} from 'react-admin';

const UserEnterpriseShow = () => (
  <Show
    title="Usuário Empresa">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" label="Nome" />
      <TextField source="email" label="E-mail" />
      <TextField source="enterprise_name" label="Empresa" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
      <ArrayField label="Permissões" source="permissions_list" fieldKey="uuid">
        <SingleFieldList
          linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default UserEnterpriseShow;
