import { useGetOne } from "react-admin";
import { getCurrentDate } from "../utils/api-params";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Box } from "@mui/material";
import SelectDateInpunt from "../components/inputs/select-date-input";
import IconCardLabel from "../components/icon-card-label";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClientIcon from "@mui/icons-material/GroupAdd";
import WithContractIcon from "@mui/icons-material/Handshake";
import WithOutContractIcon from "@mui/icons-material/SubtitlesOff";
import ContractIcon from "@mui/icons-material/Gavel";
import FinishedIcon from "@mui/icons-material/DoneAll";
import ContractsValueCostIcon from "@mui/icons-material/Paid";
import GainValueIcon from "@mui/icons-material/CurrencyExchange";
import WithdrawalValueIcon from "@mui/icons-material/MoneyOff";
import BalanceValueIcon from "@mui/icons-material/Savings";
import moment from "moment";
import formatValue from "../utils/currency";
import { calculateGain, calculateBalance } from "../utils/utils";

const EnterpriseMonthlyDashboard = () =>
{
  const currentDate = (getCurrentDate() !== null ? getCurrentDate() : moment().format('M/YYYY'));
  const currentMonth = (currentDate.split('/')[0] - 1);
  const currentYear = currentDate.split('/')[1];
  const firstDateMonth = moment().set('month', currentMonth).set('year', currentYear).startOf('month').format('YYYY-MM-DD');
  const lastDateMonth = moment().set('month', currentMonth).set('year', currentYear).endOf('month').format('YYYY-MM-DD');
  const toClients = `/client?displayedFilters={"date_init_created_at":true,"date_end_created_at":true,"activated":true}&filter={"date_init_created_at":"${firstDateMonth}","date_end_created_at":"${lastDateMonth}","activated":true}`;
  const toClientsWithContract = `/client?displayedFilters={"with_contract":true,"date_init_created_at":true,"date_end_created_at":true,"activated":true}&filter={"with_contract":true,"date_init_created_at":"${firstDateMonth}","date_end_created_at":"${lastDateMonth}","activated":true}`;
  const toClientsWithoutContract = `/client?displayedFilters={"with_out_contract":true,"date_init_created_at":true,"date_end_created_at":true,"activated":true}&filter={"with_out_contract":true,"date_init_created_at":"${firstDateMonth}","date_end_created_at":"${lastDateMonth}","activated":true}`;
  const toContracts = `/contract?displayedFilters={"date_init_created_at":true,"date_end_created_at":true}&filter={"date_init_created_at":"${firstDateMonth}","date_end_created_at":"${lastDateMonth}"}`;
  const toContractsFinalized = `/contract?displayedFilters={"status_contract_type":true,"date_init":true,"date_end":true}&filter={"status_contract_type":2,"date_init":"${firstDateMonth}","date_end":"${lastDateMonth}"}`;

  const { data, isLoading } = useGetOne("chart/monthly-values", { id: currentDate });

  if (!data || isLoading)
  {
    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" component="h1">
            Carregando...
          </Typography>
        </AccordionSummary>
      </Accordion>
    );
  }
  else
  {
    var gain = calculateGain(data.monthly_installment_gain, data.monthly_installment_penalty);
    var balance = calculateBalance(gain, data.monthly_withdrawal);

    return (
      <Accordion
        defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            flexWrap="wrap"
            alignContent="center"
            justifyContent="flex-start"
            maxWidth="100wh"
            gap={1}>
            <Typography variant="h6" component="h1">
              Mês
            </Typography>
            <Box
              sx={{
                width: { xs: "calc(100vw - 140px)", md: "calc(100vw - 360px)" },
                maxWidth: { xs: "100%", lg: "200px" }
              }}>
              <SelectDateInpunt />
            </Box>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={4}>
              <IconCardLabel
                to={toClients}
                icon={ClientIcon}
                title="Clientes"
                subtitle={data.monthly_count_clients}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <IconCardLabel
                to={toClientsWithContract}
                icon={WithContractIcon}
                title="Clientes com contrato"
                subtitle={data.monthly_count_clients_with_contract}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <IconCardLabel
                to={toClientsWithoutContract}
                icon={WithOutContractIcon}
                title="Clientes sem contrato"
                subtitle={data.monthly_count_clients_with_out_contract}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <IconCardLabel
                to={toContracts}
                icon={ContractIcon}
                title="Contratos"
                subtitle={data.monthly_count_contracts}
                color="#CC8400"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <IconCardLabel
                to={toContractsFinalized}
                icon={FinishedIcon}
                title="Contratos finalizados"
                subtitle={data.monthly_count_contracts_finalized}
                color="#CC8400"
              />
            </Grid>
            <Grid item xs={12} sm={4} />

            <Grid item xs={12} sm={3}>
              <IconCardLabel
                icon={ContractsValueCostIcon}
                title="Valor investido"
                subtitle={formatValue(data.monthly_contracts_cost)}
                color="#008000"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <IconCardLabel
                icon={GainValueIcon}
                title="Lucro"
                subtitle={formatValue(gain)}
                color="#008000"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <IconCardLabel
                icon={WithdrawalValueIcon}
                title="Despesas"
                subtitle={formatValue(data.monthly_withdrawal)}
                color="#B31312"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <IconCardLabel
                icon={BalanceValueIcon}
                title="Saldo"
                subtitle={formatValue(balance)}
                color={balance < 0 ? "#B31312" : "#008000"}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
};

export default EnterpriseMonthlyDashboard;
