import {
  Edit,
  FormTab,
  TabbedForm,
  TabbedFormTabs,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  DateInput,
  Labeled,
  useUpdate,
  useRedirect
} from 'react-admin';
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, hasValue, phone, cellphone, file } from "../../../utils/schemas";
import GooglePlacesInput from "../../../components/inputs/google-places-input";
import MaskTextInput from "../../../components/inputs/mask-text-input";

const Schema = Yup.object({
  employee_situation_type: leastOneOption(),
  name: hasValue(),
  phone: phone(false),
  cellphone: cellphone(false),
  enterprise_employee_files: Yup.array().of(
    Yup.object().shape(
    {
      file_name: hasValue(),
      file: file()
    })
  )
});
const EnterpriseEmployeeEdit = () =>
{
  const [update] = useUpdate();
  const redirect = useRedirect();
  const { id } = useParams();
  const [newAddress, setNewAddress] = useState(null);

  const employeeUpdate = (data) =>
  {
    if (newAddress)
      data.new_address = newAddress;

    update("enterprise-employee", { id: id, data: data }, { onSuccess: (employee) => { redirect("/enterprise-employee"); }});
  };

  return (
    <Edit
      title={ <Title subtitle="Editar Funcionário" source="name" /> }
      redirect="list"
      mutationMode="pessimistic">
      <TabbedForm
        noValidate
        onSubmit={employeeUpdate}
        resolver={yupResolver(Schema)}
        tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
        toolbar={<ToolbarSave />}>
        <FormTab label="Dados">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={2}>
              <TextInput source="id" label="Id" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskTextInput source="document" label="CPF" mask="###.###.###-##" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={8} />

            <Grid item xs={12} sm={6}>
              <ReferenceInput source="employee_situation_type" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/employee-situations">
                <SelectInput label="Situação" optionText="name" optionValue="type" fullWidth isRequired />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="name" label="Nome" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DateInput source="birthdate" label="Data Nascimento" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="nationality" label="Nacionalidade" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <MaskTextInput source="phone" label="Telefone Fixo" mask="(##) ####-####" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskTextInput source="cellphone" label="Celular Principal" mask="(##) #####-####" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="work_document" label="Carteira de Trabalho" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="social_document" label="PIS/PASEP" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="voter_document" label="Título de Eleitor" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="identifier_document" label="Identidade" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="key_transfer" label="Chave PIX" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>

        <FormTab label="Contrato">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={2}>
              <TextInput source="contract_type" label="Tipo de Contrato" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="office" label="Cargo/Função" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <NumberInput source="value_salary" label="Salário" step={1} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="workplace" label="Local de Trabalho" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DateInput source="admission_date" label="Data de Admissão" fullWidth />
            </Grid>
            <Grid item xs={12} sm={8} />

            <Grid item xs={12} sm={4}>
              <TextInput source="working_day" label="Jornada de Trabalho" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>

        <FormTab label="Endereço">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput source="address.city.name" label="Cidade" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="address.zip_code" label="CEP" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput source="address.street" label="Rua" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="address.number" label="Número" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput source="address.neighborhood" label="Bairro" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <Labeled label="Novo Endereço" fullWidth>
                <GooglePlacesInput source="new_address" setAddress={setNewAddress} />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <TextInput source="address.complement" label="Complemento" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};

export default EnterpriseEmployeeEdit;
