import React from 'react';
import { useFieldValue } from 'react-admin';
import { JsonField } from "react-admin-json-view";
import { translateHeader } from "../utils/utils";

const keysFilter =
[
  'created_at',
  'updated_at',
  'enterprise_id',
  'user_enterprise_id',
  'status_cash_register',
  'status_invoice_type',
  'status_contract_type',
  'status_installment_type',
  'enterprise_type_invoice_id',
  'date_billing',
  'original_date_billing',
  'date_payment',
  'enterprise_billing_period_id',
  'enterprise_report_commission_id',
  'type_enterprise_report_commission',
  'value_gain_without_penalties',
  'value_commission',
  'user_enterprise',
  'date_init',
  'date_end',
  'value_initial',
  'value_expected',
  'value_total',
  'value_cost',
  'value_fees',
  'value_paid',
  'value_to_receive',
  'value_extended',
  'value_canceled',
  'value_penalties',
  'value_gain',
  'value_gain_without_penalties',
  'enterprise_type_employee_cost_id',
  'enterprise_employee_id',
  'client',
  'enterprise_payment_form_id',
  'conductors_id',
  'created_at_format',
  'user_enterprise_name',
  'key',
  'date_maintenance',
  'enterprise_vehicle_id',
  'date_due',
  'date_penalty',
  'status_penalty_type',
  'enterprise_bank_account_id',
  'enterprise_vehicle_penalty_id',
  'value_discount',
  'employee_situation_type',
  'city_id'
];

function translateObjectProps(data)
{
  return Object.keys(data).forEach((key) =>
  {
    if (keysFilter.includes(key))
      delete data[key];
    else
    {
      const value = data[key];
      var itemTranslated = translateHeader(key.toString());
      data[itemTranslated] = value;

      if (key.toString() !== itemTranslated)
        delete data[key];

      if (typeof value === 'object' && value !== null)
        translateObjectProps(value)
      else
        return;
    }
  }, {});
}

const JsonTranslatedField = () =>
{
  var data = useFieldValue({ source: "data" });
  translateObjectProps(data);

  return (
    <JsonField
      source="data"
      label="Dados"
      reactJsonOptions={{ name: null, collapsed: false, enableClipboard: false, displayDataTypes: false }} />
  );
};

export default JsonTranslatedField;
