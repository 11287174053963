import { useRecordContext, useResourceContext, useRedirect } from "react-admin";

function wrapperButtonForRedirect(WrapperComponent, action)
{
  return function EnhancedComponent()
  {
    const resource = useResourceContext();
    const record = useRecordContext();
    const redirect = useRedirect();

    const handlerClick = () =>
    {
      var local = "/" + resource + "/" + record.id;
      if (action && action.length > 0)
        local += "/" + action;
      
      redirect(local);
    };

    return (
      <WrapperComponent
        resource={resource}
        record={record}
        onClick={handlerClick}
      />
    );
  };
}

export default wrapperButtonForRedirect;
