import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  DateField
} from 'react-admin';

const EnterpriseBillingPeriodShow = () => (
  <Show
    title="Período de Pagamento">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" label="Nome" />
      <NumberField source="number_installments" label="Número de Parcelas" />
      <NumberField source="interval_days" label="Intervalo de Dias" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default EnterpriseBillingPeriodShow;
