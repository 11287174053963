function getLocation(callback)
{
  return new Promise((resolve, reject) =>
  {
    if ("geolocation" in navigator)
    {
      navigator.geolocation.getCurrentPosition(
      (position) =>
      {
        return resolve(position);
      },
      (error) =>
      {
        return resolve(null);
      });
    }
    else
      return resolve(null);
  });
};

export default getLocation;
