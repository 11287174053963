import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  FunctionField,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";

const EnterpriseInstallmentPenaltyShow = () => (
  <Show
    title="Multa">
    <SimpleShowLayout>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="id" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <FunctionField render={record => `${record.installment_number} - ${record.installment_count_installments}`} label="Número da Parcela" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="installment_client_name" label="Cliente" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="installment_contract_name" label="Contrato" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="value_format" label="Valor Recebido" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="enterprise_payment_form_name" label="Forma" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="customer_name" label="Cobrada Por" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <DateField source="date_payment" label="Data do Pagamento" locales="pt-BR" showTime />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <DateField source="created_at" label="Criado em" locales="pt-BR" showTime />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export default EnterpriseInstallmentPenaltyShow;
