import React from 'react';
import {
  useRecordContext
} from 'react-admin';

const LinkField = ({ source, ...props }) =>
{
  const record = useRecordContext();
  return (
    <span>
    {
      <a
        href={record[source]}
        target="_blank"
        rel="noopener noreferrer"
        onClick={ (e) => { e.stopPropagation() }}
      >{record[source]}</a>
    }
    </span>
  );
};

LinkField.defaultProps =
{
  addLabel: true,
  source: 'Link'
};

export default LinkField;
