import { defaultTheme } from "react-admin";

export const themeOptions = {
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#403C90",
    },
    secondary: {
      main: "#9C27B0",
    },
  },
  typography: {
    fontFamily: "Lexend",
  },
  components: {
    ...defaultTheme.components,
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableelevation: "true",
        fullwidth: "true",
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "secondary",
        size: "large",
      },
      styleOverrides: {
        root: {
          color: "white",
          fontWeight: "bold",
          shadow: "none",
          boxShadow: "none",
          "&:hover": {
            shadow: "none",
            boxShadow: "none",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          marginTop: 10,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "16px !important",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        fullwidth: "true",
        size: "small",
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "& .RaConfirm-confirmPrimary": {
            color: "white !important",
          },
          "& button": {
            flex: 1,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0 24px 20px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          width: "auto !important",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiTextField-root": {
            margin: "0 !important",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflow: "visible !important",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        size: "small",
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          "& .RaLayout-content": {
            paddingTop: 10,
          },
        },
      },
    },
    RaListToolbar: {
      styleOverrides: {
        root: {
          alignItems: "center",
          padding: 0,
        },
      },
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          minHeight: "auto !important",
          alignItems: "center",
          padding: 0,
          gap: 10,
          "& .RaFilterForm-filterFormInput, & .MuiFormControl-root": {
            marginTop: "0 !important",
            width: "100%",
          },
        },
      },
    },
    RaSearchInput: {
      styleOverrides: {
        root: {
          margin: "0 !important",
        },
      },
    },
    RaSelectInput: {
      styleOverrides: {
        root: {
          margin: "0 !important",
          width: "100%",
        },
      },
    },
    RaFilterFormInput: {
      styleOverrides: {
        root: {
          alignItems: "center",
          width: "100%",
          maxWidth: "200px",
          margin: 0,
          "& .RaFilterFormInput-hideButton": {
            margin: "0 !important",
          },
          "& .RaFilterFormInput-spacer": {
            display: "none",
          },
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          alignItems: "center",
          margin: 0,
          padding: 0,
        },
      },
    },
    RaCreate: {
      styleOverrides: {
        root: {
          margin: "0 !important",
          "& .RaCreate-noActions": {
            margin: "0 !important",
          },
        },
      },
    },
    RaLabeled: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            fontSize: "0.9em",
          },
          "& .RaLabeled-label": {
            fontSize: "1em",
            fontWeight: "bold",
            color: "black",
          },
        },
      },
    },
  },
};

export default themeOptions;
