import { SearchInput, ReferenceInput, SelectInput, DateInput } from "react-admin";

const FilterEnterpriseInvoice = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Status da Conta" source="status_invoice_type" reference="setup/enterprise/status-invoice">
    <SelectInput
      label="Status da Conta"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Tipo" source="enterprise_type_invoice_id" reference="setup/enterprise/types-invoice">
    <SelectInput
      label="Tipo"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      limitChoicesToValue={true}
      shouldRenderSuggestions={(val) => { return val ? val.trim().length > 1 : false }}
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Conta Bancária" source="enterprise_bank_account_id" reference="setup/enterprise/bank-accounts">
    <SelectInput
      label="Forma de Pagamento"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Forma de Pagamento" source="enterprise_payment_form_id" reference="setup/enterprise/payment-forms">
    <SelectInput
      label="Forma de Pagamento"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <DateInput source="date_due_init" label="Data de Início Vencimento" />,
  <DateInput source="date_due_end" label="Data Final Vencimento" />
];

export default FilterEnterpriseInvoice;
