import { useGetOne, useRefresh } from "react-admin";
import { useState } from "react";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Box } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SelectCountMonthsInpunt from "../inputs/select-count-months-input";
import SelectTypesInvoiceInput from "../inputs/select-types-invoice-input";
import ButtonRefreshChart from "../buttons/button-refresh-chart";
import { setFilterChartInvoicesPerType, getFilterChartInvoicesPerType } from "../../utils/api-params";
import { parseDataForChartMonths, getDistincIdsByArray, getChartColors } from "../../utils/utils";
import formatValue from "../../utils/currency";
import config from "../../config";

const barBaseSlotProps =
{
  legend:
  {
    direction: "row",
    position:
    {
      vertical: "bottom",
      horizontal: "middle"
    },
    padding: 0
  }
};

const ChartMonthsInvoicesPerType = () =>
{
  var filters = getFilterChartInvoicesPerType();
  if (!filters)
  {
    setFilterChartInvoicesPerType(config.chart.max_months, []);
    filters = getFilterChartInvoicesPerType();
  }

  const valueFormatter = (value) => !value ? "R$ 0,00" : formatValue(value);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [countMonthsSelected, setCountMonthsSelected] = useState();
  const [typesInvoiceSelected, setTypesInvoiceSelected] = useState();
  const refresh = useRefresh();
  const { data, isLoading } = useGetOne("chart/months-invoices-per-type", { id: filters.count_months, meta: filters.types_invoice });

  const setSelectedCountMonths = (countMonths) =>
  {
    setCountMonthsSelected(countMonths);
    setButtonDisabled(false);
  };

  const setSelectedTypesInvoice = (types_invoice) =>
  {
    setTypesInvoiceSelected(types_invoice);
    setButtonDisabled(false);
  }

  const onClickButtonRefresh = (e) =>
  {
    e.stopPropagation();

    setFilterChartInvoicesPerType(
      (countMonthsSelected ? countMonthsSelected : filters.count_months),
      (typesInvoiceSelected ? typesInvoiceSelected : filters.types_invoice));
    setButtonDisabled(true);
    refresh();
  }

  if (!data || isLoading)
  {
    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" component="h1">
            Carregando...
          </Typography>
        </AccordionSummary>
      </Accordion>
    );
  }
  else
  {
    const { chartLabels, chartSeries } = parseDataForChartMonths(data.result, valueFormatter);
    const typesInvoiceId = getDistincIdsByArray(data.result);

    return (
      <Accordion
        defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            flexWrap="wrap"
            alignContent="center"
            justifyContent="flex-start"
            maxWidth="100wh"
            gap={1}>
            <Typography variant="h6" component="h1">
              Contas a pagar por tipo
            </Typography>
            <Box
              sx={{
                width: { xs: "calc(100vw - 140px)", md: "calc(100vw - 360px)" },
                maxWidth: { xs: "100%", lg: "200px" }
              }}>
              <SelectCountMonthsInpunt
                currentCountMonths={filters.count_months}
                setCurrentCountMonths={setSelectedCountMonths}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "calc(100vw - 140px)", md: "calc(100vw - 360px)" },
                maxWidth: { xs: "100%", lg: "360px" }
              }}>
              <SelectTypesInvoiceInput
                currentTypesInvoice={typesInvoiceId}
                setSelectedTypesInvoice={setSelectedTypesInvoice}
              />
            </Box>
            <ButtonRefreshChart
              disabled={buttonDisabled}
              onClick={onClickButtonRefresh} />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={12}>
              <BarChart
                xAxis={[{ scaleType: "band", data: chartLabels }]}
                margin={{ top: 10, bottom: 90, left: 70, right: 70 }}
                slotProps={barBaseSlotProps}
                series={chartSeries}
                colors={getChartColors}
                height={450}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
};

export default ChartMonthsInvoicesPerType;
