import { stringify } from "query-string";
import axios from "axios";
import paramsToFormData from "../utils/params-to-form-data";
import getLocation from "../utils/location";
import {
  getUrlApi,
  getRequestAdminMe,
  getRequestEnterpriseMe,
  getRequestCustomerMe,
  getHeadersFormData,
  setLocation
} from "../utils/api-params";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  CREATE,
  DELETE,
  HttpError,
  withLifecycleCallbacks
} from "react-admin";
import { setCurrentUser } from '../utils/api-params';
import config from "../config";

const dataProvider =
{
  getList: (resource, params) => provider(GET_LIST, resource, params),
  getOne: (resource, params) => provider(GET_ONE, resource, params),
  getMany: (resource, params) => provider(GET_MANY, resource, params),
  getManyReference: (resource, params) => provider(GET_MANY_REFERENCE, resource, params),
  update: (resource, params) => provider(UPDATE, resource, params),
  create: (resource, params) => provider(CREATE, resource, params),
  delete: (resource, params) => provider(DELETE, resource, params)
};

const provider = async (type, resource, params) =>
{
  const apiUrl = getUrlApi();
  const options =
  {
    url: "",
    method: "GET",
    headers: getHeadersFormData(),
    data: paramsToFormData(params.data)
  };

  switch (type)
  {
    case GET_LIST:
    {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query =
      {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter)
      };

      // EXPORT
      if (perPage === config.export.max_results)
        options.url = `${apiUrl}/${resource}/export?${stringify(query)}`;
      // LIST
      else
      {
        // CUSTOMER INSTALLMENTS OR CUSTOMER CLIENT PROSPECTION OR CUSTOMER ROUTE
        if (resource === 'customer-installment' ||
            resource === 'customer-client-prospection' ||
            resource === 'customer-route')
        {
          // GET USER CURRENT LOCATION
          const location = await getLocation();
          if (location !== null)
          {
            setLocation({ lat: location.coords.latitude, lng: location.coords.longitude });
            options.url = `${apiUrl}/${resource}?${stringify(query)}&lat=${location.coords.latitude}&lng=${location.coords.longitude}`;
          }
          else
            options.url = `${apiUrl}/${resource}?${stringify(query)}`;
        }
        else if (resource === 'enterprise-report-payment')
          options.url = `${apiUrl}/report/enterprise/payment?${stringify(query)}`;
        else if (resource === 'customer-report-payment')
          options.url = `${apiUrl}/report/customer/payment?${stringify(query)}`;
        else
          options.url = `${apiUrl}/${resource}?${stringify(query)}`;
      }

      break;
    }
    case GET_ONE:
    {
      if (resource === 'user-enterprise/me')
        options.url = `${apiUrl}/${resource}`;
      else if (resource === 'setup/admin/permissions')
        options.url = `${apiUrl}/setup/admin/permissions`;
      else if (resource === 'chart/months-financial' ||
               resource === 'chart/months-clients' ||
               resource === 'chart/months-contracts')
        options.url = `${apiUrl}/${resource}?count_months=${params.id}`;
      else if (resource === 'chart/months-clients-per-region')
      {
        options.url = `${apiUrl}/${resource}?count_months=${params.id}`;

        // REGIONS
        if (params.meta && params.meta.length > 0)
        {
          params.meta.forEach((item, i) =>
          {
            options.url += `&regions[]=${item}`;
          });
        }
      }
      else if (resource === 'chart/months-clients-per-niche')
      {
        options.url = `${apiUrl}/${resource}?count_months=${params.id}`;

        // NICHES
        if (params.meta && params.meta.length > 0)
        {
          params.meta.forEach((item, i) =>
          {
            options.url += `&niches[]=${item}`;
          });
        }
      }
      else if (resource === 'chart/months-contracts-per-billing-period')
      {
        options.url = `${apiUrl}/${resource}?count_months=${params.id}`;

        // BILLING PERIODS
        if (params.meta && params.meta.length > 0)
        {
          params.meta.forEach((item, i) =>
          {
            options.url += `&billing_periods[]=${item}`;
          });
        }
      }
      else if (resource === 'chart/months-withdrawals-per-type')
      {
        options.url = `${apiUrl}/${resource}?count_months=${params.id}`;

        // TYPES
        if (params.meta && params.meta.length > 0)
        {
          params.meta.forEach((item, i) =>
          {
            options.url += `&types_withdrawal[]=${item}`;
          });
        }
      }
      else if (resource === 'chart/months-withdrawals-per-customer')
      {
        options.url = `${apiUrl}/${resource}?count_months=${params.id}`;

        // TYPES
        if (params.meta && params.meta.length > 0)
        {
          params.meta.forEach((item, i) =>
          {
            options.url += `&customers[]=${item}`;
          });
        }
      }
      else if (resource === 'chart/months-invoices-per-type')
      {
        options.url = `${apiUrl}/${resource}?count_months=${params.id}`;

        // TYPES
        if (params.meta && params.meta.length > 0)
        {
          params.meta.forEach((item, i) =>
          {
            options.url += `&types_invoice[]=${item}`;
          });
        }
      }
      else if (resource === 'chart/monthly-values')
        options.url = `${apiUrl}/${resource}?month_year=${params.id}`;
      else if (resource === 'enterprise-contract-installment')
        options.url = `${apiUrl}/enterprise-installment/${params.id}`;
      else if (resource === 'customer-cash-register/daily-values')
      {
        options.url = `${apiUrl}/${resource}?date=${params.id}`;

        // TYPES
        if (params.meta && params.meta.length > 0)
        {
          params.meta.forEach((item, i) =>
          {
            options.url += `&payment_forms[]=${item}`;
          });
        }
      }
      else if (resource === 'enterprise-installment-pending-penalty')
      {
        options.url = `${apiUrl}/enterprise-installment-penalty/${params.id}`;
      }
      else
        options.url = `${apiUrl}/${resource}/${params.id}`;

      break;
    }
    case GET_MANY:
    {
      options.url = `${apiUrl}/${resource}`;

      break;
    }
    case GET_MANY_REFERENCE:
    {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query =
      {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(
        {
          ...params.filter,
          [params.target]: params.id,
        })
      };

      options.url = `${apiUrl}/${resource}?${stringify(query)}`;

      break;
    }
    case UPDATE:
    {
      options.method = "PUT";

      if (resource === 'enterprise-installment-pending-penalty')
      {
        options.url = `${apiUrl}/enterprise-installment-penalty/${params.id}`;
      }
      else
        options.url = `${apiUrl}/${resource}/${params.id}`;

      break;
    }
    case CREATE:
    {
      options.method = "POST";
      options.url = `${apiUrl}/${resource}`;

      break;
    }
    case DELETE:
    {
      options.method = "DELETE";
      options.url = `${apiUrl}/${resource}/${params.id}`;

      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  try
  {
    const res = await axios(options);
    const response = res.data;

    switch (type)
    {
      case GET_LIST:
      case GET_MANY:
        const data = response.data;
        const result = response.result;
        const meta = response.meta;
        var array = [];

        // ARRAY
        if (!Array.isArray(result))
          array.push({ id: 1, data: result})

        return {
          data: data ? data : Array.isArray(result) ? result : array,
          total: meta.total || 1,
          pagination: {
            page: meta.page || 1,
            perPage: meta.perpage || 10,
          }
        };
      case GET_MANY_REFERENCE:
        return {
          data: response.result,
          total: response.result.lenght,
        };
      case GET_ONE:
        if (!response.result.id)
        {
          // ARRAY
          if (Array.isArray(response.result))
            return {
              data: {
                id : 1,
                result: response.result
              },
            };
          else
            return {
              data: {
                id : 1,
                ...response.result
              },
            };
        }
        else
          return {
            data: response.result
          };
      case UPDATE:
      case CREATE:
        return {
          data: response.result,
        };
      default:
        return {
          data: []
        };
    }
  }
  catch (error)
  {
    if (error.response.status < 200 || error.response.status >= 300)
    {
      if (error.response.data && error.response.data.error)
        return Promise.reject(new HttpError(error.response.data.error, error.response.status));
      else
        return Promise.reject(new HttpError(error.response.statusText, error.response.status));
    }
  }
};

// REFRESH CURRENT USER
const refreshCurrentUser = async (type_user, params) =>
{
  const { data, error } = await axios(
    type_user === config.type_user.admin ? getRequestAdminMe() :
    type_user === config.type_user.enterprise ? getRequestEnterpriseMe() :
    getRequestCustomerMe());

  if (!error)
  {
    data.result.type_user = type_user;
    setCurrentUser(data.result);
  }

  return params;
};

export default withLifecycleCallbacks(dataProvider,
[
  {
    resource: 'enterprise-invoice',
    beforeGetList: async (params, dataProvider) => await refreshCurrentUser(config.type_user.enterprise, params),
    afterCreate: async (params, dataProvider) => await refreshCurrentUser(config.type_user.enterprise, params)
  },
  {
    resource: 'enterprise-cash-register',
    beforeGetList: async (params, dataProvider) => await refreshCurrentUser(config.type_user.enterprise, params)
  },
  {
    resource: 'enterprise-banking-flow',
    beforeGetList: async (params, dataProvider) => await refreshCurrentUser(config.type_user.enterprise, params)
  },
  {
    resource: 'enterprise-vehicle-penalty',
    beforeGetList: async (params, dataProvider) => await refreshCurrentUser(config.type_user.enterprise, params),
    afterCreate: async (params, dataProvider) => await refreshCurrentUser(config.type_user.enterprise, params)
  },
  {
    resource: 'customer-cash-register',
    beforeGetList: async (params, dataProvider) => await refreshCurrentUser(config.type_user.customer, params),
    afterCreate: async (params, dataProvider) => await refreshCurrentUser(config.type_user.customer, params)
  }
]);
