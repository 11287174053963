import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  DateInput,
  NumberInput
} from 'react-admin';
import { Grid } from '@mui/material';
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, validDate, number } from "../../../utils/schemas";

const Schema = Yup.object({
  type_enterprise_report_commission: leastOneOption(),
  customer_id: leastOneOption(),
  date_init: validDate(),
  date_end: validDate(),
  percentage: number()
});

const EnterpriseReportCommissionCreate = () => (
  <Create
    title="Novo Relatório de Comissão"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <ReferenceInput source="type_enterprise_report_commission" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/types-report-commission" >
            <SelectInput label="Tipo" optionText="name" optionValue="type" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ReferenceInput source="customer_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/customers" >
            <AutocompleteInput label="Colaborador" optionText="name" optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 1 : false }} fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <DateInput source="date_init" label="Data de início" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DateInput source="date_end" label="Data de Término" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <NumberInput source="percentage" label="Porcentagem" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EnterpriseReportCommissionCreate;
