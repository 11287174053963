import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import FilterCustomerReportCommission from "../../../filters/customer/report-commission";

const CustomerReportCommissionList = () => (
  <List
    title="Relatórios de Comissão"
    sort={{ field: 'erc.id', order: 'DESC' }}
    filters={ FilterCustomerReportCommission }
    actions={ null }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" label="Id" sortBy="erc.id" />
      <TextField source="type_enterprise_report_commission_name" label="Tipo" sortBy="erc.type_enterprise_report_commission" />
      <TextField source="date_init_format" label="Data de Início" sortBy="erc.date_init" />
      <TextField source="date_end_format" label="Data de Término" sortBy="erc.date_end" />
      <TextField source="count_contracts" label="Nº Contratos" sortBy="count_contracts" />
      <TextField source="percentage" label="Porcentagem" sortBy="erc.percentage" />
      <TextField source="value_commission_format" label="Valor Comissão" sortBy="erc.value_commission" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="erc.created_at" />
    </Datagrid>
  </List>
);

export default CustomerReportCommissionList;
