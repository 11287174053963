import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  FunctionField,
  ArrayField,
  Datagrid,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";
import formatValue from "../../../utils/currency";

const EnterpriseInstallmentShow = () => (
  <Show
    title="Parcela">
    <TabbedShowLayout>
      <Tab label="Dados">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="id" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="status_installment_name" label="Status" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField render={record => `${record.number} - ${record.count_installments}`} label="Número da Parcela" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="client.name" label="Cliente" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="contract_name" label="Contrato" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_original" label="Valor Original" render={record => `${formatValue(record.value_total)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_cost" label="Valor Custo" render={record => `${formatValue(record.value_cost)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_fees" label="Valor Juros" render={record => `${formatValue(record.value_fees)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_total" label="Valor Total" render={record => `${formatValue(record.value_total)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="original_date_billing_format" label="Data de Cobrança Original" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="date_billing_format" label="Data de Cobrança" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </Tab>
      <Tab label="Pagamento">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="payment.customer_name" label="Cobrada Por" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="payment.enterprise_payment_form_name" label="Forma" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="payment.value_format" label="Valor Recebido" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="payment.value_gain" label="Valor do Lucro" render={record => `${record.payment ? formatValue(record.payment.value_gain) : ''}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="payment.date_payment_format" label="Data do Pagamento" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={8} />
        </Grid>
      </Tab>
      <Tab label="Observações">
        <ArrayField source="notes" label="">
          <Datagrid
            rowClick={false}
            bulkActionButtons={false}>
            <TextField source="customer_name" label="Colaborador" />
            <TextField source="note" label="Observação" />
            <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Multas">
        <ArrayField source="penalties" label="">
          <Datagrid
            rowClick={false}
            bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="value_format" label="Valor Recebido" />
            <TextField source="enterprise_payment_form_name" label="Forma" />
            <TextField source="customer_name" label="Cobrada Por" />
            <TextField source="date_payment_format" label="Data do Pagamento" />
            <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default EnterpriseInstallmentShow;
