import { Stack } from "@mui/system";

/**
 * @param {object} props
 * @param {import("react").ReactNode} [props.children]
 * @param {"row" | "column"} [props.direction]
 * @param {number | string} [props.gap]
 * @param {"flex-start" | "flex-end" | "center"} [props.align]
 */
function WrapperDirection({
  children,
  direction = "row",
  gap = 1,
  align = "flex-start",
})
{
  return (
    <Stack direction={direction} gap={gap} sx={{ justifyContent: align }}>
      {children}
    </Stack>
  );
}

export default WrapperDirection;
