import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField
} from 'react-admin';

const EnterpriseTypeInvoiceShow = () => (
  <Show
    title="Tipo de Conta">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" label="Nome" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default EnterpriseTypeInvoiceShow;
