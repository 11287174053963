import {
  Create,
  FormTab,
  TabbedForm,
  TabbedFormTabs,
  TextInput,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  Labeled,
  usePermissions,
  useCreate,
  useRedirect,
  useNotify
} from "react-admin";
import { useState } from "react";
import { Grid } from "@mui/material";
import GooglePlacesInput from "../../../components/inputs/google-places-input";
import MaskTextInput from "../../../components/inputs/mask-text-input";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  leastOneOption,
  hasValue,
  document,
  phone,
  cellphone,
  cnpj
} from "../../../utils/schemas";

const Schema = (permissions) =>
{
  var obj =
  {
    enterprise_region_id: leastOneOption(),
    name: hasValue(),
    document: document(),
    cellphone: cellphone(false),
    secondary_cellphone: cellphone(false),
    phone: phone(false),
    enterprise_register_number: cnpj(false),
    address: hasValue(),
    enterprise_address: hasValue()
  };

  return Yup.object(obj);
};

const CustomerClientProspectionCreate = () =>
{
  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const [address, setAddress] = useState(null);
  const [enterpriseAddress, setEnterpriseAddress] = useState(null);

  const clientProspectionSave = (data) =>
  {
    if (!address || !enterpriseAddress)
    {
      if (!address && !enterpriseAddress)
        notify("Digite e selecione o Endereço e o Endereço da Empresa!", { type: "error" });
      else if (!address)
        notify("Digite e selecione o Endereço!", { type: "error" });
      else if (!enterpriseAddress)
        notify("Digite e selecione o Endereço da Empresa!", { type: "error" });

      return;
    }

    data.address = address;
    data.enterprise_address = enterpriseAddress;

    create("customer-client-prospection", { data: data }, { onSuccess: client =>  { redirect('/customer-client-prospection'); }});
  };

  return (
    <Create
      title="Novo Cliente Agendado"
      redirect="list">
      <TabbedForm
        noValidate
        onSubmit={clientProspectionSave}
        resolver={yupResolver(Schema(permissions))}
        tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
        toolbar={<ToolbarSave />}>
        <FormTab label="Dados">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <ReferenceInput source="enterprise_region_id" sort={{ field: "name", order: "ASC" }} reference="setup/customer/regions">
                <SelectInput label="Região" optionText="name" optionValue="id" fullWidth isRequired />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <ReferenceInput source="enterprise_client_niche_id" sort={{ field: "name", order: "ASC" }} reference="setup/customer/client/niches">
                <SelectInput label="Nicho" optionText="name" optionValue="id" fullWidth />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <ReferenceInput source="enterprise_client_classification_id" sort={{ field: "name", order: "ASC" }} reference="setup/customer/client/classifications">
                <SelectInput label="Classificação" optionText="name" optionValue="id" fullWidth />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <TextInput source="name" label="Nome" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <MaskTextInput source="document" label="CPF" mask="###.###.###-##" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="email" label="E-mail" type="email" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="instagram" label="Instagram" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <MaskTextInput source="cellphone" label="Celular Principal" mask="(##) #####-####" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskTextInput source="secondary_cellphone" label="Celular Secundário" mask="(##) #####-####" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskTextInput source="phone" label="Telefone Fixo" mask="(##) ####-####" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
            <br />
            <br />
            <br />

            <Grid item xs={12} sm={2}>
              <MaskTextInput source="enterprise_register_number" label="CNPJ" mask="##.###.###/####-##" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="enterprise_name" label="Empresa" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="enterprise_reference" label="Referência" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <TextInput source="comments" label="Observações" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>

        <FormTab label="Endereço">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <Labeled label="Endereço" fullWidth>
                <GooglePlacesInput source="address" setAddress={setAddress} />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <TextInput source="address_complement" label="Complemento do Endereço" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>

        <FormTab label="Endereço da Empresa">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <Labeled label="Endereço da Empresa" fullWidth>
                <GooglePlacesInput source="enterprise_address" setAddress={setEnterpriseAddress} />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <TextInput source="enterprise_address_complement" label="Complemento do Endereço da Empresa" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>

        <FormTab label="Arquivo(s)">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <FileInput source="client_files" label="Arquivo(s)" multiple>
                <FileField source="src" title="title" />
              </FileInput>
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CustomerClientProspectionCreate;
