import {
  Button,
  useNotify,
  useResourceContext,
  useListController,
  usePermissions,
  useResourceDefinitions
} from "react-admin";
import { FileDownload, TextSnippet, PictureAsPdf, Cancel } from "@mui/icons-material";
import {
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { stringify } from "query-string";
import fileDownload from "js-file-download";
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import { xlsx, pdf } from "../../utils/export";
import WrapperDirection from "../wrapper-direction";

function DialogExport()
{
  const resource = useResourceContext();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const { on, toggle } = useToggle();
  const { loading, action } = useForRequest();
  const resourcesDefinitions = useResourceDefinitions();
  const { filterValues, page, perPage, sort } = useListController({ resource: resource });
  const resourceDefinition = resourcesDefinitions[resource];
  const typeUser = permissions.type_user.replace("user-", "");

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const exportXLSX = async () =>
  {
    const { field, order } = sort;
    const query =
    {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(filterValues)
    };

    var url = `export/${typeUser}/${resourceDefinition.options.export}?${stringify(query)}`;
    const { response, error } = await action(url, 'POST', { });
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    var dataXlsx = await xlsx(response.data.result);
    fileDownload(dataXlsx, `${resource}.xlsx`);
    toggle();
  };

  const exportPDF = async () =>
  {
    const { field, order } = sort;
    const query =
    {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(filterValues)
    };

    var url = `export/${typeUser}/${resourceDefinition.options.export}?${stringify(query)}`;
    const { response, error } = await action(url, 'POST', { });
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    var dataPdf = await pdf(response.data.result);
    fileDownload(dataPdf, `${resource}.pdf`);
    toggle();
  };

  return (
    <>
      <Button onClick={handleClick} color="primary" label="EXPORTAR">
        <FileDownload />
      </Button>

      <Dialog
        fullWidth
        open={on}
        onClose={handleCloseClick}
        aria-label="Exportar">
        <DialogTitle>
          Escolha uma opção
        </DialogTitle>
        <DialogContent>
          <WrapperDirection direction="column">
            <MuiButton
              label="Excel (xlsx)"
              color="primary"
              startIcon={<TextSnippet />}
              disabled={loading}
              onClick={exportXLSX}>
              Excel (xlsx)
            </MuiButton>
            <MuiButton
              label="PDF"
              color="secondary"
              startIcon={<PictureAsPdf />}
              disabled={loading}
              onClick={exportPDF}>
              PDF
            </MuiButton>
          </WrapperDirection>
        </DialogContent>
        <DialogActions>
          <MuiButton startIcon={<Cancel />} color="error" onClick={handleCloseClick}>
            Fechar
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogExport;
