import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  Datagrid,
  TextField,
  useListController
} from 'react-admin';
import { Card } from '@mui/material';
import Button from "../../../components/buttons/button";
import FilterCustomerClientProspection from '../../../filters/customer/client-prospection';
import Actions from "../../../ra/list/actions";
import IconMaps from '@mui/icons-material/MyLocation';
import WrapperDirection from "../../../components/wrapper-direction";
import ButtonClientAddress from '../../../components/buttons/button-client-address';
import ButtonClientEnterpriseAddress from '../../../components/buttons/button-client-enterprise-address';
import ButtonClientPhone from '../../../components/buttons/button-client-phone';
import ButtonClientPhoneWhatsapp from '../../../components/buttons/button-client-phone-whatsapp';
import DialogClientActions from  '../../../components/dialogs/dialog-client-actions';
const CustomerClientProspectionPagination = props => <Pagination rowsPerPageOptions={[ 100 ]} {...props} />;

const CustomList = ({ children, actions, filters, title, ...props }) =>
{
  const { data, loading } = useListController();

  const btnClick = () =>
  {
    window.open(mapsUrl);
  };

  if (!loading && data)
  {
    var mapsUrl = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&';
    var wayPoints = '';

    data.forEach((item, i) =>
    {
      if (i === (data.length - 1))
        mapsUrl += ("&destination=" + item.enterprise_address.lat + "," + item.enterprise_address.lng);
      else
        wayPoints += (item.enterprise_address.lat + "," + item.enterprise_address.lng + "|");
    });

    if (wayPoints !== '')
      mapsUrl += ("&waypoints=" + wayPoints);

    return (
      <ListBase {...props}>
        <Title title={title}/>
        <ListToolbar filters={filters} actions={actions}/>
        <Card>
        {children}
        </Card>
        <br/>
        <div>
          <Button
            onClick={btnClick}
            label="Traçar Rota">
            <IconMaps />
          </Button>
        </div>
        <CustomerClientProspectionPagination />
      </ListBase>
    );
  }
  else
    return null;
}

const CustomerClientProspectionList = (props) => (
  <CustomList
    title="Clientes Agendados"
    sort={{ field: 'c.id', order: 'DESC' }}
    perPage={100}
    filters={ FilterCustomerClientProspection }
    actions={ <Actions hasExport={false} /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="name" label="Nome" sortBy="c.name" />
      <TextField source="enterprise_region.name" label="Região" sortBy="er.name" />
      <WrapperDirection>
        <ButtonClientAddress />
        <ButtonClientEnterpriseAddress />
        <ButtonClientPhone />
        <ButtonClientPhoneWhatsapp />
        <DialogClientActions isEnterprise={false} isClient={false} />
      </WrapperDirection>
    </Datagrid>
  </CustomList>
);

export default CustomerClientProspectionList;
