import { useRecordContext, useRedirect } from "react-admin";
import Button from "./button";
import IconEdit from "@mui/icons-material/Edit";

export default function EditInstallmentPendingPenaltyButton()
{
  const record = useRecordContext();
  const redirect = useRedirect();

  const btnClick = () =>
  {
    redirect(
      `/enterprise-installment-pending-penalty/${record?.id}?redirect=${window.location.hash.slice(1)}`,
    );
  };

  return (
    <Button label="Editar Multa" color="info" onClick={btnClick}>
      <IconEdit />
    </Button>
  );
}
