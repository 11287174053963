import {
  ReferenceInput,
  SelectInput
} from "react-admin";

const CustomFilterCustomerReportPayment = [
  <ReferenceInput label="Forma de Pagamento" source="enterprise_payment_form_id" filter={{ allow_customer_installment: true, allow_customer_penalty: true }} reference="setup/customer/payment-forms" alwaysOn>
    <SelectInput
      label="Forma de Pagamento"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>
];

export default CustomFilterCustomerReportPayment;
