import React from "react";
import { useRecordContext } from "react-admin";

const Title = ({ subtitle, source, subvalue = undefined }) =>
{
  const record = useRecordContext();

  if (!record)
    return null;

  var text = subtitle;

  const recordSource = record[source];

  if (recordSource)
  {
    if (subvalue)
      text += `: ${recordSource[subvalue]}`;
    else
      text += `: ${recordSource}`;
  }

  return <span>{text}</span>;
};

export default Title;
