import {
  Edit,
  FormTab,
  TabbedForm,
  TabbedFormTabs,
  ReferenceInput,
  SelectInput,
  TextInput,
  PasswordInput,
  BooleanInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import PermissionsInput from "../../../components/inputs/permissions-input";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, email, hasValue } from "../../../utils/schemas";

const Schema = Yup.object({
  enterprise_id: leastOneOption(),
  name: hasValue(),
  email: email()
});

const UserEnterpriseEdit = () => (
  <Edit
    title={ <Title subtitle="Editar Usuário Empresa" source="name" /> }
    redirect="list"
    mutationMode="pessimistic">
    <TabbedForm
      noValidate
      resolver={yupResolver(Schema)}
      tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
      toolbar={<ToolbarSave />}>
      <FormTab label="Dados">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <TextInput source="id" label="Id" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={10} />

          <Grid item xs={12} sm={6}>
            <ReferenceInput source="enterprise_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/admin/enterprises" >
              <SelectInput label="Empresa" optionText="name" optionValue="id" fullWidth isRequired />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={6}>
            <TextInput source="name" label="Nome" fullWidth isRequired />
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={3}>
            <TextInput source="email" label="E-mail" type="email" fullWidth isRequired />
          </Grid>
          <Grid item xs={12} sm={3}>
            <PasswordInput source="password" label="Senha" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={6}>
            <BooleanInput source="active" label="Ativo" />
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </FormTab>

      <FormTab label="Permissões">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <PermissionsInput isEdit={true} />
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default UserEnterpriseEdit;
