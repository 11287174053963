const rowVehiclePenaltyStyle = () => (record, index, defaultStyle = {}) =>
{
  let style = defaultStyle;
  if (record.status_penalty_type === 0)
    return {
      ...style,
      background: '#D2F8D2',
      borderLeftColor: '#D2F8D2',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  else if (record.status_penalty_type === 1)
    return {
      ...style,
      background: '#FFC0CB',
      borderLeftColor: '#FFC0CB',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  else if (record.status_penalty_type === 2)
    return {
      ...style,
      background: '#E8F4F8',
      borderLeftColor: '#E8F4F8',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  else if (record.status_penalty_type === 3)
    return {
      ...style,
      background: '#FFDB9A',
      borderLeftColor: '#FFDB9A',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  return style;
};

export default rowVehiclePenaltyStyle;
