import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import FilterDefault from "../../../filters/default";
import Actions from "../../../ra/list/actions";
import WrapperDirection from "../../../components/wrapper-direction";
import DetailsButton from "../../../components/buttons/details-button";
import EditButton from "../../../components/buttons/edit-button";

const EnterpriseList = () => (
  <List
    title="Empresas"
    sort={{ field: 'e.id', order: 'DESC' }}
    filters={ FilterDefault }
    actions={ <Actions /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="e.id" />
      <TextField source="name" label="Nome" sortBy="e.name" />
      <BooleanField source="active" label="Ativo" sortBy="e.active" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="e.created_at" />
      <WrapperDirection>
        <DetailsButton />
        <EditButton />
      </WrapperDirection>
    </Datagrid>
  </List>
);

export default EnterpriseList;
