import {
  FilterButton,
  CreateButton,
  TopToolbar,
} from "react-admin";
import config from "../../../config";
import DialogExport from "../../../components/dialogs/dialog-export";

const Actions = ({ hasFilter = true, hasCreate = true, hasExport = true }) => (
  <TopToolbar>
    {hasFilter && <FilterButton />}
    {hasCreate && <CreateButton />}
    {hasExport && <DialogExport maxResults={config.export.max_results} />}
  </TopToolbar>
);

export default Actions;
