import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  DateTimeInput,
  NumberInput,
  BooleanInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, hasValue, validDate, number } from "../../../utils/schemas";

const Schema = Yup.object({
  enterprise_type_withdrawal_id: leastOneOption(),
  customer_id: leastOneOption(),
  name: hasValue(),
  date_withdrawal: validDate(),
  value: number()
});

const EnterpriseWithdrawalEdit = () => (
  <Edit
    title={ <Title subtitle="Editar Retirada" source="value" /> }
    redirect="list"
    mutationMode="pessimistic">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={ <ToolbarSave /> }>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <TextInput source="id" label="Id" fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={10} />

        <Grid item xs={12} sm={6}>
          <ReferenceInput source="enterprise_type_withdrawal_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/types-withdrawal" >
            <SelectInput label="Tipo" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ReferenceInput source="customer_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/customers" >
            <SelectInput label="Colaborador" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Nome" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={3}>
          <DateTimeInput source="date_withdrawal" label="Data" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumberInput source="value" label="Valor" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <BooleanInput source="is_enterprise_cash" label="Retirado do Caixa da Empresa" />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default EnterpriseWithdrawalEdit;
