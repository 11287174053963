import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  useListController
} from 'react-admin';
import { Card } from '@mui/material';
import FilterEnterpriseWithdrawal from "../../../filters/enterprise/withdrawal";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import DetailEditWithPermission from "../../../components/detail-edit-with-permission";
import formatValue from "../../../utils/currency";
const EnterpriseWtihdrawalPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100, 250, 500, 1000 ]} {...props} />;

const CustomList = ({ children, actions, filters, title, ...props }) =>
{
  const { data, loading } = useListController();

  if (!loading && data)
  {
    var total = 0;
    data.forEach((item, i) => { total += parseFloat(item.value); });

    return (
      <ListBase {...props}>
        <Title title={title}/>
        <ListToolbar filters={filters} actions={actions}/>
        <Card>
        {children}
        </Card>
        <br/>
        <div>
          <b>
            Total: {formatValue(total)}
          </b>
        </div>
        <EnterpriseWtihdrawalPagination />
      </ListBase>
    );
  }
  else
    return null;
}

const EnterpriseWtihdrawalList = () => (
  <CustomList
    title="Retiradas"
    sort={{ field: 'w.id', order: 'DESC' }}
    filters={ FilterEnterpriseWithdrawal }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="w.id" />
      <TextField source="enterprise_type_withdrawal_name" label="Tipo" sortBy="etw.name" />
      <TextField source="customer_name" label="Colaborador" sortBy="cus.name" />
      <TextField source="name" label="Nome" sortBy="w.name" />
      <TextField source="value_format" label="Valor" sortBy="w.value" />
      <BooleanField source="is_enterprise_cash" label="Caixa da Empresa" sortBy="w.is_enterprise_cash" />
      <DateField source="date_withdrawal" label="Data" locales="pt-BR" showTime sortBy="w.date_withdrawal" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="w.created_at" />
      <DetailEditWithPermission />
    </Datagrid>
  </CustomList>
);

export default EnterpriseWtihdrawalList;
