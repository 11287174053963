import { usePermissions } from "react-admin";
import { Settings, Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import useToggle from "../../ra/hook/useToggle";
import WrapperDirection from "../wrapper-direction";
import EditInvoiceButton from '../buttons/edit-invoice-button';
import DialogInvoiceNote from '../dialogs/dialog-invoice-note';
import DialogInvoiceAddFile from '../dialogs/dialog-invoice-add-file';
import DialogInvoicePay from '../dialogs/dialog-invoice-pay';
import DialogCancelInvoice from '../dialogs/dialog-cancel-invoice';
import config from "../../config";

function DialogInvoiceActions()
{
  const { permissions } = usePermissions();
  const { on, toggle } = useToggle();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  return (
    <>
      <Button startIcon={<Settings />} color="success" onClick={handleClick}>
        Ações
      </Button>
      <Dialog
        open={on}
        onClose={handleCloseClick}
        aria-label="Ações">
        <DialogTitle>Selecione uma opção</DialogTitle>
        <DialogContent>
          <WrapperDirection direction="column">
            { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.invoices.edit)) && <EditInvoiceButton /> }
            <DialogInvoiceNote />
            <DialogInvoiceAddFile />
            <DialogInvoicePay />
            { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.invoices.cancel)) && <DialogCancelInvoice /> }
          </WrapperDirection>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<Cancel />} color="error" onClick={handleCloseClick}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogInvoiceActions;
