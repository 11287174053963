import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  useListController
} from 'react-admin';
import { Card } from '@mui/material';
import FilterEnterpriseInstallmentPenalty from "../../../filters/enterprise/installment-penalty";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsInstallmentPenalty from "../../../components/group-buttons/buttons-installment-penalty";
import formatValue from "../../../utils/currency";
const EnterpriseInstallmentPenaltyPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]} {...props} />;

const CustomList = ({ children, actions, filters, title, ...props }) => {
  const { data, loading } = useListController();

  if (!loading && data) {
    var total = 0;
    data.forEach((item, i) => { total += parseFloat(item.value); });

    return (
      <ListBase {...props}>
        <Title title={title} />
        <ListToolbar filters={filters} actions={actions} />
        <Card>
          {children}
        </Card>
        <br />
        <div>
          <b>
            Total: {formatValue(total)}
          </b>
        </div>
        <EnterpriseInstallmentPenaltyPagination />
      </ListBase>
    );
  }
  else
    return null;
}

const EnterpriseInstallmentPenaltyList = () => (
  <CustomList
    title="Multas"
    sort={{ field: 'ipe.date_payment', order: 'DESC' }}
    filters={FilterEnterpriseInstallmentPenalty}
    actions={<ActionsWithPermission hasCreate={false} />}>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="ipe.id" />
      <FunctionField render={record => `${record.installment_number} - ${record.installment_count_installments}`} label="Número da Parcela" sortBy="installment_number" />
      <TextField source="installment_client_name" label="Cliente" sortBy="cl.name" />
      <TextField source="installment_contract_name" label="Contrato" sortBy="c.name" />
      <TextField source="value_format" label="Valor Recebido" sortBy="ipe.value" />
      <TextField source="enterprise_payment_form_name" label="Forma" sortBy="epf.name" />
      <TextField source="customer_name" label="Cobrada Por" sortBy="cu.name" />
      <DateField source="date_payment" label="Data do Pagamento" locales="pt-BR" showTime sortBy="ipe.date_payment" />
      <ButtonsInstallmentPenalty />
    </Datagrid>
  </CustomList>
);

export default EnterpriseInstallmentPenaltyList;
