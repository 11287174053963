import {
  useRecordContext,
  useRedirect
} from 'react-admin';
import Button from "./button";
import IconEdit from '@mui/icons-material/Edit';
import config from "../../config";

function EditVehiclePenaltyButton()
{
  const record = useRecordContext();
  const redirect = useRedirect();

  const btnClick = (e) =>
  {
    e.stopPropagation();
    redirect(('/enterprise-vehicle-penalty/' + record.id));
  };

  if (record.status_penalty_type === config.status_penalty.in_day ||
      record.status_penalty_type === config.status_penalty.overdue ||
      record.status_penalty_type === config.status_penalty.pay)
  {
    return (
      <Button label="Editar" color="info" onClick={btnClick}>
        <IconEdit />
      </Button>
    );
  }
  else
    return null;
}

export default EditVehiclePenaltyButton;
