import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import FilterUserEnterprise from "../../../filters/admin/user-enterprise";
import Actions from "../../../ra/list/actions";
import WrapperDirection from "../../../components/wrapper-direction";
import DetailsButton from "../../../components/buttons/details-button";
import EditButton from "../../../components/buttons/edit-button";

const UserEnterpriseList = () => (
  <List
    title="Usuários Empresa"
    sort={{ field: 'ue.id', order: 'DESC' }}
    filters={ FilterUserEnterprise }
    actions={ <Actions /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="ue.id" />
      <TextField source="name" label="Nome" sortBy="ue.name" />
      <TextField source="email" label="E-mail" sortBy="ue.email" />
      <TextField source="enterprise_name" label="Empresa" sortBy="e.name" />
      <BooleanField source="active" label="Ativo" sortBy="ue.active" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="ue.created_at" />
      <WrapperDirection>
        <DetailsButton />
        <EditButton />
      </WrapperDirection>
    </Datagrid>
  </List>
);

export default UserEnterpriseList;
