import {
  Create,
  SimpleForm,
  DateInput,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  FileInput,
  FileField
} from 'react-admin';
import { Grid } from '@mui/material';
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, validDate, number, hasValue, file } from "../../../utils/schemas";

const Schema = Yup.object({
  enterprise_vehicle_id: leastOneOption(),
  customer_id: leastOneOption(),
  type_penalty: hasValue(),
  value: number(),
  date_penalty: validDate(),
  date_due: validDate(),
  enterprise_vehicle_penalty_files: Yup.array().of(
    Yup.object().shape(
    {
      file_name: hasValue(),
      file: file()
    })
  )
});

const EnterpriseVehiclePenaltyCreate = () => (
  <Create
    title="Nova Infração"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={3}>
          <ReferenceInput source="enterprise_vehicle_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/vehicles">
            <SelectInput label="Veículo" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ReferenceInput source="customer_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/customers">
            <SelectInput label="Condutor" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <TextInput source="type_penalty" label="Tipo" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <NumberInput source="value" label="Valor" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DateTimeInput source="date_penalty" label="Data da Infração" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DateInput source="date_due" label="Data Vencimento" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <TextInput source="points" label="Pontos" fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="gravity" label="Gravidade" fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="comments" label="Observação" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ArrayInput label="Arquivo(s)" source="enterprise_vehicle_penalty_files">
            <SimpleFormIterator disableReordering fullWidth>
              <TextInput source="file_name" label="Nome" fullWidth isRequired />
              <FileInput source="file" label="Arquivo" isRequired>
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EnterpriseVehiclePenaltyCreate;
