import {
  TextField,
  SimpleShowLayout
} from 'react-admin';

const InstallmentPanel = () => (
  <SimpleShowLayout>
    <TextField source="id" sortBy="i.id" />
    <TextField source="contract_name" label="Contrato" />
  </SimpleShowLayout>
);

export default InstallmentPanel;
