import { usePermissions } from "react-admin";
import { Settings, Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import useToggle from "../../ra/hook/useToggle";
import WrapperDirection from "../wrapper-direction";
import EditButton from '../buttons/edit-button';
import DialogVehicleAddFile from '../dialogs/dialog-vehicle-add-file';
import DialogDeleteVehicle from '../dialogs/dialog-delete-vehicle';
import config from "../../config";

function DialogVehicleActions()
{
  const { permissions } = usePermissions();
  const { on, toggle } = useToggle();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  return (
    <>
      <Button startIcon={<Settings />} color="success" onClick={handleClick}>
        Ações
      </Button>
      <Dialog
        open={on}
        onClose={handleCloseClick}
        aria-label="Ações">
        <DialogTitle>Selecione uma opção</DialogTitle>
        <DialogContent>
          <WrapperDirection direction="column">
            { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.vehicles.edit)) && <EditButton /> }
            <DialogVehicleAddFile />
            { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.vehicles.delete)) && <DialogDeleteVehicle /> }
          </WrapperDirection>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<Cancel />} color="error" onClick={handleCloseClick}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogVehicleActions;
