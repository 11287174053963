import {
  FilterButton,
  CreateButton,
  TopToolbar,
  usePermissions,
  useResourceContext
} from "react-admin";
import DialogExport from "../../../components/dialogs/dialog-export";
import EnterpriseResourceEnum from "../../../utils/enterprise-resource-enum";
import config from "../../../config";

const ActionsWithPermission = ({ hasFilter = true, hasCreate = true, hasExport = true }) =>
{
  const { permissions } = usePermissions();
  const resource = useResourceContext();

  return (
    <TopToolbar>
      { hasFilter && <FilterButton /> }
      { hasCreate && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions[EnterpriseResourceEnum.get(resource)].create)) && <CreateButton /> }
      { hasExport && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions[EnterpriseResourceEnum.get(resource)].export)) && <DialogExport maxResults={config.export.max_results} /> }
    </TopToolbar>
  )
};

export default ActionsWithPermission;
