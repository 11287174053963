import { SearchInput, ReferenceInput, SelectInput, DateInput } from "react-admin";
import { Chip } from "@mui/material";

const FilterEnterpriseReportDay = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Colaborador" source="customer_id" reference="setup/enterprise/customers">
    <SelectInput
      label="Colaborador"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <Chip source="unread" label="Não Lida" defaultValue={false} />,
  <Chip source="read" label="Lida" defaultValue={true} />,
  <DateInput source="date_init_created_at" label="Data Criação Inicial" />,
  <DateInput source="date_end_created_at" label="Data Criação Final" />
];

export default FilterEnterpriseReportDay;
