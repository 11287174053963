import { SearchInput } from "react-admin";
import { Chip } from "@mui/material";

const FilterDefault = [
  <SearchInput source="q" alwaysOn />,
  <Chip source="activated" label="Ativos" defaultValue={true} />,
  <Chip source="disabled" label="Desativados" defaultValue={true} />
];

export default FilterDefault;
