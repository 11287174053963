import { SearchInput, ReferenceInput, SelectInput } from "react-admin";
import { Chip } from "@mui/material";

const FilterUserEnterprise = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Empresa" source="enterprise_id" reference="setup/admin/enterprises" alwaysOn>
    <SelectInput label="Empresa" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <Chip source="activated" label="Ativos" defaultValue={true} />,
  <Chip source="disabled" label="Desativados" defaultValue={true} />,
];

export default FilterUserEnterprise;
