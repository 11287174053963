import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import FilterDefault from "../../../filters/default";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import DetailEditWithPermission from "../../../components/detail-edit-with-permission";

const EnterpriseRegionList = () => (
  <List
    title="Regiões"
    sort={{ field: 'er.id', order: 'DESC' }}
    filters={ FilterDefault }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="er.id" />
      <TextField source="name" label="Nome" sortBy="er.name" />
      <BooleanField source="active" label="Ativo" sortBy="er.active" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="er.created_at" />
      <DetailEditWithPermission />
    </Datagrid>
  </List>
);

export default EnterpriseRegionList;
