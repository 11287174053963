import {
  Create,
  SimpleForm,
  TextInput
} from 'react-admin';
import CustomColorInput from "../../../components/inputs/custom-color-input";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue } from "../../../utils/schemas";

const Schema = Yup.object({
  name: hasValue(),
  color_base: hasValue()
});

const EnterpriseClientClassificationCreate = () => (
  <Create
    title="Nova Classificação"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Nome" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <CustomColorInput source="color_base" label="Cor Base" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EnterpriseClientClassificationCreate;
