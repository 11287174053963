import {
  Edit,
  FormTab,
  TabbedForm,
  TabbedFormTabs,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  Labeled,
  useUpdate,
  useRedirect,
  usePermissions,
} from "react-admin";
import config from "../../../config";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Grid } from "@mui/material";
import Title from "../../../ra/edit/title";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import GooglePlacesInput from "../../../components/inputs/google-places-input";
import MaskTextInput from "../../../components/inputs/mask-text-input";
import {
  leastOneOption,
  hasValue,
  document,
  cellphone,
  phone,
  cnpj
} from "../../../utils/schemas";

const Schema = (permissions) =>
{
  var obj =
  {
    enterprise_region_id: leastOneOption(),
    name: hasValue(),
    document: document(),
    cellphone: cellphone(false),
    secondary_cellphone: cellphone(false),
    phone: phone(false),
    enterprise_register_number: cnpj(false)
  };

  if (permissions.permissions.some((e) => e.keys.some(k => k.key === config.permissions.client_niches.list)))
    obj.enterprise_client_niche_id = leastOneOption();

  if (permissions.permissions.some((e) => e.keys.some(k => k.key === config.permissions.client_classifications.list)))
    obj.enterprise_client_classification_id = leastOneOption();

  return Yup.object(obj);
};

const ClientProspectionEdit = () =>
{
  const [update] = useUpdate();
  const redirect = useRedirect();
  const { id } = useParams();
  const { permissions } = usePermissions();
  const [newAddress, setNewAddress] = useState(null);
  const [newEnterpriseAddress, setNewEnterpriseAddress] = useState(null);

  const clientProspectionUpdate = (data) =>
  {
    if (newAddress)
      data.new_address = newAddress;
    if (newEnterpriseAddress)
      data.new_enterprise_address = newEnterpriseAddress;

    update("client-prospection", { id: id, data: data }, { onSuccess: (client) => { redirect("/client-prospection"); }});
  };

  return (
    <Edit
      title={<Title subtitle="Editar Cliente Agendado" source="name" />}
      redirect="list"
      mutationMode="pessimistic">
      <TabbedForm
        noValidate
        onSubmit={clientProspectionUpdate}
        resolver={yupResolver(Schema(permissions))}
        tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
        toolbar={<ToolbarSave />}>
        <FormTab label="Dados">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={2}>
              <TextInput source="id" label="Id" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={10} />

            <Grid item xs={12} sm={6}>
              <ReferenceInput
                source="enterprise_region_id"
                sort={{ field: "name", order: "ASC" }}
                reference="setup/enterprise/regions">
                <SelectInput
                  label="Região"
                  optionText="name"
                  optionValue="id"
                  fullWidth
                  isRequired />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} />

            {
              permissions.permissions.some((e) => e.keys.some(k => k.key === config.permissions.client_niches.list)) &&
              (
                <>
                  <Grid item xs={12} sm={6}>
                    <ReferenceInput
                      source="enterprise_client_niche_id"
                      sort={{ field: "name", order: "ASC" }}
                      reference="setup/enterprise/client/niches">
                      <SelectInput
                        label="Nicho"
                        optionText="name"
                        optionValue="id"
                        fullWidth
                        isRequired />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={12} sm={6} />
                </>
              )
            }

            {
              permissions.permissions.some((e) => e.keys.some(k => k.key === config.permissions.client_classifications.list)) &&
              (
                <>
                  <Grid item xs={12} sm={6}>
                    <ReferenceInput
                      source="enterprise_client_classification_id"
                      sort={{ field: "name", order: "ASC" }}
                      reference="setup/enterprise/client/classifications">
                      <SelectInput
                        label="Classificação"
                        optionText="name"
                        optionValue="id"
                        fullWidth
                        isRequired />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={12} sm={6} />
                </>
              )
            }

            <Grid item xs={12} sm={6}>
              <TextInput source="name" label="Nome" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <MaskTextInput source="document" label="CPF" mask="###.###.###-##" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="email" label="E-mail" type="email" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="instagram" label="Instagram" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <MaskTextInput source="cellphone" label="Celular Principal" mask="(##) #####-####" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskTextInput source="secondary_cellphone" label="Celular Secundário" mask="(##) #####-####" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskTextInput source="phone" label="Telefone Fixo" mask="(##) ####-####" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
            <br />
            <br />
            <br />

            <Grid item xs={12} sm={2}>
              <MaskTextInput source="enterprise_register_number" label="CNPJ" mask="##.###.###/####-##" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="enterprise_name" label="Empresa" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="enterprise_reference" label="Referência" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <TextInput source="comments" label="Observações" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <BooleanInput source="active" label="Ativo" />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>

        <FormTab label="Endereço">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput source="address.city.name" label="Cidade" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="address.zip_code" label="CEP" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput source="address.street" label="Rua" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="address.number" label="Número" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput source="address.neighborhood" label="Bairro" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <Labeled label="Novo Endereço" fullWidth>
                <GooglePlacesInput source="new_address" setAddress={setNewAddress} />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <TextInput source="address.complement" label="Complemento" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>

        <FormTab label="Endereço da Empresa">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput source="enterprise_address.city.name" label="Cidade" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="enterprise_address.zip_code" label="CEP" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput source="enterprise_address.street" label="Rua" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="enterprise_address.number" label="Número" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput source="enterprise_address.neighborhood" label="Bairro" fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <Labeled label="Novo Endereço da Empresa" fullWidth>
                <GooglePlacesInput source="new_enterprise_address" setAddress={setNewEnterpriseAddress} />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <TextInput source="enterprise_address.complement" label="Complemento" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default ClientProspectionEdit;
