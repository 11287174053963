import { useNotify, useRecordContext } from "react-admin";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Button from "./button";
import config from "../../config";

export default function LinkInstallementPendingPenaltyButton()
{
  const record = useRecordContext();

  const notify = useNotify();
  if (record && record.date_payment) return;

  const btnClick = () =>
  {
    if (!record) return;
    const search = new URLSearchParams({ onlypenalty: "t" });

    navigator.clipboard.writeText(
      [
        config.link_pagamento.url,
        record.contract_hash,
        record.installment_id,
        `?${search}`,
      ].join("/"),
    );
    notify("Link de Pagamento copiado com sucesso!", { type: "success" });
  };

  return (
    <Button label="Multa" color="error" onClick={btnClick} sx={{ wordWrap: "no-break" }}>
      <InsertLinkIcon sx={{ margin: 0 }} />
    </Button>
  );
}
