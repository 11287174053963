import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  FunctionField,
  ArrayField,
  Datagrid,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";
import LinkField from '../../../components/link-field';
import DialogDeleteEnterpriseVehiclePenaltyFile from '../../../components/dialogs/dialog-delete-enterprise-vehicle-penalty-file';
import formatValue from "../../../utils/currency";

const EnterpriseVehiclePenaltyShow = () => (
  <Show
    title="Infração">
    <TabbedShowLayout>
      <Tab label="Dados">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="id" label="Id" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="status_penalty_name" label="Status" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="enterprise_vehicle_name" label="Veículo" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="customer_name" label="Condutor" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="date_penalty_format" label="Data Infração" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="date_due_format" label="Data Vencimento" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value" label="Valor" render={record => `${formatValue(record.value)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="points" label="Pontos" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="gravity" label="Gravidade" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="comments" label="Observação" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="user_enterprise_name" label="Criado Por" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </Tab>
      <Tab label="Pagamento">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="payment.user_enterprise_name" label="Paga Por" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="payment.enterprise_bank_account_name" label="Conta" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="payment.enterprise_payment_form_name" label="Forma" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="payment.value_format" label="Valor Pago" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="payment.date_payment_format" label="Data do Pagamento" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={8} />
        </Grid>
      </Tab>
      <Tab label="Arquivos">
        <ArrayField source="files" label="">
          <Datagrid
            rowClick={false}
            bulkActionButtons={false}>
            <TextField source="name" label="Nome" />
            <LinkField source="url" label="Arquivo" blank />
            <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            <DialogDeleteEnterpriseVehiclePenaltyFile />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default EnterpriseVehiclePenaltyShow;
