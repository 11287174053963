import { SearchInput, ReferenceInput, SelectInput, DateInput } from "react-admin";

const FilterCashRegister = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="date_init" label="Data Inicial" />,
  <DateInput source="date_end" label="Data Final" />,
  <ReferenceInput label="Colaborador" source="customer_id" reference="setup/enterprise/customers">
    <SelectInput
      label="Colaborador"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Status" source="status_cash_register" reference="setup/enterprise/status-cash-register">
    <SelectInput
      label="Status"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>
];

export default FilterCashRegister;
