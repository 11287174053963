import { usePermissions } from 'react-admin';
import WrapperDirection from "../wrapper-direction";
import DetailsButton from "../buttons/details-button";
import DialogContractActions from "../dialogs/dialog-contract-actions";
import config from "../../config";

const ButtonsContract = () =>
{
  const { permissions } = usePermissions();

  return (
    <WrapperDirection>
      { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.contracts.show)) && <DetailsButton /> }
      <DialogContractActions />
    </WrapperDirection>
  );
};

export default ButtonsContract;
