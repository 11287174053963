import { usePermissions } from 'react-admin';
import WrapperDirection from "../wrapper-direction";
import DetailsButton from "../buttons/details-button";
import DialogDeleteReportComission from "../dialogs/dialog-delete-report-commission";
import config from "../../config";

const ButtonsReportComission = () =>
{
  const { permissions } = usePermissions();

  return (
    <WrapperDirection>
    { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.reports_comission.show)) && <DetailsButton /> }
    { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.reports_comission.delete)) && <DialogDeleteReportComission /> }
    </WrapperDirection>
  );
};

export default ButtonsReportComission;
