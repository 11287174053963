import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Datagrid,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";
import LinkField from '../../../components/link-field';
import DialogDeleteEnterpriseVehicleFile from '../../../components/dialogs/dialog-delete-enterprise-vehicle-file';

const EnterpriseVehicleShow = () => (
  <Show
    title="Veículo">
    <TabbedShowLayout>
      <Tab label="Dados">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="id" label="Id" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="type" label="Tipo" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="plate" label="Placa" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="mileage" label="Quilometragem" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="color" label="Cor" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="brand" label="Fabricante" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="model" label="Modelo" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="year_manufacture" label="Ano Fabricação" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="year_model" label="Ano Modelo" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="fuel" label="Combustível" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="situation" label="Situação" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="chassis_number" label="Chassi" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="document_number" label="Renavam" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="user_enterprise.name" label="Criado Por" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={6}>
            <Labeled>
              <ArrayField label="Condutores" source="conductors" fieldKey="uuid">
                <SingleFieldList
                  linkType={false}>
                  <ChipField source="name" />
                </SingleFieldList>
              </ArrayField>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </Tab>
      <Tab label="Arquivos">
        <ArrayField source="files" label="">
          <Datagrid
            rowClick={false}
            bulkActionButtons={false}>
            <TextField source="name" label="Nome" />
            <LinkField source="url" label="Arquivo" blank />
            <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            <DialogDeleteEnterpriseVehicleFile />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default EnterpriseVehicleShow;
