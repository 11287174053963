import {
  useRecordContext,
  useResourceContext,
  useRedirect
} from "react-admin";
import IconDetails from "@mui/icons-material/Visibility";
import Button from "./button";
import wrapperButtonForRedirect from "../../ra/hoc/wrapperButtonForRedirect";

function DetailsButton()
{
  const record = useRecordContext();
  const resource = useResourceContext();
  const redirect = useRedirect();

  const btnClick = (e) =>
  {
    e.stopPropagation();

    redirect('show', resource, record.id);
  };

  return (
    <Button label="Detalhes" color="primary" onClick={btnClick}>
      <IconDetails />
    </Button>
  );
}

export default wrapperButtonForRedirect(DetailsButton, "show");
