const rowReportDayStyle = () => (record, index, defaultStyle = {}) =>
{
  let style = defaultStyle;
  if (!record.read)
    return {
      ...style,
      background: '#D2F8D2',
      borderLeftStyle: 'solid'
    };
  else
    return style;
};

export default rowReportDayStyle;
