const calculateGain = (monthly_installment_gain, monthly_installment_penalty) =>
{
  return (parseFloat(monthly_installment_gain) + parseFloat(monthly_installment_penalty));
};

const calculateBalance = (monthly_gain, monthly_withdrawal) =>
{
  return (parseFloat(monthly_gain) - parseFloat(monthly_withdrawal));
};

const calculateHeigthBarChart = (values) =>
{
  return (values.length <= 5 ? 200 : values.length <= 10 ? 300 : values.length <= 15 ? 400 : values.length <= 20 ? 500 : values.length <= 25 ? 600 : values.length <= 30 ? 700 : values.length <= 35 ? 800 : values.length <= 40 ? 900 : 1000);
};

const getDistincIdsByArray = (data) =>
{
  const ids = data.map(a => a.id);
  return ids.filter(function(item, index) { return ids.indexOf(item) === index })
};

const getChartColors = () =>
{
  return ["#E6194B", "#3CB44B", "#FFE119", "#4363D8", "#F58231", "#911EB4", "#46F0F0", "#F032E6", "#BCF60C", "#FABEBE"];
};

const parseDataForChartMonths = (data, valueFormatter) =>
{
  var chartLabels = []
  var chartSeries = [];
  var barBaseConfig =
  {
    highlightScope: { faded: 'global', highlighted: 'item' },
    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
  };

  data.forEach((item, i) =>
  {
    if (!chartSeries.find((obj) => obj.label === item.name))
    {
      chartSeries.push(
      {
        ...barBaseConfig,
        data: [item.value],
        label: item.name,
        valueFormatter
      });
    }
    else
      chartSeries.find((obj) => obj.label === item.name).data.push(item.value);

    if (!chartLabels.includes(item.month_year))
      chartLabels.push(item.month_year);
  });

  return { chartLabels, chartSeries };
};

const getCustomersId = (permissions, key) =>
{
  return permissions.permissions.find(e => e.key === key).filter_customers.map(k => k.customer_id);
};

const translateHeader = (header) =>
{
  switch (header)
  {
    case "id":
      return "Id";
    case "status_contract_name":
      return "Status";
    case "client_name":
      return "Cliente";
    case "name":
      return "Nome";
    case "email":
      return "E-mail";
    case "enterprise_name":
      return "Empresa";
    case "enterprise_billing_period_name":
      return "Periodo de Pagamento";
    case "percentage":
      return "Porcentagem";
    case "date_init_format":
      return "Data de Inicio";
    case "value_initial":
      return "Valor Inicial";
    case "value_expected":
      return "Valor Esperado";
    case "value_total":
      return "Valor Total";
    case "value_cost":
      return "Valor Custo";
    case "value_fees":
      return "Valor Juros";
    case "value_paid":
      return "Valor Pago";
    case "value_to_receive":
      return "Valor a Receber";
    case "value_extended":
      return "Valor Estendido";
    case "value_canceled":
      return "Valor Cancelado";
    case "value_penalties":
      return "Valor Multas";
    case "value_gain":
      return "Valor Lucro";
    case "user_enterprise_name":
      return "Criado Por";
    case "created_at_format":
      return "Data Criacao";
    case "permissions_name":
      return "Permissoes";
    case "deposited_enterprise_bank_account":
      return "Depositado na Conta Bancaria";
    case "allow_enterprise_installment":
      return "Permitido Empresa Parcelas";
    case "allow_customer_installment":
      return "Permitido Colaborador Parcelas";
    case "allow_enterprise_penalty":
      return "Permitido Empresa Multas";
    case "allow_customer_penalty":
      return "Permitido Colaborador Multas";
    case "number_installments":
      return "Numero de Parcelas";
    case "interval_days":
      return "Intervalo de Dias";
    case "customer_name":
      return "Colaborador";
    case "title":
      return "Titulo";
    case "report_text":
      return "Texto";
    case "count_contracts":
      return "Numero Contratos";
    case "type_enterprise_report_commission_name":
      return "Tipo";
    case "date_end_format":
      return "Data Final";
    case "value_commission":
      return "Valor Comissao";
    case "enterprise_report_commission_contract_clients_name":
      return "Clientes";
    case "count_clients":
      return "Numero Clientes";
    case "value_total_contracts":
      return "Valor Contratos";
    case "color_base":
      return "Cor Base";
    case "enterprise_regions_name":
      return "Regioes";
    case "enterprise_region_name":
      return "Regiao";
    case "document":
      return "CPF";
    case "instagram":
      return "Instagram";
    case "phone":
      return "Telefone";
    case "cellphone":
      return "Celular";
    case "secondary_cellphone":
      return "Celular Secundario";
    case "client_phone":
      return "Telefone Cliente";
    case "client_cellphone":
      return "Celular Cliente";
    case "client_secondary_cellphone":
      return "Celular Secundario Cliente";
    case "enterprise_register_number":
      return "CNPJ";
    case "enterprise_reference":
      return "Referencia";
    case "comments":
      return "Observacao";
    case "enterprise_classification_name":
      return "Classificacao";
    case "enterprise_niche_name":
      return "Nicho";
    case "address_city":
      return "Endereço Cidade";
    case "address_zip_code":
      return "Endereço CEP";
    case "address_street":
      return "Endereço Rua";
    case "address_number":
      return "Endereço Numero";
    case "address_neighborhood":
      return "Endereço Bairro";
    case "address_complement":
      return "Endereço Complemento";
    case "address_enterprise_city":
      return "Endereço Empresa Cidade";
    case "address_enterprise_zip_code":
      return "Endereço Empresa CEP";
    case "address_enterprise_street":
      return "Endereço Empresa Rua";
    case "address_enterprise_number":
      return "Endereço Empresa Numero";
    case "address_enterprise_neighborhood":
      return "Endereço Empresa Bairro";
    case "address_enterprise_complement":
      return "Endereço Empresa Complemento";
    case "value":
      return "Valor";
    case "enterprise_payment_form_name":
      return "Forma de Pagamento";
    case "date_payment_format":
      return "Data de Pagamento";
    case "installment_number":
      return "Parcela";
    case "installment_count_installments":
      return "Numero Parcelas";
    case "installment_contract_name":
      return "Contrato";
    case "installment_client_name":
      return "Cliente";
    case "date_withdrawal_format":
      return "Data Retirada";
    case "number":
      return "Numero";
    case "count_installments":
      return "Numero Parcelas";
    case "contract_name":
      return "Contrato";
    case "status_installment_name":
      return "Status";
    case "original_date_billing_format":
      return "Data Cobranca Original";
    case "date_billing_format":
      return "Data Cobranca";
    case "value_original":
      return "Valor Original";
    case "notes_name":
      return "Observacoes";
    case "payment_customer_name":
      return "Cobrada Por";
    case "payment_form_name":
      return "Forma de Pagamento";
    case "value_payment":
      return "Valor Pago";
    case "value_gain_payment":
      return "Valor Lucro";
    case "payment_date_format":
      return "Data Pagamento";
    case "enterprise_type_withdrawal_name":
      return "Tipo";
    case "status_cash_register_name":
      return "Status";
    case "enterprise_payment_forms_name":
      return "Formas de Pagamento";
    case "date_format":
      return "Data";
    case "value_installments":
      return "Valor Parcelas";
    case "value_installment_penalties":
      return "Valor Multas";
    case "value_withdrawals":
      return "Valor Retiradas";
    case "value_balance":
      return "Saldo do Dia";
    case "value_deposited":
      return "Valor Depositado";
    case "value_possession":
      return "Valor em Posse";
    case "enterprise_type_invoice_name":
      return "Tipo";
    case "status_invoice_name":
      return "Status";
    case "date_issue_format":
      return "Data Emissao";
    case "date_due_format":
      return "Data Vencimento";
    case "payment_user_enterprise_name":
      return "Paga Por";
    case "enterprise_type_payment_invoice_name":
      return "Forma de Pagamento";
    case "bar_code":
      return "Codigo de Barras";
    case "description":
      return "Descricao";
    case "recipient":
      return "Beneficiario";
    case "enterprise_bank_account_name":
      return "Conta Bancária";
    case "value_fees_payment":
      return "Valor Juros";
    case "value_discount_payment":
      return "Valor Desconto";
    case "type_name":
      return "Tipo";
    case "type":
      return "Tipo";
    case "plate":
      return "Placa";
    case "mileage":
      return "Quilometragem";
    case "color":
      return "Cor";
    case "brand":
      return "Fabricante";
    case "model":
      return "Modelo";
    case "year_manufacture":
      return "Ano Fabricacao";
    case "year_model":
      return "Ano Modelo";
    case "fuel":
      return "Combustivel";
    case "situation":
      return "Situacao";
    case "chassis_number":
      return "Chassi";
    case "document_number":
      return "Renavam";
    case "conductors_name":
      return "Condutores";
    case "enterprise_vehicle_name":
      return "Veiculo";
    case "date_maintenance_format":
      return "Data Manutencao";
    case "status_penalty_name":
      return "Status";
    case "type_penalty":
      return "Tipo";
    case "date_penalty_format":
      return "Data Infracao";
    case "points":
      return "Pontos";
    case "gravity":
      return "Gravidade";
    case "employee_situation_name":
      return "Situacao";
    case "birthdate_format":
      return "Data Nascimento";
    case "voter_document":
      return "Titulo de Eleitor";
    case "identifier_document":
      return "Identidade";
    case "work_document":
      return "Carteira de Trabalho";
    case "social_document":
      return "PIS PASEP";
    case "admission_date_format":
      return "Data de Admissão";
    case "contract_type":
      return "Tipo Contrato";
    case "office":
      return "Cargo Funcao";
    case "workplace":
      return "Local de Trabalho";
    case "key_transfer":
      return "Chave PIX";
    case "working_day":
      return "Jornada de Trabalho";
    case "enterprise_type_employee_cost_name":
      return "Tipo de Custo";
    case "enterprise_employee_name":
      return "Funcionario";
    case "value_salary":
      return "Salario";
    case "nationality":
      return "Nacionalidade";
    case "type_log_name":
      return "Tipo";
    case "type_user_log_name":
      return "Tipo Usuario";
    case "category_log_name":
      return "Categoria";
    case "message":
      return "Mensagem";
    case "active":
      return "Ativo";
    case "old_value":
      return "Valor Antigo";
    case "new_value":
      return "Novo Valor";
    case "files":
      return "Arquivos";
    case "url":
      return "Link";
    case "address":
      return "Endereço";
    case "lat":
      return "Latitude";
    case "lng":
      return "Longitude";
    case "city":
      return "Cidade";
    case "state":
      return "Estado";
    case "initials":
      return "Sigla";
    case "street":
      return "Rua";
    case "zip_code":
      return "CEP";
    case "complement":
      return "Complemento";
    case "neighborhood":
      return "Bairro";
    case "enterprise_address":
      return "Endereço Empresa";
    case "prospection":
      return "Agendado";
    case "enterprise_id":
      return "Id Empresa";
    case "client_id":
      return "Id Cliente";
    case "date":
      return "Data";
    case "enterprise_billing_period_interval_days":
      return "Intervalo de Dias";
    case "installments":
      return "Parcelas";
    case "value_initial_format":
      return "Valor Inicial";
    case "value_expected_format":
      return "Valor Esperado";
    case "value_total_format":
      return "Valor Total";
    case "value_cost_format":
      return "Valor Custo";
    case "value_fees_format":
      return "Valor Juros";
    case "value_paid_format":
      return "Valor Pago";
    case "value_to_receive_format":
      return "Valor a Receber";
    case "value_extended_format":
      return "Valor Estendido";
    case "value_canceled_format":
      return "Valor Cancelado";
    case "value_penalties_format":
      return "Valor Multas";
    case "value_gain_format":
      return "Valor Lucro";
    case "value_original_format":
      return "Valor Original";
    case "has_installment_overdue":
      return "Parcelas Atrasadas";
    case "has_installment_pending":
      return "Parcelas Pendentes";
    case "contract_id":
      return "Id Contrato";
    case "payment":
      return "Pagamento";
    case "value_format":
      return "Valor";
    case "installment_id":
      return "Id Parcela";
    case "note":
      return "Observacao";
    case "is_enterprise_cash":
      return "Retirado do Caixa da Empresa";
    case "status_cash_register":
      return "Status Fechamento de Caixa";
    case "value_installments_format":
      return "Valor Parcelas";
    case "value_installment_penalties_format":
      return "Valor Multas";
    case "value_withdrawals_format":
      return "Valor Retiradas";
    case "value_balance_format":
      return "Saldo do Dia";
    case "value_deposited_format":
      return "Valor Depositado";
    case "value_possession_format":
      return "Valor em Posse";
    case "enterprise_payment_forms":
      return "Formas de Pagamento";
    case "number_repetitions":
      return "Numero de Repeticoes";
    case "notes":
      return "Observacoes";
    case "enterprise_invoice_id":
      return "Id Conta";
    case "value_discount_format":
      return "Valor Desconto";
    case "is_withdrawal":
      return "Gerou Retirada"
    case "customer_id":
      return "Id Colaborador";
    case "read":
      return "Lido";
    case "value_commission_format":
      return "Valor Comissao";
    case "enterprise_report_commission_contracts":
      return "Contratos";
    case "penalties":
      return "Multas";
    case "extended":
      return "Extendida";
    case "value_gain_without_penalties_format":
      return "Valor Lucro sem Multas";
    case "conductors":
      return "Condutores";
    case "value_salary_format":
      return "Valor Salario";
    case "birthdate":
      return "Date de Nascimento";
    case "admission_date":
      return "Data Admissao";
    default:
      return header;
  }
};

export {
  calculateGain,
  calculateBalance,
  calculateHeigthBarChart,
  translateHeader,
  getDistincIdsByArray,
  getChartColors,
  getCustomersId,
  parseDataForChartMonths
};
