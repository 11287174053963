import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  FunctionField,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";
import formatValue from "../../../utils/currency";

const EnterpriseEmployeCostShow = () => (
  <Show
    title="Infração">
    <SimpleShowLayout>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="id" label="Id" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="enterprise_type_employee_cost_name" label="Tipo de Custo" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="enterprise_employee_name" label="Funcionário" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <Labeled>
            <FunctionField source="value" label="Valor" render={record => `${formatValue(record.value)}`} />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="user_enterprise_name" label="Criado Por" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export default EnterpriseEmployeCostShow;
