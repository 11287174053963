import { Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { useGetList } from "react-admin";
import config from "../../config";

function SelectCustomersInput({ currentCustomers, setSelectedCustomers })
{
  const [selectedCurrentCustomers, setSelectedCurrentCustomers] = useState(currentCustomers);
  const { data } = useGetList("setup/enterprise/customers", {});

  const handleClick = (e) =>
  {
    e.stopPropagation();
  };

  const handleChange = (e) =>
  {
    e.stopPropagation();

    setSelectedCurrentCustomers(e.target.value);
    setSelectedCustomers(e.target.value);
  };

  if (!data)
    return null;

  return (
    <Select
      multiple
      size="small"
      variant="outlined"
      value={selectedCurrentCustomers}
      sx={{ backgroundColor: "white", width: "100%" }}
      onClick={handleClick}
      onChange={handleChange}>
      {
        data.map((item, index) => (
          <MenuItem
            key={index}
            disabled={selectedCurrentCustomers.length >= config.chart.max_columns && !selectedCurrentCustomers.includes(item.id)}
            value={item.id}>
            {item.name}
          </MenuItem>
        ))
      }
    </Select>
  );
}

export default SelectCustomersInput;
