import {
  useRecordContext,
  useRedirect
} from 'react-admin';
import Button from "./button";
import IconEdit from '@mui/icons-material/Edit';
import config from "../../config";

function EditInstallmentButton({ isEnterprise = true, isMainInstallment = true })
{
  const record = useRecordContext();
  const redirect = useRedirect();

  const btnClick = (e) =>
  {
    e.stopPropagation();

    if (isMainInstallment)
      redirect(('/enterprise-installment/' + record.id));
    else
      redirect(('/enterprise-contract-installment/' + record.id));
  };

  if (isEnterprise &&
      (record.status_installment_type === config.status_installment.in_day ||
       record.status_installment_type === config.status_installment.overdue ||
       record.status_installment_type === config.status_installment.pay ||
       record.status_installment_type === config.status_installment.pay_partial ||
       record.status_installment_type === config.status_installment.extended))
  {
    return (
      <Button label="Editar Parcela" color="info" onClick={btnClick}>
        <IconEdit />
      </Button>
    );
  }
  else
    return null;
}

export default EditInstallmentButton;
