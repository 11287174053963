import {
  Form,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { Delete, Cancel, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import Button from "../buttons/button";

function DialogDeleteEnterpriseVehicleMaintenanceFile()
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { on, toggle } = useToggle();
  const { loading, action } = useForRequest();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleSubmit = async () =>
  {
    const { error } = await action(`enterprise-vehicle-maintenance-file/${record.id}`, 'DELETE', {});
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    refresh();
    toggle();
    notify('Arquivo excluído com sucesso!', { type: "success" });
  };

  return (
    <>
      <Button onClick={handleClick} color="warning" label="DELETAR">
        <Delete />
      </Button>

      <Dialog
        fullWidth
        open={on}
        onClose={handleCloseClick}
        aria-label="Excluir Arquivo">
        <Form onSubmit={handleSubmit}>
          <DialogTitle>
            Tem certeza que deseja excluir esse arquivo?
          </DialogTitle>
          <DialogActions>
            <Button
              label="Cancelar"
              disabled={loading}
              color="error"
              onClick={handleCloseClick}>
              <Cancel />
            </Button>
            <Button
              label="Confirmar"
              color="success"
              type="submit"
              disabled={loading}>
              <Save />
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

export default DialogDeleteEnterpriseVehicleMaintenanceFile;
