import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  Datagrid,
  TextField,
  FunctionField,
  useListController
} from 'react-admin';
import { Card } from '@mui/material';
import FilterInstallment from "../../../filters/enterprise/installment";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsInstallment from "../../../components/group-buttons/buttons-installment";
import InstallmentPanel from '../../../panels/enterprise/installment-panel';
import rowInstallmentStyle from '../../../ra/datagrid/row/installment';
import formatValue from "../../../utils/currency";
const InstallmentPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100, 250, 500, 1000 ]} {...props} />;

const CustomList = ({ children, actions, filters, title, ...props }) =>
{
  const { data, loading } = useListController();

  if (!loading && data)
  {
    var total = 0;
    data.forEach((item, i) => { total += parseFloat(item.value_total); });

    return (
      <ListBase {...props}>
        <Title title={title}/>
        <ListToolbar filters={filters} actions={actions}/>
        <Card>
        {children}
        </Card>
        <br/>
        <div>
          <b>
            Total: { formatValue(total) }
          </b>
        </div>
        <InstallmentPagination />
      </ListBase>
    );
  }
  else
    return null;
}

const EnterpriseInstallmentList = () => (
  <CustomList
    title="Parcelas"
    sort={{ field: 'i.date_billing', order: 'DESC' }}
    filters={ FilterInstallment }
    perPage={25}
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      expandSingle
      expand={<InstallmentPanel />}
      rowStyle={rowInstallmentStyle()}>
      <TextField source="status_installment_name" label="Status" sortBy="si.name" />
      <FunctionField render={record => `${record.number} - ${record.count_installments}`} label="Número da Parcela" sortBy="i.number" />
      <TextField source="client.name" label="Cliente" sortBy="cl.name" />
      <TextField source="contract_name" label="Contrato" sortBy="c.name" />
      <FunctionField source="value_original" label="Valor Original" render={record => `${formatValue(record.value_total)}`} sortBy="i.value_original" />
      <FunctionField source="value_total" label="Valor Total" render={record => `${formatValue(record.value_total)}`} sortBy="i.value_total" />
      <TextField source="date_billing_format" label="Data de Cobrança" sortBy="i.date_billing" />
      <TextField source="payment.customer_name" label="Cobrada Por" sortBy="cu.name" />
      <TextField source="payment.enterprise_payment_form_name" label="Forma" sortBy="epf.name" />
      <TextField source="payment.value_format" label="Valor Recebido" sortBy="ip.value" />
      <FunctionField source="payment.value_gain" label="Valor Lucro" render={record => `${record.payment ? formatValue(record.payment.value_gain) : ''}`} sortable={false} />
      <TextField source="payment.date_payment_format" label="Data do Pagamento" sortBy="ip.date_payment" />
      <ButtonsInstallment />
    </Datagrid>
  </CustomList>
);

export default EnterpriseInstallmentList;
