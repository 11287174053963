import { Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { useGetList } from "react-admin";
import config from "../../config";

function SelectRegionsInput({ currentRegions, setSelectedRegions })
{
  const [selectedCurrentRegions, setSelectedCurrentRegions] = useState(currentRegions);
  const { data } = useGetList("setup/enterprise/regions", {});

  const handleClick = (e) =>
  {
    e.stopPropagation();
  };

  const handleChange = (e) =>
  {
    e.stopPropagation();

    setSelectedCurrentRegions(e.target.value);
    setSelectedRegions(e.target.value);
  };

  if (!data)
    return null;

  return (
    <Select
      multiple
      size="small"
      variant="outlined"
      value={selectedCurrentRegions}
      sx={{ backgroundColor: "white", width: "100%" }}
      onClick={handleClick}
      onChange={handleChange}>
      {
        data.map((item, index) => (
          <MenuItem
            key={index}
            disabled={selectedCurrentRegions.length >= config.chart.max_columns && !selectedCurrentRegions.includes(item.id)}
            value={item.id}>
            {item.name}
          </MenuItem>
        ))
      }
    </Select>
  );
}

export default SelectRegionsInput;
