import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { email, hasValue, array } from "../../../utils/schemas";

const Schema = Yup.object({
  name: hasValue(),
  email: email(),
  password: hasValue(),
  enterprise_regions: array()
});

const CustomerCreate = () => (
  <Create
    title="Novo Colaborador"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Nome" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={3}>
          <TextInput source="email" label="E-mail" type="email" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={3}>
          <PasswordInput source="password" label="Senha" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ReferenceArrayInput source="enterprise_regions" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/regions" >
            <SelectArrayInput label="Região(ões)" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceArrayInput>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default CustomerCreate;
