import { useFormContext, useWatch } from "react-hook-form";
import {
  TextInput,
  NumberInput,
  FileInput,
  ArrayInput,
  SimpleFormIterator,
  FileField,
  useGetOne
} from "react-admin";
import { Grid } from "@mui/material";
import ValuePossession from "./value-possession";
import { useEffect } from "react";
import formatValue from "../utils/currency";

const CashRegister = ({ ...props }) =>
{
  const form = useFormContext();
  const date = useWatch({ name: "date" });
  const enterprise_payments_form = useWatch({ name: "enterprise_payments_form" });

  useEffect(() =>
  {
    const values = form.getValues();
    form.reset({
      ...values,
      value_total_installments: null,
      value_total_installment_penalties: null,
      value_total_withdrawals: null,
      balance: null,
      value_deposited: 0,
      comments: null,
      cash_register_files: []
    });
  }, [JSON.stringify(enterprise_payments_form)]);

  const { data } = useGetOne("customer-cash-register/daily-values", { id: date, meta: enterprise_payments_form });

  if (!data)
    return;
  else
  {
    return (
      <>
        <Grid item xs={12} sm={2}>
          <TextInput source="value_installments" label="Valor Recebido com Parcelas" defaultValue={formatValue(data.value_total_installments)} fullWidth readOnly={true} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="value_installment_penalties" label="Valor Recebido com Multas" defaultValue={formatValue(data.value_total_installment_penalties)} fullWidth readOnly={true} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="value_withdrawals" label="Valor de Retiradas" defaultValue={formatValue(data.value_total_withdrawals)} fullWidth readOnly={true} />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <TextInput source="balance" label="Saldo do Dia" defaultValue={formatValue(data.balance)} fullWidth readOnly={true} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <ValuePossession source="value_possession" label="Valor em Posse" value={data.value_possession} balance={data.balance} fullWidth readOnly={true} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <NumberInput source="value_deposited" label="Valor Depositado" step={1} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <TextInput source="comments" label="Observações" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ArrayInput label="Arquivo(s)" source="cash_register_files">
            <SimpleFormIterator disableReordering fullWidth>
              <TextInput source="file_name" label="Nome" fullWidth isRequired />
              <FileInput source="file" label="Arquivo" isRequired>
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12} sm={6} />
      </>
    );
  }
};

export default CashRegister;
