import {
  useRecordContext,
  useNotify,
  useRefresh
} from "react-admin";
import IconUnread from "@mui/icons-material/VisibilityOff";
import Button from "./button";
import useForRequest from "../../ra/hook/useForRequest";

function ButtonReportDayUnread()
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { loading, action } = useForRequest();

  const btnClick = async (e) =>
  {
    e.stopPropagation();

    const { error } = await action(`enterprise-report-day/${record.id}/unread`, 'POST', {});
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    refresh();
    notify('Relatório marcado como não lido com sucesso!', { type: "success" });
  };

  if (record.read)
    return (
      <Button label="Não lida" color="warning" disabled={loading} onClick={btnClick}>
        <IconUnread />
      </Button>
    );
  else
    return null;
}

export default ButtonReportDayUnread;
