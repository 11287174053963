import { SearchInput, ReferenceInput, SelectInput, AutocompleteInput } from "react-admin";

const FilterEnterpriseReportCommission = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Tipo de Comissão" source="type_enterprise_report_commission" reference="setup/enterprise/types-report-commission">
    <SelectInput
      label="Tipo de Comissão"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Colaborador" source="customer_id" reference="setup/enterprise/customers">
    <AutocompleteInput
      label="Colaborador"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      limitChoicesToValue={true}
      shouldRenderSuggestions={(val) => { return val ? val.trim().length > 1 : false }}
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>
];

export default FilterEnterpriseReportCommission;
