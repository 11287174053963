import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import FilterEnterpriseReportDay from "../../../filters/enterprise/report-day";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import WrapperDirection from "../../../components/wrapper-direction";
import DetailsButton from "../../../components/buttons/details-button";
import ButtonReportDayUnread from "../../../components/buttons/button-report-day-unread";
import rowReportDayStyle from '../../../ra/datagrid/row/report-day';

const ReportDayList = () => (
  <List
    title="Relatórios Diário"
    sort={{ field: 'crd.id', order: 'DESC' }}
    filters={ FilterEnterpriseReportDay }
    actions={ <ActionsWithPermission hasCreate={false} /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      rowStyle={rowReportDayStyle()}>
      <TextField source="id" sortBy="crd.id" />
      <TextField source="customer_name" label="Colaborador" sortBy="cus.name" />
      <TextField source="title" label="Título" sortBy="crd.title" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="crd.created_at" />
      <WrapperDirection>
        <DetailsButton />
        <ButtonReportDayUnread />
      </WrapperDirection>
    </Datagrid>
  </List>
);

export default ReportDayList;
