import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";

const CustomerShow = () => (
  <Show
    title="Colaborador">
    <SimpleShowLayout>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="id" label="Id" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="name" label="Nome" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Labeled>
            <TextField source="email" label="E-mail" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <Labeled>
            <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={10} />

        <Grid item xs={12} sm={6}>
          <Labeled>
            <ArrayField label="Regiões" source="enterprise_regions" fieldKey="uuid">
              <SingleFieldList
                linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export default CustomerShow;
