import { Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { useGetList } from "react-admin";
import config from "../../config";

function SelectTypesWithdrawalInput({ currentTypesWithdrawal, setSelectedTypesWithdrawal })
{
  const [selectedCurrentTypesWithdrawal, setSelectedCurrentTypesWithdrawal] = useState(currentTypesWithdrawal);
  const { data } = useGetList("setup/enterprise/types-withdrawal", {});

  const handleClick = (e) =>
  {
    e.stopPropagation();
  };

  const handleChange = (e) =>
  {
    e.stopPropagation();

    setSelectedCurrentTypesWithdrawal(e.target.value);
    setSelectedTypesWithdrawal(e.target.value);
  };

  if (!data)
    return null;

  return (
    <Select
      multiple
      size="small"
      variant="outlined"
      value={selectedCurrentTypesWithdrawal}
      sx={{ backgroundColor: "white", width: "100%" }}
      onClick={handleClick}
      onChange={handleChange}>
      {
        data.map((item, index) => (
          <MenuItem
            key={index}
            disabled={selectedCurrentTypesWithdrawal.length >= config.chart.max_columns && !selectedCurrentTypesWithdrawal.includes(item.id)}
            value={item.id}>
            {item.name}
          </MenuItem>
        ))
      }
    </Select>
  );
}

export default SelectTypesWithdrawalInput;
