import { useGetOne, useRefresh } from "react-admin";
import { useState } from "react";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Box } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SelectCountMonthsInpunt from "../inputs/select-count-months-input";
import SelectNichesInput from "../inputs/select-niches-input";
import ButtonRefreshChart from "../buttons/button-refresh-chart";
import { setFilterChartClientsPerNiche, getFilterChartClientsPerNiche } from "../../utils/api-params";
import { parseDataForChartMonths, getDistincIdsByArray, getChartColors } from "../../utils/utils";
import config from "../../config";

const barBaseSlotProps =
{
  legend:
  {
    direction: "row",
    position:
    {
      vertical: "bottom",
      horizontal: "middle"
    },
    padding: 0
  }
};

const ChartMonthsClientsPerNiche = () =>
{
  var filters = getFilterChartClientsPerNiche();
  if (!filters)
  {
    setFilterChartClientsPerNiche(config.chart.max_months, []);
    filters = getFilterChartClientsPerNiche();
  }

  const valueFormatter = (value) => (!value ? 0 : value);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [countMonthsSelected, setCountMonthsSelected] = useState();
  const [nichesSelected, setNichesSelected] = useState();
  const refresh = useRefresh();
  const { data, isLoading } = useGetOne("chart/months-clients-per-niche", { id: filters.count_months, meta: filters.niches });

  const setSelectedCountMonths = (countMonths) =>
  {
    setCountMonthsSelected(countMonths);
    setButtonDisabled(false);
  };

  const setSelectedNiches = (niches) =>
  {
    setNichesSelected(niches);
    setButtonDisabled(false);
  }

  const onClickButtonRefresh = (e) =>
  {
    e.stopPropagation();

    setFilterChartClientsPerNiche(
      (countMonthsSelected ? countMonthsSelected : filters.count_months),
      (nichesSelected ? nichesSelected : filters.niches));
    setButtonDisabled(true);
    refresh();
  }

  if (!data || isLoading)
  {
    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" component="h1">
            Carregando...
          </Typography>
        </AccordionSummary>
      </Accordion>
    );
  }
  else
  {
    const { chartLabels, chartSeries } = parseDataForChartMonths(data.result, valueFormatter);
    const nichesId = getDistincIdsByArray(data.result);

    return (
      <Accordion
        defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            flexWrap="wrap"
            alignContent="center"
            justifyContent="flex-start"
            maxWidth="100wh"
            gap={1}>
            <Typography variant="h6" component="h1">
              Clientes por nicho
            </Typography>
            <Box
              sx={{
                width: { xs: "calc(100vw - 140px)", md: "calc(100vw - 360px)" },
                maxWidth: { xs: "100%", lg: "200px" }
              }}>
              <SelectCountMonthsInpunt
                currentCountMonths={filters.count_months}
                setCurrentCountMonths={setSelectedCountMonths}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "calc(100vw - 140px)", md: "calc(100vw - 360px)" },
                maxWidth: { xs: "100%", lg: "360px" }
              }}>
              <SelectNichesInput
                currentNiches={nichesId}
                setSelectedNiches={setSelectedNiches}
              />
            </Box>
            <ButtonRefreshChart
              disabled={buttonDisabled}
              onClick={onClickButtonRefresh} />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={12}>
              <BarChart
                xAxis={[{ scaleType: "band", data: chartLabels }]}
                margin={{ top: 10, bottom: 90, left: 70, right: 70 }}
                slotProps={barBaseSlotProps}
                series={chartSeries}
                colors={getChartColors}
                height={450}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
};

export default ChartMonthsClientsPerNiche;
