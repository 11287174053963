import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField
} from 'react-admin';
import FilterEnterpriseClient from "../../../filters/enterprise/client";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsClient from "../../../components/group-buttons/buttons-client";
import rowClientStyle from '../../../ra/datagrid/row/client';

const ClientList = (props) => (
  <List
    title="Clientes"
    sort={{ field: 'c.id', order: 'DESC' }}
    filters={ FilterEnterpriseClient }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      rowStyle={rowClientStyle()}>
      <TextField source="id" sortBy="c.id" />
      <TextField source="name" label="Nome" sortBy="c.name" />
      <TextField source="niche.name" label="Nicho" sortBy="ecn.name" />
      <TextField source="enterprise_region.name" label="Região" sortBy="er.name" />
      <BooleanField source="active" label="Ativo" sortBy="c.active" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="c.created_at" />
      <ButtonsClient />
    </Datagrid>
  </List>
);

export default ClientList;
