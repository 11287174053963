import { usePermissions, useResourceDefinitions, useResourceContext } from 'react-admin';
import WrapperDirection from "./wrapper-direction";
import DetailsButton from "./buttons/details-button";
import EditButton from "./buttons/edit-button";

const DetailEditWithPermission = () =>
{
  const { permissions } = usePermissions();
  const resources = useResourceDefinitions();
  const resource = useResourceContext();
  const resourceParams = resources[resource];

  return (
    <WrapperDirection>
    { permissions.permissions.some(e => e.keys.some(k => k.key === resourceParams.options.permissions.show)) && <DetailsButton /> }
    { permissions.permissions.some(e => e.keys.some(k => k.key === resourceParams.options.permissions.edit)) && <EditButton /> }
    </WrapperDirection>
  );
};

export default DetailEditWithPermission;
