import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField
} from 'react-admin';
import FilterCashRegister from "../../../filters/enterprise/cash-register";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsCashRegister from "../../../components/group-buttons/buttons-cash-register";
import rowCashRegisterStyle from '../../../ra/datagrid/row/cash-register';

const CashRegisterList = () => (
  <List
    title="Fechamentos de Caixa"
    sort={{ field: 'ccr.id', order: 'DESC' }}
    filters={ FilterCashRegister }
    actions={ <ActionsWithPermission hasCreate={false} /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      rowStyle={rowCashRegisterStyle()}>
      <TextField source="id" sortBy="ccr.id" />
      <TextField source="status_cash_register_name" label="Status" sortBy="status_cash_register_name" />
      <ArrayField label="Formas de Pagamento" source="enterprise_payment_forms" fieldKey="uuid" sortable={false}>
        <SingleFieldList
          linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="customer_name" label="Colaborador" sortBy="cus.name" />
      <TextField source="date_format" label="Data" sortBy="ccr.date" />
      <TextField source="value_balance_format" label="Saldo do Dia" sortBy="value_balance" />
      <TextField source="value_deposited_format" label="Valor Depositado" sortBy="ccr.value_deposited" />
      <TextField source="value_possession_format" label="Valor em Posse"  sortBy="ccr.value_possession" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="ccr.created_at" />
      <ButtonsCashRegister />
    </Datagrid>
  </List>
);

export default CashRegisterList;
