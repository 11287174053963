import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  NumberInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, number } from "../../../utils/schemas";

const Schema = Yup.object({
  enterprise_payment_form_id: leastOneOption(),
  value: number()
});

const InstallmentPenaltyEdit = () => (
  <Edit
    title={ <Title subtitle="Editar Multa" source="installment_client_name" /> }
    redirect="list"
    mutationMode="pessimistic">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={ <ToolbarSave /> }>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <TextInput source="id" label="Id" fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={10} />

        <Grid item xs={12} sm={6}>
          <TextInput source="installment_client_name" label="Cliente" disabled fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <TextInput source="installment_contract_name" label="Contrato" disabled fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ReferenceInput source="customer_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/customers" >
            <SelectInput label="Colaborador" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ReferenceInput source="enterprise_payment_form_id" sort={{ field: 'name', order: 'ASC' }} filter={{ allow_enterprise_penalty: true }} reference="setup/enterprise/payment-forms" >
            <SelectInput label="Forma de Pagamento" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={3}>
          <DateTimeInput source="date_payment" label="Data do Pagamento" fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumberInput source="value" label="Valor" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default InstallmentPenaltyEdit;
