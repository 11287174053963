import config from '../../../config';

const rowInstallmentPenaltyStyle = () => (record, index, defaultStyle = {}) =>
{
  let style = defaultStyle;

  //INSTALLMENT
  if (record.type_item === config.type_item_contract.installment)
  {
    if (record.status_installment_type === 0)
      return {
        ...style,
        background: '#D2F8D2',
        borderLeftColor: '#D2F8D2',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid'
      };
    else if (record.status_installment_type === 1)
      return {
        ...style,
        background: '#FFC0CB',
        borderLeftColor: '#FFC0CB',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid'
      };
    else if (record.status_installment_type === 2)
      return {
        ...style,
        background: '#E8F4F8',
        borderLeftColor: '#E8F4F8',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid'
      };
    else if (record.status_installment_type === 3)
      return {
        ...style,
        background: '#C1E1EC',
        borderLeftColor: '#C1E1EC',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid'
      };
    else if (record.status_installment_type === 4)
      return {
        ...style,
        background: '#FFDB9A',
        borderLeftColor: '#FFDB9A',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid'
      };
    else if (record.status_installment_type === 5)
      return {
        ...style,
        background: '#CBC3E3',
        borderLeftColor: '#CBC3E3',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid'
      };
    else if (record.status_installment_type === 6)
      return {
        ...style,
        background: '#FFE9EC',
        borderLeftColor: '#FFE9EC',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid'
      };
  }
  //PENALTY
  else if (record.type_item === config.type_item_contract.penalty)
  {
    return {
      ...style,
      background: '#F9E8D9',
      borderLeftColor: '#F9E8D9',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };
  }

  return style;
};

export default rowInstallmentPenaltyStyle;
