import { Chip } from "@mui/material";
import { SearchInput, ReferenceInput, SelectInput, DateInput } from "react-admin";

const FilterEnterpriseInstallmentPenalty = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Colaborador" source="customer_id" reference="setup/enterprise/customers">
    <SelectInput
      label="Colaborador"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }} />
  </ReferenceInput>,
  <ReferenceInput label="Forma de Pagamento" source="enterprise_payment_form_id" filter={{ allow_enterprise_penalty: true }} reference="setup/enterprise/payment-forms">
    <SelectInput
      label="Forma de Pagamento"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }} />
  </ReferenceInput>,
  <Chip source="unpaid_penalties" label="Multas Pendentes" defaultValue={true} />,
  <DateInput source="date_payment" label="Data de Início Pagamento" />,
  <DateInput source="date_end_payment" label="Data Final Pagamento" />
];

export default FilterEnterpriseInstallmentPenalty;
