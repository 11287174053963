import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import FilterWithdrawal from "../../../filters/customer/withdrawal";
import Actions from "../../../ra/list/actions";
import WrapperDirection from "../../../components/wrapper-direction";
import DetailsButton from "../../../components/buttons/details-button";

const WithdrawalList = () => (
  <List
    title="Retiradas"
    sort={{ field: 'w.id', order: 'DESC' }}
    filters={ FilterWithdrawal }
    actions={ <Actions hasExport={false} /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="w.id" />
      <TextField source="enterprise_type_withdrawal_name" label="Tipo" sortBy="etw.name" />
      <TextField source="name" label="Nome" sortBy="w.name" />
      <TextField source="value_format" label="Valor" sortBy="w.value" />
      <DateField source="date_withdrawal" label="Data" locales="pt-BR" showTime sortBy="w.date_withdrawal" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="w.created_at" />
      <WrapperDirection>
        <DetailsButton />
      </WrapperDirection>
    </Datagrid>
  </List>
);

export default WithdrawalList;
