import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import FilterDefault from "../../../filters/default";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import DetailEditWithPermission from "../../../components/detail-edit-with-permission";

const EnterpriseBankAccountList = () => (
  <List
    title="Contas Bancárias"
    sort={{ field: 'eba.id', order: 'DESC' }}
    filters={ FilterDefault }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="eba.id" />
      <TextField source="name" label="Nome" sortBy="eba.name" />
      <BooleanField source="active" label="Ativo" sortBy="eba.active" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="eba.created_at" />
      <DetailEditWithPermission />
    </Datagrid>
  </List>
);

export default EnterpriseBankAccountList;
