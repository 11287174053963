import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import FilterEnterpriseLog from "../../../filters/enterprise/log";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsLog from "../../../components/group-buttons/buttons-log";

const EnterpriseLogList = () => (
  <List
    title="Logs"
    sort={{ field: 'el.id', order: 'DESC' }}
    filters={ FilterEnterpriseLog }
    actions={ <ActionsWithPermission hasCreate={false} /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="el.id" />
      <TextField source="type_log_name" label="Tipo" sortBy="tl.name" />
      <TextField source="type_user_log_name" label="Tipo Usuário" sortBy="tul.name" />
      <TextField source="category_log_name" label="Categoria" sortBy="cl.name" />
      <TextField source="user_name" label="Usuário" sortBy="user_name" />
      <TextField source="message" label="Mensagem" sortBy="el.message" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="el.created_at" />
      <ButtonsLog />
    </Datagrid>
  </List>
);

export default EnterpriseLogList;
