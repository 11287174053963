import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  Datagrid,
  TextField,
  FunctionField,
  useListController
} from 'react-admin';
import { Card } from '@mui/material';
import FilterEnterpriseInvoice from "../../../filters/enterprise/invoice";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsInvoice from "../../../components/group-buttons/buttons-invoice";
import InvoicePanel from '../../../panels/enterprise/invoice-panel';
import config from "../../../config";
import rowInvoiceStyle from '../../../ra/datagrid/row/invoice';
import formatValue from "../../../utils/currency";
const InvoicePagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100, 250, 500, 1000 ]} {...props} />;

const CustomList = ({ children, actions, filters, title, ...props }) =>
{
  const { data, loading } = useListController();

  if (!loading && data)
  {
    var totalPay = 0;
    var totalToPay = 0;
    var totalOverdue = 0;

    data.forEach((item, i) =>
    {
      if (item.status_invoice_type === config.status_invoice.pay)
          totalPay += parseFloat(item.payment.value);
      else if (item.status_invoice_type === config.status_invoice.in_day ||
               item.status_invoice_type === config.status_invoice.overdue)
      {
        totalToPay += parseFloat(item.value);

        if (item.status_invoice_type === config.status_invoice.overdue)
            totalOverdue += parseFloat(item.value);
      }
    });

    return (
      <ListBase {...props}>
        <Title title={title}/>
        <ListToolbar filters={filters} actions={actions}/>
        <Card>
        {children}
        </Card>
        <br/>
        <div>
          <b>
            Valor Total Pago: { formatValue(totalPay) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor a Pagar: { formatValue(totalToPay) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor em Atraso: { formatValue(totalOverdue) }
          </b>
        </div>
        <InvoicePagination />
      </ListBase>
    );
  }
  else
    return null;
}

const EnterpriseInvoiceList = () => (
  <CustomList
    title="Contas a Pagar"
    sort={{ field: 'ei.id', order: 'DESC' }}
    filters={ FilterEnterpriseInvoice }
    perPage={25}
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      expandSingle
      expand={<InvoicePanel />}
      isRowExpandable={(row) => (row.relations && row.relations.length > 0)}
      rowStyle={rowInvoiceStyle()}>
      <TextField source="id" sortBy="ei.id" />
      <TextField source="status_invoice_name" label="Status" sortBy="status_invoice_name" />
      <TextField source="enterprise_type_invoice_name" label="Tipo" sortBy="enterprise_type_invoice_name" />
      <TextField source="description" label="Descrição" sortBy="ei.description" />
      <TextField source="date_issue_format" label="Data Emissão" sortBy="ei.date_issue" />
      <TextField source="date_due_format" label="Data Vencimento" sortBy="ei.date_due" />
      <FunctionField source="value" label="Valor" render={record => `${formatValue(record.value)}`} sortBy="ei.value" />
      <TextField source="payment.user_enterprise_name" label="Paga Por" sortBy="ue.name" />
      <TextField source="payment.enterprise_bank_account_name" label="Conta" sortBy="eba.name" />
      <TextField source="payment.enterprise_payment_form_name" label="Forma" sortBy="epf.name" />
      <TextField source="payment.value_format" label="Valor Pago" sortBy="eip.value" />
      <TextField source="payment.date_payment_format" label="Data do Pagamento" sortBy="eip.date_payment" />
      <ButtonsInvoice />
    </Datagrid>
  </CustomList>
);

export default EnterpriseInvoiceList;
