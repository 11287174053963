import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import FilterEnterpriseClientSearch from "../../../filters/enterprise/client-search";
import Actions from "../../../ra/list/actions";
import rowClientStyle from '../../../ra/datagrid/row/client';

const EnterpriseClientSearchList = (
  <List
    title="Clientes"
    sort={{ field: 'c.id', order: 'DESC' }}
    empty={false}
    filters={ FilterEnterpriseClientSearch }
    actions={ <Actions hasFilter={false} hasCreate={false} hasExport={false} /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      rowStyle={rowClientStyle()}>
      <TextField source="id" sortBy="c.id" />
      <TextField source="enterprise.name" label="Empresa" sortBy="e.name" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="c.created_at" />
    </Datagrid>
  </List>
);

export default EnterpriseClientSearchList;
