import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  Datagrid,
  TextField,
  FunctionField,
  useListController
} from 'react-admin';
import { Card } from '@mui/material';
import FilterEnterpriseVehiclePenalty from "../../../filters/enterprise/vehicle-penalty";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsVehiclePenalty from "../../../components/group-buttons/buttons-vehicle-penalty";
import config from "../../../config";
import rowVehiclePenaltyStyle from '../../../ra/datagrid/row/vehicle-penalty';
import formatValue from "../../../utils/currency";
const EnterpriseVehiclePenaltyPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100, 250, 500, 1000 ]} {...props} />;

const CustomList = ({ children, actions, filters, title, ...props }) =>
{
  const { data, loading } = useListController();

  if (!loading && data)
  {
    var totalPay = 0;
    var totalToPay = 0;
    var totalOverdue = 0;

    data.forEach((item, i) =>
    {
      if (item.status_penalty_type === config.status_penalty.pay)
          totalPay += parseFloat(item.payment.value);
      else if (item.status_penalty_type === config.status_penalty.in_day ||
               item.status_penalty_type === config.status_penalty.overdue)
      {
        totalToPay += parseFloat(item.value);

        if (item.status_penalty_type === config.status_penalty.overdue)
            totalOverdue += parseFloat(item.value);
      }
    });

    return (
      <ListBase {...props}>
        <Title title={title}/>
        <ListToolbar filters={filters} actions={actions}/>
        <Card>
        {children}
        </Card>
        <br/>
        <div>
          <b>
            Valor Total Pago: { formatValue(totalPay) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor a Pagar: { formatValue(totalToPay) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor em Atraso: { formatValue(totalOverdue) }
          </b>
        </div>
        <EnterpriseVehiclePenaltyPagination />
      </ListBase>
    );
  }
  else
    return null;
}

const EnterpriseVehiclePenaltyList = () => (
  <CustomList
    title="Infrações"
    sort={{ field: 'evp.id', order: 'DESC' }}
    filters={ FilterEnterpriseVehiclePenalty }
    perPage={25}
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      rowStyle={rowVehiclePenaltyStyle()}>
      <TextField source="id" sortBy="evp.id" />
      <TextField source="status_penalty_name" label="Status" sortBy="status_penalty_name" />
      <TextField source="enterprise_vehicle_name" label="Veículo" sortBy="enterprise_vehicle_name" />
      <TextField source="customer_name" label="Condutor" sortBy="customer_name" />
      <TextField source="date_penalty_format" label="Data Infração" sortBy="evp.date_penalty" />
      <TextField source="date_due_format" label="Data Vencimento" sortBy="evp.date_due" />
      <FunctionField source="value" label="Valor" render={record => `${formatValue(record.value)}`} sortBy="evp.value" />
      <TextField source="payment.user_enterprise_name" label="Paga Por" sortBy="ue.name" />
      <TextField source="payment.enterprise_bank_account_name" label="Conta" sortBy="eba.name" />
      <TextField source="payment.enterprise_payment_form_name" label="Forma" sortBy="epf.name" />
      <TextField source="payment.value_format" label="Valor Pago" sortBy="evpp.value" />
      <TextField source="payment.date_payment_format" label="Data do Pagamento" sortBy="evpp.date_payment" />
      <ButtonsVehiclePenalty />
    </Datagrid>
  </CustomList>
);

export default EnterpriseVehiclePenaltyList;
