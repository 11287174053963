import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField
} from 'react-admin';
import { ColorField } from 'react-admin-color-picker';

const EnterpriseClientClassificationShow = () => (
  <Show
    title="Classificação">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" label="Nome" />
      <ColorField source="color_base" label="Cor" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default EnterpriseClientClassificationShow;
