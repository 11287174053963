const rowClientStyle = () => (record, index, defaultStyle = {}) =>
{
  let style = defaultStyle;
  if (record.classification !== null)
    return {
      ...style,
      borderLeftColor: record.classification.color_base,
      borderLeftWidth: 5,
      borderLeftStyle: 'solid'
    };

  return style;
};

export default rowClientStyle;
