import {
  Create,
  SimpleForm,
  TextInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import CustomRichTextInput from "../../../components/inputs/custom-rich-text-input";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue } from "../../../utils/schemas";

const Schema = Yup.object({
  title: hasValue(),
  report_text: hasValue()
});

const ReportDayCreate = () => (
  <Create
    title="Novo Relatório Diário"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput source="title" label="Título" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <CustomRichTextInput source="report_text" label="Texto" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default ReportDayCreate;
