import * as React from 'react';
import { ReactNode } from 'react';
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
  Badge
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useSidebarState } from 'react-admin';

interface Props
{
  dense: boolean;
  handleToggle: () => void;
  isOpen: boolean;
  name: string;
  children: ReactNode;
}

const SubMenu = (props: Props) =>
{
  const { handleToggle, isOpen, name, children, dense, showBadge = false } = props;
  const [sidebarIsOpen] = useSidebarState();

  const header = (
    <MenuItem
      dense={dense}
      onClick={handleToggle}>
      <ListItemIcon
        sx={{ minWidth: 5 }}>
      { isOpen ? <ExpandMore /> : <KeyboardArrowRight /> }
      </ListItemIcon>
      <Typography
        variant="inherit"
        color="textSecondary">
      { name }
      </Typography>
      <Badge
        color="error"
        overlap="circular"
        variant="dot"
        style={{transform: 'translate(10px, 0px)'}}
        invisible={!showBadge} />
    </MenuItem>
  );

  return (
    <div>
    {
      sidebarIsOpen || isOpen ?
      ( header ) :
      (
        <Tooltip
          title={name}
          placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          sx={
          {
            '& .MuiMenuItem-root':
            {
              transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              paddingLeft: theme => sidebarIsOpen ? theme.spacing(4) : theme.spacing(2)
            }
          }}>
          {children}
        </List>
      </Collapse>
    </div>
  );
};

export default SubMenu;
