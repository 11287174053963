import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  BooleanField
} from 'react-admin';
import FilterDefault from "../../../filters/default";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import DetailEditWithPermission from "../../../components/detail-edit-with-permission";

const CustomerList = () => (
  <List
    title="Colaboradores"
    sort={{ field: 'c.id', order: 'DESC' }}
    filters={ FilterDefault }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" sortBy="c.id" />
      <TextField source="name" label="Nome" sortBy="c.name" />
      <TextField source="email" label="E-mail" sortBy="c.email" />
      <ArrayField label="Regiões" source="enterprise_regions" fieldKey="uuid" sortable={false}>
        <SingleFieldList
          linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <BooleanField source="active" label="Ativo" sortBy="c.active" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="c.created_at" />
      <DetailEditWithPermission />
    </Datagrid>
  </List>
);

export default CustomerList;
