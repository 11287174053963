import {
  TextField,
  FunctionField,
  DateField,
  SimpleShowLayout
} from 'react-admin';
import formatValue from "../../utils/currency";

const InstallmentPanel = () => (
  <SimpleShowLayout>
    <TextField source="id" sortBy="i.id" />
    <TextField source="original_date_billing_format" label="Data de Cobrança Original" />
    <FunctionField source="value_cost" label="Valor Custo" render={record => `${formatValue(record.value_cost)}`} />
    <FunctionField source="value_fees" label="Valor Juros" render={record => `${formatValue(record.value_fees)}`} />
    <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
  </SimpleShowLayout>
);

export default InstallmentPanel;
