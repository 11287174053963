import {
  ListBase,
  Title,
  ListToolbar,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  useListController,
} from "react-admin";
import { Card, Stack } from "@mui/material";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import FilterBankingFlow from "../../../filters/enterprise/banking-flow";
import BankingFlowBalanceInput from "../../../components/inputs/banking-flow-balance-input";
import config from "../../../config";
import moment from "moment";
import rowBankingFlowStyle from "../../../ra/datagrid/row/banking-flow";
import { getCurrentUser } from "../../../utils/api-params";
import formatValue from "../../../utils/currency";

const CustomList = ({ children, actions, filters, title, ...props }) =>
{
  const { data, loading } = useListController();

  if (!loading && data)
  {
    const currentUser = getCurrentUser();
    var total = 0;
    data.forEach((item, i) =>
    {
      if (item.type === config.type_banking_flow.withdrawal)
        total -= parseFloat(item.value);
      else if (item.type === config.type_banking_flow.deposit)
        total += parseFloat(item.value);
    });

    return (
      <ListBase {...props}>
        <Title title={title} />
        <Stack
          direction="column"
          alignItems="status_contract_type"
          spacing={1}
          style={{ marginTop: "15px" }}>
          <BankingFlowBalanceInput totalValue={total} balanceValue={currentUser.enterprise.value_balance_banking_flow} />
          <ListToolbar filters={filters} actions={actions} />
        </Stack>
        <Card
          style={{ marginBottom: "15px" }}>
          {children}
        </Card>
      </ListBase>
    );
  }
  else
    return null;
};

const BankingFlowList = () => (
  <CustomList
    title="Fluxo Bancário"
    sort={{ field: "bf.id", order: "DESC" }}
    filters={FilterBankingFlow}
    filterDefaultValues={
    {
      date_init_banking_flow: moment().format("yyyy/MM/DD"),
      date_end_banking_flow: moment().format("yyyy/MM/DD")
    }}
    actions={<ActionsWithPermission hasCreate={false} hasFilter={false} />}>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      rowSx={rowBankingFlowStyle()}>
      <TextField source="id" sortable={false} />
      <TextField source="type_name" label="Tipo" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <FunctionField source="value" label="Valor" render={(record) => `${formatValue(record.value)}`} sortable={false} />
      <DateField source="date" locales="pt-BR" label="Criado em" showTime />
    </Datagrid>
  </CustomList>
);

export default BankingFlowList;
