import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasValue } from "../../../utils/schemas";

const Schema = Yup.object({
  type: hasValue(),
  plate: hasValue()
});

const EnterpriseVehicleCreate = () => (
  <Create
    title="Novo Veículo"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <TextInput source="type" label="Tipo" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="plate" label="Placa" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="color" label="Cor" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <TextInput source="brand" label="Fabricante" fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="model" label="Modelo" fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="fuel" label="Combustível" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <TextInput source="year_manufacture" label="Ano Fabricação" fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="year_model" label="Ano Modelo" fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="mileage" label="Quilometragem" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <TextInput source="chassis_number" label="Chassi" fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="document_number" label="Renavam" fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput source="situation" label="Situação" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ReferenceArrayInput source="conductors" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/customers" >
            <SelectArrayInput label="Condutor(es)" optionText="name" optionValue="id" fullWidth />
          </ReferenceArrayInput>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EnterpriseVehicleCreate;
