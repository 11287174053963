import { useFormContext, useWatch } from "react-hook-form";
import {
  CheckboxGroupInput,
  ReferenceArrayInput,
  SelectArrayInput,
  useRecordContext,
  useGetOne
} from "react-admin";
import { Stack } from "@mui/material";
import { useEffect } from "react";

const PermissionsInput = ({ isEdit, ...props }) =>
{
  var record = useRecordContext();
  const form = useFormContext();
  const enterprise_id = useWatch({ name: "enterprise_id" });
  const { data } = useGetOne("setup/admin/permissions", { id: 1 });

  if (!isEdit)
    record = { permissions: [] };

  useEffect(() =>
  {
    const values = form.getValues();
    var obj = { ...values };

    Object.keys(values).forEach(key =>
    {
      if (key.startsWith("filter_customers_"))
        obj[key] = [];
    });

    form.reset(obj);

  }, [JSON.stringify(enterprise_id)]);

  if (!record || !record.permissions || !data)
    return;
  else
    return (
      data.result.map(item =>
      {
        var keyFilterCustomers = ('filter_customers_' + item.key);
        var permission = record.permissions.filter(permission => permission.key === item.key);
        var keys = (permission !== null && permission.length > 0) ? permission[0].keys.map(y => y.key) : [];
        var customers = (permission !== null && permission.length > 0) ? permission[0].filter_customers.map(y => y.customer_id) : [];
        record[keyFilterCustomers] = customers;

        return (
          <Stack
            direction="column"
            alignItems="start"
            spacing="1px"
            key={(Math.random().toString(36))}
            sx={{ width: "100%" }}>
            <CheckboxGroupInput
              label={item.name}
              source={('keys_permission_' + item.key)}
              defaultValue={keys}
              choices={item.permissions}
              optionValue="key" />
            {
              item.filter_customer === true ?
                <ReferenceArrayInput
                  label="Colaborador(es)"
                  filter={{ enterprise_id: enterprise_id }}
                  source={keyFilterCustomers}
                  reference="setup/admin/customers">
                  <SelectArrayInput
                    label="Colaborador(es)"
                    optionText="name"
                    optionValue="id"
                    fullWidth />
                </ReferenceArrayInput>
              : null
            }
          </Stack>
        )
      })
    );
};

export default PermissionsInput;
