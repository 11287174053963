import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import FilterEnterpriseEmployee from "../../../filters/enterprise/employee";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsEmployee from "../../../components/group-buttons/buttons-employee";
import rowEmployeeStyle from '../../../ra/datagrid/row/employee';

const EnterpriseEmployeeList = () => (
  <List
    title="Funcionários"
    sort={{ field: 'ee.id', order: 'DESC' }}
    filters={ FilterEnterpriseEmployee }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      rowStyle={rowEmployeeStyle()}>
      <TextField source="id" sortBy="ee.id" />
      <TextField source="employee_situation_name" label="Situação" sortBy="employee_situation_name" />
      <TextField source="name" label="Nome" sortBy="ee.name" />
      <TextField source="document" label="CPF" sortBy="ee.document" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="ee.created_at" />
      <ButtonsEmployee />
    </Datagrid>
  </List>
);

export default EnterpriseEmployeeList;
