const formatValue = (value) =>
{
  if (value !== null && value !== undefined)
    return new Intl.NumberFormat("pt-BR",
    {
      style: "currency",
      currency: "BRL"
    }).format(value);
  else
    return '';
}

export default formatValue;
