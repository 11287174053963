import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  DateField,
  usePermissions
} from 'react-admin';
import formatValue from "../../../utils/currency";
import config from "../../../config";

const EnterpriseClientNicheShow = () =>
{
  const { permissions } = usePermissions();

  return (
    <Show
      title="Nicho">
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" label="Nome" />
        <TextField source="count_clients" label="Nº Clientes" />
        {
          permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.client_niches.show_value_invested)) &&
          (
            <FunctionField source="value_total_contracts" label="Valor Investido" render={record => `${formatValue(record.value_total_contracts)}`} />
          )
        }
        <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
      </SimpleShowLayout>
    </Show>
  );
}

export default EnterpriseClientNicheShow;
