import { usePermissions } from 'react-admin';
import WrapperDirection from "../wrapper-direction";
import DetailsButton from "../buttons/details-button";
import DialogVehicleMaintenanceActions from "../dialogs/dialog-vehicle-maintenance-actions";
import config from "../../config";

const ButtonsVehicleMaintenance = () =>
{
  const { permissions } = usePermissions();

  return (
    <WrapperDirection>
      { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.vehicles_maintenances.show)) && <DetailsButton /> }
      <DialogVehicleMaintenanceActions />
    </WrapperDirection>
  );
};

export default ButtonsVehicleMaintenance;
