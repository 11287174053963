import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import FilterEnterpriseReportCommission from "../../../filters/enterprise/report-commission";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsReportComission from "../../../components/group-buttons/buttons-report-commission";

const EnterpriseReportCommissionList = () => (
  <List
    title="Relatórios de Comissão"
    sort={{ field: 'erc.id', order: 'DESC' }}
    filters={ FilterEnterpriseReportCommission }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}>
      <TextField source="id" label="Id" sortBy="erc.id" />
      <TextField source="type_enterprise_report_commission_name" label="Tipo" sortBy="erc.type_enterprise_report_commission" />
      <TextField source="customer_name" label="Colaborador" sortBy="cus.name" />
      <TextField source="date_init_format" label="Data de Início" sortBy="erc.date_init" />
      <TextField source="date_end_format" label="Data de Término" sortBy="erc.date_end" />
      <TextField source="count_contracts" label="Nº Contratos" sortBy="count_contracts" />
      <TextField source="value_total_format" label="Valor Total" sortBy="erc.value_total" />
      <TextField source="value_cost_format" label="Valor Despesas" sortBy="erc.value_cost" />
      <TextField source="percentage" label="Porcentagem" sortBy="erc.percentage" />
      <TextField source="value_commission_format" label="Valor Comissão" sortBy="erc.value_commission" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime sortBy="erc.created_at" />
      <ButtonsReportComission />
    </Datagrid>
  </List>
);

export default EnterpriseReportCommissionList;
