import {
  Form,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { PersonOff, Cancel, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import Button from "../buttons/button";
import config from '../../config';

function DialogInstallmentClientAbsent({ isEnterprise = true })
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { on, toggle } = useToggle();
  const { loading, action } = useForRequest();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleSubmit = async (values) =>
  {
    const { error } = await action(`customer-installment/${record.id}/client/absent`, 'POST', values);
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    refresh();
    toggle();
    notify('Ausência registrada com sucesso!', { type: "success" });
  };

  if (!isEnterprise &&
      (record.status_installment_type === config.status_installment.in_day ||
       record.status_installment_type === config.status_installment.overdue))
  {
    return (
      <>
        <Button onClick={handleClick} label="Cliente Ausente">
          <PersonOff />
        </Button>

        <Dialog
          fullWidth
          open={on}
          onClose={handleCloseClick}
          aria-label="Registar cliente não encontrado">
          <Form
            onSubmit={handleSubmit}>
            <DialogTitle>
              Tem certeza que deseja registar que cliente não foi encontrado?
            </DialogTitle>
            <DialogActions>
              <Button
                label="Cancelar"
                disabled={loading}
                color="error"
                onClick={handleCloseClick}>
                <Cancel />
              </Button>
              <Button
                label="Salvar"
                color="success"
                type="submit"
                disabled={loading}>
                <Save />
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    );
  }
  else
    return null;
}

export default DialogInstallmentClientAbsent;
