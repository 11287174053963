import { AppBar, TitlePortal } from "react-admin";

function NewAppBar()
{
  return (
    <AppBar color="primary">
      <TitlePortal />
    </AppBar>
  );
}

export default NewAppBar;
