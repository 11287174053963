import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import Login from "./view/login";
import Dashboard from "./view/dashboard";
import polyglotI18nProvider from "ra-i18n-polyglot";
import portugueseMessages from "./i18n/pt";
import dataProvider from "./provider/data-provider";
import authProvider from "./provider/auth-provider";
import config from "./config";

// ENTERPRISE
import EnterpriseIcon from "@mui/icons-material/Apartment";
import EnterpriseList from "./container/admin/enterprise/list";
import EnterpriseCreate from "./container/admin/enterprise/create";
import EnterpriseEdit from "./container/admin/enterprise/edit";
import EnterpriseShow from "./container/admin/enterprise/show";

// USER ENTERPRISE
import UserEnterpriseIcon from "@mui/icons-material/SupervisedUserCircle";
import UserEnterpriseList from "./container/admin/user-enterprise/list";
import UserEnterpriseCreate from "./container/admin/user-enterprise/create";
import UserEnterpriseEdit from "./container/admin/user-enterprise/edit";
import UserEnterpriseShow from "./container/admin/user-enterprise/show";

// CHARTS
import EnterpriseChartsMonthsDashboard from "./view/enterprise-charts-months-dashboard";

// ENTERPRISE REGION
import EnterpriseRegionIcon from "@mui/icons-material/Map";
import EnterpriseRegionList from "./container/enterprise/region/list";
import EnterpriseRegionCreate from "./container/enterprise/region/create";
import EnterpriseRegionEdit from "./container/enterprise/region/edit";
import EnterpriseRegionShow from "./container/enterprise/region/show";

// ENTERPRISE BILLING PERIOD
import EnterpriseBillingPeriodIcon from "@mui/icons-material/Shop";
import EnterpriseBillingPeriodList from "./container/enterprise/billing-period/list";
import EnterpriseBillingPeriodCreate from "./container/enterprise/billing-period/create";
import EnterpriseBillingPeriodEdit from "./container/enterprise/billing-period/edit";
import EnterpriseBillingPeriodShow from "./container/enterprise/billing-period/show";

// ENTERPRISE PAYMENT FORM
import EnterprisePaymentFormIcon from "@mui/icons-material/Payment";
import EnterprisePaymentFormList from "./container/enterprise/payment-form/list";
import EnterprisePaymentFormCreate from "./container/enterprise/payment-form/create";
import EnterprisePaymentFormEdit from "./container/enterprise/payment-form/edit";
import EnterprisePaymentFormShow from "./container/enterprise/payment-form/show";

// ENTERPRISE TYPE WITHDRAWAL
import EnterpriseTypeWithdrawalIcon from "@mui/icons-material/Payment";
import EnterpriseTypeWithdrawalList from "./container/enterprise/type-withdrawal/list";
import EnterpriseTypeWithdrawalCreate from "./container/enterprise/type-withdrawal/create";
import EnterpriseTypeWithdrawalEdit from "./container/enterprise/type-withdrawal/edit";
import EnterpriseTypeWithdrawalShow from "./container/enterprise/type-withdrawal/show";

// ENTERPRISE TYPE INVOICE
import EnterpriseTypeInvoiceIcon from "@mui/icons-material/Receipt";
import EnterpriseTypeInvoiceList from "./container/enterprise/type-invoice/list";
import EnterpriseTypeInvoiceCreate from "./container/enterprise/type-invoice/create";
import EnterpriseTypeInvoiceEdit from "./container/enterprise/type-invoice/edit";
import EnterpriseTypeInvoiceShow from "./container/enterprise/type-invoice/show";

// ENTERPRISE BANK ACCOUNT
import EnterpriseBankAccountIcon from "@mui/icons-material/AccountBalance";
import EnterpriseBankAccountList from "./container/enterprise/bank-account/list";
import EnterpriseBankAccountCreate from "./container/enterprise/bank-account/create";
import EnterpriseBankAccountEdit from "./container/enterprise/bank-account/edit";
import EnterpriseBankAccountShow from "./container/enterprise/bank-account/show";

// ENTERPRISE CLIENT NICHE
import EnterpriseClientNicheIcon from "@mui/icons-material/Groups";
import EnterpriseClientNicheList from "./container/enterprise/client-niche/list";
import EnterpriseClientNicheCreate from "./container/enterprise/client-niche/create";
import EnterpriseClientNicheEdit from "./container/enterprise/client-niche/edit";
import EnterpriseClientNicheShow from "./container/enterprise/client-niche/show";

// ENTERPRISE CLIENT CLASSIFICATION
import EnterpriseClientClassificationIcon from "@mui/icons-material/RateReview";
import EnterpriseClientClassificationList from "./container/enterprise/client-classification/list";
import EnterpriseClientClassificationCreate from "./container/enterprise/client-classification/create";
import EnterpriseClientClassificationEdit from "./container/enterprise/client-classification/edit";
import EnterpriseClientClassificationShow from "./container/enterprise/client-classification/show";

// ENTERPRISE REPORT DAY
import EnterpriseReportDayIcon from "@mui/icons-material/Today";
import EnterpriseReportDayList from "./container/enterprise/report-day/list";
import EnterpriseReportDayShow from "./container/enterprise/report-day/show";

// ENTERPRISE REPORT COMMISSION
import EnterpriseReportCommissionIcon from "@mui/icons-material/PriceCheck";
import EnterpriseReportCommissionList from "./container/enterprise/report-commission/list";
import EnterpriseReportCommissionCreate from "./container/enterprise/report-commission/create";
import EnterpriseReportCommissionShow from "./container/enterprise/report-commission/show";

// ENTERPRISE VEHICLE
import EnterpriseVehicleIcon from "@mui/icons-material/DirectionsCar";
import EnterpriseVehicleList from "./container/enterprise/vehicle/list";
import EnterpriseVehicleCreate from "./container/enterprise/vehicle/create";
import EnterpriseVehicleEdit from "./container/enterprise/vehicle/edit";
import EnterpriseVehicleShow from "./container/enterprise/vehicle/show";

// ENTERPRISE VEHICLE MAINTENANCE
import EnterpriseVehicleMaintenanceIcon from "@mui/icons-material/Engineering";
import EnterpriseVehicleMaintenanceList from "./container/enterprise/vehicle-maintenance/list";
import EnterpriseVehicleMaintenanceCreate from "./container/enterprise/vehicle-maintenance/create";
import EnterpriseVehicleMaintenanceEdit from "./container/enterprise/vehicle-maintenance/edit";
import EnterpriseVehicleMaintenanceShow from "./container/enterprise/vehicle-maintenance/show";

// ENTERPRISE VEHICLE PENALTY
import EnterpriseVehiclePenaltyIcon from "@mui/icons-material/Traffic";
import EnterpriseVehiclePenaltyList from "./container/enterprise/vehicle-penalty/list";
import EnterpriseVehiclePenaltyCreate from "./container/enterprise/vehicle-penalty/create";
import EnterpriseVehiclePenaltyEdit from "./container/enterprise/vehicle-penalty/edit";
import EnterpriseVehiclePenaltyShow from "./container/enterprise/vehicle-penalty/show";

// ENTERPRISE TYPE EMPLOYEE COST
import EnterpriseTypeEmployeeCostIcon from "@mui/icons-material/SensorOccupied";
import EnterpriseTypeEmployeeCostList from "./container/enterprise/type-employee-cost/list";
import EnterpriseTypeEmployeeCostCreate from "./container/enterprise/type-employee-cost/create";
import EnterpriseTypeEmployeeCostEdit from "./container/enterprise/type-employee-cost/edit";
import EnterpriseTypeEmployeeCostShow from "./container/enterprise/type-employee-cost/show";

// ENTERPRISE EMPLOYEE COST
import EnterpriseEmployeeIcon from "@mui/icons-material/Badge";
import EnterpriseEmployeeList from "./container/enterprise/employee/list";
import EnterpriseEmployeeCreate from "./container/enterprise/employee/create";
import EnterpriseEmployeeEdit from "./container/enterprise/employee/edit";
import EnterpriseEmployeeShow from "./container/enterprise/employee/show";

// ENTERPRISE EMPLOYEE COST
import EnterpriseEmployeeCostIcon from "@mui/icons-material/PriceChange";
import EnterpriseEmployeeCostList from "./container/enterprise/employee-cost/list";
import EnterpriseEmployeeCostCreate from "./container/enterprise/employee-cost/create";
import EnterpriseEmployeeCostEdit from "./container/enterprise/employee-cost/edit";
import EnterpriseEmployeeCostShow from "./container/enterprise/employee-cost/show";

// CUSTOMER
import CustomerIcon from "@mui/icons-material/AccountCircle";
import CustomerList from "./container/enterprise/customer/list";
import CustomerCreate from "./container/enterprise/customer/create";
import CustomerEdit from "./container/enterprise/customer/edit";
import CustomerShow from "./container/enterprise/customer/show";

// ENTERPRISE CLIENT SEARCH
import EnterpriseClientSearchIcon from "@mui/icons-material/Search";
import EnterpriseClientSearchList from "./container/enterprise/client-search/list";

// CLIENT
import ClientIcon from "@mui/icons-material/GroupAdd";
import ClientList from "./container/enterprise/client/list";
import ClientCreate from "./container/enterprise/client/create";
import ClientEdit from "./container/enterprise/client/edit";
import ClientShow from "./container/enterprise/client/show";

// CLIENT PROSPECTION
import ClientProspectionIcon from "@mui/icons-material/CalendarMonth";
import ClientProspectionList from "./container/enterprise/client-prospection/list";
import ClientProspectionCreate from "./container/enterprise/client-prospection/create";
import ClientProspectionEdit from "./container/enterprise/client-prospection/edit";
import ClientProspectionShow from "./container/enterprise/client-prospection/show";

// CONTRACT
import ContractIcon from "@mui/icons-material/Handshake";
import ContractList from "./container/enterprise/contract/list";
import ContractCreate from "./container/enterprise/contract/create";
import ContractShow from "./container/enterprise/contract/show";

// ENTERPRISE INSTALLMENT
import EnterpriseInstallmentIcon from "@mui/icons-material/AccountBalance";
import EnterpriseInstallmentList from "./container/enterprise/installment/list";
import EnterpriseInstallmentCreate from "./container/enterprise/installment/create";
import EnterpriseInstallmentEdit from "./container/enterprise/installment/edit";
import EnterpriseInstallmentShow from "./container/enterprise/installment/show";

// ENTERPRISE INSTALLMENT PENALTY
import EnterpriseInstallmentPenaltyIcon from "@mui/icons-material/CurrencyExchange";
import EnterpriseInstallmentPenaltyList from "./container/enterprise/installment-penalty/list";
import EnterpriseInstallmentPenaltyEdit from "./container/enterprise/installment-penalty/edit";
import EnterpriseInstallmentPenaltyShow from "./container/enterprise/installment-penalty/show";

// ENTERPRISE WITHDRAWAL
import EnterpriseWtihdrawalIcon from "@mui/icons-material/Paid";
import EnterpriseWtihdrawalList from "./container/enterprise/withdrawal/list";
import EnterpriseWtihdrawalCreate from "./container/enterprise/withdrawal/create";
import EnterpriseWtihdrawalEdit from "./container/enterprise/withdrawal/edit";
import EnterpriseWtihdrawalShow from "./container/enterprise/withdrawal/show";

// ENTERPRISE CASH REGISTER
import EnterpriseCashRegisterIcon from "@mui/icons-material/PointOfSale";
import EnterpriseCashRegisterList from "./container/enterprise/cash-register/list";
import EnterpriseCashRegisterShow from "./container/enterprise/cash-register/show";

// ENTERPRISE INVOICE
import EnterpriseInvoiceIcon from "@mui/icons-material/ReceiptLong";
import EnterpriseInvoiceList from "./container/enterprise/invoice/list";
import EnterpriseInvoiceCreate from "./container/enterprise/invoice/create";
import EnterpriseInvoiceEdit from "./container/enterprise/invoice/edit";
import EnterpriseInvoiceShow from "./container/enterprise/invoice/show";

// ENTERPRISE BANKING FLOW
import EnterpriseBankingFlowIcon from "@mui/icons-material/Savings";
import EnterpriseBankingFlowList from "./container/enterprise/banking-flow/list";

// ENTERPRISE REPORT PAYMENT
import EnterpriseReportPaymentIcon from "@mui/icons-material/Assessment";
import EnterpriseReportPaymentList from "./container/enterprise/report-payment/list";

// ENTERPRISE CONTRACT INSTALLMENT
import EnterpriseContractInstallmentEdit from "./container/enterprise/contract-installment/edit";

// ENTERPRISE LOG
import EnterpriseLogIcon from "@mui/icons-material/Dvr";
import EnterpriseLogList from "./container/enterprise/log/list";
import EnterpriseLogShow from "./container/enterprise/log/show";

// CUSTOMER WITHDRAWAL
import CustomerWithdrawalIcon from "@mui/icons-material/Paid";
import CustomerWithdrawalList from "./container/customer/withdrawal/list";
import CustomerWithdrawalCreate from "./container/customer/withdrawal/create";
import CustomerWithdrawalShow from "./container/customer/withdrawal/show";

// CUSTOMER INSTALLMENT
import CustomerInstallmentIcon from "@mui/icons-material/AccountBalance";
import CustomerInstallmentList from "./container/customer/installment/list";

// CUSTOMER CLIENT
import CustomerClientIcon from "@mui/icons-material/GroupAdd";
import CustomerClientEdit from "./container/customer/client/edit";
import CustomerClientShow from "./container/customer/client/show";

// CUSTOMER CLIENT PROSPECTION
import CustomerClientProspectionIcon from "@mui/icons-material/CalendarMonth";
import CustomerClientProspectionList from "./container/customer/client-prospection/list";
import CustomerClientProspectionCreate from "./container/customer/client-prospection/create";
import CustomerClientProspectionEdit from "./container/customer/client-prospection/edit";
import CustomerClientProspectionShow from "./container/customer/client-prospection/show";

// CUSTOMER INSTALLMENT PENALTY
import CustomerInstallmentPenaltyIcon from "@mui/icons-material/CurrencyExchange";
import CustomerInstallmentPenaltyList from "./container/customer/installment-penalty/list";

// CUSTOMER ROUTE
import CustomerRouteIcon from "@mui/icons-material/AddLocationAlt";
import CustomerRouteList from "./container/customer/route/list";

// CUSTOMER REPORT DAY
import CustomerReportDayIcon from "@mui/icons-material/Report";
import CustomerReportDayList from "./container/customer/report-day/list";
import CustomerReportDayCreate from "./container/customer/report-day/create";
import CustomerReportDayEdit from "./container/customer/report-day/edit";
import CustomerReportDayShow from "./container/customer/report-day/show";

// CUSTOMER REPORT COMMISSION
import CustomerReportCommissionIcon from "@mui/icons-material/PriceCheck";
import CustomerReportCommissionList from "./container/customer/report-commission/list";

// CUSTOMER CASH REGISTER
import CustomerCashRegisterIcon from "@mui/icons-material/PointOfSale";
import CustomerCashRegisterList from "./container/customer/cash-register/list";
import CustomerCashRegisterCreate from "./container/customer/cash-register/create";
import CustomerCashRegisterShow from "./container/customer/cash-register/show";

// CUSTOMER REPORT PAYMENT
import CustomerReportPaymentIcon from "@mui/icons-material/Assessment";
import CustomerReportPaymentList from "./container/customer/report-payment/list";

import { MyLayout } from "./ra/override/layout";

import theme from "./theme";
import { lazy } from "react";

const EnterpriseInstallmentPendingPenaltyShow = lazy(() =>
  import("./container/enterprise/installment-pending-penalty/show"),
);
const EnterpriseInstallmentPendingPenaltyEdit = lazy(() =>
  import("./container/enterprise/installment-pending-penalty/edit"),
);

const i18nProvider = polyglotI18nProvider(() => portugueseMessages);

const App = () => (
  <Admin
    theme={theme}
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    dataProvider={dataProvider}
    loginPage={Login}
    dashboard={Dashboard}
    layout={MyLayout}
    title="Dashboard">
    {(permissions) =>
    {
      // ADMIN
      if (permissions && permissions.type_user === config.type_user.admin)
      {
        return [
          // ENTERPRISE
          <Resource
            name="enterprise"
            options={{ label: "Empresas", export: "enterprise" }}
            icon={EnterpriseIcon}
            list={EnterpriseList}
            create={EnterpriseCreate}
            edit={EnterpriseEdit}
            show={EnterpriseShow}
          />,

          // USER ENTERPRISE
          <Resource
            name="user-enterprise"
            options={{ label: "Usuários Empresa", export: "user-enterprise" }}
            icon={UserEnterpriseIcon}
            list={UserEnterpriseList}
            create={UserEnterpriseCreate}
            edit={UserEnterpriseEdit}
            show={UserEnterpriseShow}
          />,
        ];
      }
      // ENTERPRISE
      else if (permissions && permissions.type_user === config.type_user.enterprise)
      {
        return [
          // ENTERPRISE REGION
          <Resource
            name="enterprise-region"
            options={{
              label: "Regiões",
              export: "region",
              permissions: config.permissions.regions,
            }}
            icon={EnterpriseRegionIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.regions.list),
              )
                ? EnterpriseRegionList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.regions.create),
              )
                ? EnterpriseRegionCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.regions.edit),
              )
                ? EnterpriseRegionEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.regions.show),
              )
                ? EnterpriseRegionShow
                : null
            }
          />,

          // ENTERPRISE BILLING PERIOD
          <Resource
            name="enterprise-billing-period"
            options={{
              label: "Períodos de Pagamento",
              export: "billing-period",
              permissions: config.permissions.billing_periods,
            }}
            icon={EnterpriseBillingPeriodIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.billing_periods.list),
              )
                ? EnterpriseBillingPeriodList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.billing_periods.create),
              )
                ? EnterpriseBillingPeriodCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.billing_periods.edit),
              )
                ? EnterpriseBillingPeriodEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.billing_periods.show),
              )
                ? EnterpriseBillingPeriodShow
                : null
            }
          />,

          // ENTERPRISE PAYMENT FORM
          <Resource
            name="enterprise-payment-form"
            options={{
              label: "Formas de Pagamento",
              export: "payment-form",
              permissions: config.permissions.payment_forms,
            }}
            icon={EnterprisePaymentFormIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.payment_forms.list),
              )
                ? EnterprisePaymentFormList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.payment_forms.create),
              )
                ? EnterprisePaymentFormCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.payment_forms.edit),
              )
                ? EnterprisePaymentFormEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.payment_forms.show),
              )
                ? EnterprisePaymentFormShow
                : null
            }
          />,

          // ENTERPRISE TYPE WITHDRAWAL
          <Resource
            name="enterprise-type-withdrawal"
            options={{
              label: "Tipos de Retirada",
              export: "type-withdrawal",
              permissions: config.permissions.types_withdrawal,
            }}
            icon={EnterpriseTypeWithdrawalIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_withdrawal.list),
              )
                ? EnterpriseTypeWithdrawalList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_withdrawal.create),
              )
                ? EnterpriseTypeWithdrawalCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_withdrawal.edit),
              )
                ? EnterpriseTypeWithdrawalEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_withdrawal.show),
              )
                ? EnterpriseTypeWithdrawalShow
                : null
            }
          />,

          // ENTERPRISE TYPE INVOICE
          <Resource
            name="enterprise-type-invoice"
            options={{
              label: "Tipos de Conta",
              export: "type-invoice",
              permissions: config.permissions.types_invoice,
            }}
            icon={EnterpriseTypeInvoiceIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_invoice.list),
              )
                ? EnterpriseTypeInvoiceList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_invoice.create),
              )
                ? EnterpriseTypeInvoiceCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_invoice.edit),
              )
                ? EnterpriseTypeInvoiceEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_invoice.show),
              )
                ? EnterpriseTypeInvoiceShow
                : null
            }
          />,

          // ENTERPRISE BANK ACCOUNT
          <Resource
            name="enterprise-bank-account"
            options={{
              label: "Contas Bancárias",
              export: "bank-account",
              permissions: config.permissions.bank_accounts,
            }}
            icon={EnterpriseBankAccountIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.bank_accounts.list),
              )
                ? EnterpriseBankAccountList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.bank_accounts.create),
              )
                ? EnterpriseBankAccountCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.bank_accounts.edit),
              )
                ? EnterpriseBankAccountEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.bank_accounts.show),
              )
                ? EnterpriseBankAccountShow
                : null
            }
          />,

          // ENTERPRISE CLIENT NICHE
          <Resource
            name="enterprise-client-niche"
            options={{
              label: "Nichos",
              export: "client-niche",
              permissions: config.permissions.client_niches,
            }}
            icon={EnterpriseClientNicheIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.client_niches.list),
              )
                ? EnterpriseClientNicheList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.client_niches.create),
              )
                ? EnterpriseClientNicheCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.client_niches.edit),
              )
                ? EnterpriseClientNicheEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.client_niches.show),
              )
                ? EnterpriseClientNicheShow
                : null
            }
          />,

          // ENTERPRISE CLIENT CLASSIFICATION
          <Resource
            name="enterprise-client-classification"
            options={{
              label: "Classificações",
              export: "client-classification",
              permissions: config.permissions.client_classifications,
            }}
            icon={EnterpriseClientClassificationIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.client_classifications.list,
                ),
              )
                ? EnterpriseClientClassificationList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.client_classifications.create,
                ),
              )
                ? EnterpriseClientClassificationCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.client_classifications.edit,
                ),
              )
                ? EnterpriseClientClassificationEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.client_classifications.show,
                ),
              )
                ? EnterpriseClientClassificationShow
                : null
            }
          />,

          // ENTERPRISE REPORT DAY
          <Resource
            name="enterprise-report-day"
            options={{
              label: "Relatórios Diário",
              export: "report-day",
              permissions: config.permissions.reports_day,
            }}
            icon={EnterpriseReportDayIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.reports_day.list),
              )
                ? EnterpriseReportDayList
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.reports_day.show),
              )
                ? EnterpriseReportDayShow
                : null
            }
          />,

          // ENTERPRISE REPORT COMMISSION
          <Resource
            name="enterprise-report-commission"
            options={{
              label: "Relatórios de Comissão",
              export: "report-commission",
              permissions: config.permissions.reports_comission,
            }}
            icon={EnterpriseReportCommissionIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.reports_comission.list),
              )
                ? EnterpriseReportCommissionList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.reports_comission.create),
              )
                ? EnterpriseReportCommissionCreate
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.reports_comission.show),
              )
                ? EnterpriseReportCommissionShow
                : null
            }
          />,

          // ENTERPRISE VEHICLE
          <Resource
            name="enterprise-vehicle"
            options={{
              label: "Veículos",
              export: "vehicle",
              permissions: config.permissions.vehicles,
            }}
            icon={EnterpriseVehicleIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.vehicles.list),
              )
                ? EnterpriseVehicleList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.vehicles.create),
              )
                ? EnterpriseVehicleCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.vehicles.edit),
              )
                ? EnterpriseVehicleEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.vehicles.show),
              )
                ? EnterpriseVehicleShow
                : null
            }
          />,

          // ENTERPRISE VEHICLE MAINTENANCE
          <Resource
            name="enterprise-vehicle-maintenance"
            options={{
              label: "Manutenções",
              export: "vehicle-maintenance",
              permissions: config.permissions.vehicles_maintenances,
            }}
            icon={EnterpriseVehicleMaintenanceIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.vehicles_maintenances.list,
                ),
              )
                ? EnterpriseVehicleMaintenanceList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.vehicles_maintenances.create,
                ),
              )
                ? EnterpriseVehicleMaintenanceCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.vehicles_maintenances.edit,
                ),
              )
                ? EnterpriseVehicleMaintenanceEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.vehicles_maintenances.show,
                ),
              )
                ? EnterpriseVehicleMaintenanceShow
                : null
            }
          />,

          // ENTERPRISE VEHICLE PENALTY
          <Resource
            name="enterprise-vehicle-penalty"
            options={{
              label: "Infrações",
              export: "vehicle-penalty",
              permissions: config.permissions.vehicles_penalties,
            }}
            icon={EnterpriseVehiclePenaltyIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.vehicles_penalties.list),
              )
                ? EnterpriseVehiclePenaltyList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.vehicles_penalties.create,
                ),
              )
                ? EnterpriseVehiclePenaltyCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.vehicles_penalties.edit),
              )
                ? EnterpriseVehiclePenaltyEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.vehicles_penalties.show),
              )
                ? EnterpriseVehiclePenaltyShow
                : null
            }
          />,

          // ENTERPRISE TYPE EMPLOYEE COST
          <Resource
            name="enterprise-type-employee-cost"
            options={{
              label: "Tipos de Custo",
              export: "type-employee-cost",
              permissions: config.permissions.types_employee_cost,
            }}
            icon={EnterpriseTypeEmployeeCostIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_employee_cost.list),
              )
                ? EnterpriseTypeEmployeeCostList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.types_employee_cost.create,
                ),
              )
                ? EnterpriseTypeEmployeeCostCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_employee_cost.edit),
              )
                ? EnterpriseTypeEmployeeCostEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.types_employee_cost.show),
              )
                ? EnterpriseTypeEmployeeCostShow
                : null
            }
          />,

          // ENTERPRISE EMPLOYEE
          <Resource
            name="enterprise-employee"
            options={{
              label: "Funcionários",
              export: "employee",
              permissions: config.permissions.employees,
            }}
            icon={EnterpriseEmployeeIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.employees.list),
              )
                ? EnterpriseEmployeeList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.employees.create),
              )
                ? EnterpriseEmployeeCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.employees.edit),
              )
                ? EnterpriseEmployeeEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.employees.show),
              )
                ? EnterpriseEmployeeShow
                : null
            }
          />,

          // ENTERPRISE EMPLOYEE COST
          <Resource
            name="enterprise-employee-cost"
            options={{
              label: "Custos",
              export: "employee-cost",
              permissions: config.permissions.employee_costs,
            }}
            icon={EnterpriseEmployeeCostIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.employee_costs.list),
              )
                ? EnterpriseEmployeeCostList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.employee_costs.create),
              )
                ? EnterpriseEmployeeCostCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.employee_costs.edit),
              )
                ? EnterpriseEmployeeCostEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.employee_costs.show),
              )
                ? EnterpriseEmployeeCostShow
                : null
            }
          />,

          // CUSTOMER
          <Resource
            name="customer"
            options={{
              label: "Colaboradores",
              export: "customer",
              permissions: config.permissions.customers,
            }}
            icon={CustomerIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.customers.list),
              )
                ? CustomerList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.customers.create),
              )
                ? CustomerCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.customers.edit),
              )
                ? CustomerEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.customers.show),
              )
                ? CustomerShow
                : null
            }
          />,

          // CLIENT SEARCH
          <Resource
            name="enterprise-client"
            options={{
              label: "Pesquisar Clientes",
              export: "client",
              permissions: config.permissions.search_clients,
            }}
            icon={EnterpriseClientSearchIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.search_clients.list),
              )
                ? EnterpriseClientSearchList
                : null
            }
          />,

          // CLIENT
          <Resource
            name="client"
            options={{
              label: "Clientes",
              export: "client",
              permissions: config.permissions.clients,
            }}
            icon={ClientIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.clients.list),
              )
                ? ClientList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.clients.create),
              )
                ? ClientCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.clients.edit),
              )
                ? ClientEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.clients.show),
              )
                ? ClientShow
                : null
            }
          />,

          // CLIENT PROSPECTION
          <Resource
            name="client-prospection"
            options={{
              label: "Clientes Agendados",
              export: "client-prospection",
              permissions: config.permissions.clients_prospection,
            }}
            icon={ClientProspectionIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.clients_prospection.list),
              )
                ? ClientProspectionList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.clients_prospection.create,
                ),
              )
                ? ClientProspectionCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.clients_prospection.edit),
              )
                ? ClientProspectionEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.clients_prospection.show),
              )
                ? ClientProspectionShow
                : null
            }
          />,

          // CONTRACT
          <Resource
            name="contract"
            options={{
              label: "Contratos",
              export: "contract",
              permissions: config.permissions.contracts,
            }}
            icon={ContractIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.contracts.list),
              )
                ? ContractList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.contracts.create),
              )
                ? ContractCreate
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.contracts.show),
              )
                ? ContractShow
                : null
            }
          />,

          // ENTERPRISE INSTALLMENT
          <Resource
            name="enterprise-installment"
            options={{
              label: "Parcelas",
              export: "installment",
              permissions: config.permissions.installments,
            }}
            icon={EnterpriseInstallmentIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.installments.list),
              )
                ? EnterpriseInstallmentList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.installments.create),
              )
                ? EnterpriseInstallmentCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.installments.edit),
              )
                ? EnterpriseInstallmentEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.installments.show),
              )
                ? EnterpriseInstallmentShow
                : null
            }
          />,

          // ENTERPRISE CONTRACT INSTALLMENT
          <Resource
            name="enterprise-contract-installment"
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.installments.edit),
              )
                ? EnterpriseContractInstallmentEdit
                : null
            }
          />,

          // ENTERPRISE INSTALLMENT PENALTY
          <Resource
            name="enterprise-installment-penalty"
            options={{
              label: "Multas",
              export: "installment-penalty",
              permissions: config.permissions.installment_penalties,
            }}
            icon={EnterpriseInstallmentPenaltyIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.installment_penalties.list,
                ),
              )
                ? EnterpriseInstallmentPenaltyList
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.installment_penalties.edit,
                ),
              )
                ? EnterpriseInstallmentPenaltyEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.installment_penalties.show,
                ),
              )
                ? EnterpriseInstallmentPenaltyShow
                : null
            }
          />,

          // ENTERPRISE INSTALLMENT PENALTY
          <Resource
            name="enterprise-installment-pending-penalty"
            edit={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.installment_penalties.edit,
                ),
              )
                ? EnterpriseInstallmentPendingPenaltyEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some(
                  (k) => k.key === config.permissions.installment_penalties.show,
                ),
              )
                ? EnterpriseInstallmentPendingPenaltyShow
                : null
            }
          />,

          // ENTERPRISE WITHDRAWAL
          <Resource
            name="enterprise-withdrawal"
            options={{
              label: "Retiradas",
              export: "withdrawal",
              permissions: config.permissions.withdrawals,
            }}
            icon={EnterpriseWtihdrawalIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.withdrawals.list),
              )
                ? EnterpriseWtihdrawalList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.withdrawals.create),
              )
                ? EnterpriseWtihdrawalCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.withdrawals.edit),
              )
                ? EnterpriseWtihdrawalEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.withdrawals.show),
              )
                ? EnterpriseWtihdrawalShow
                : null
            }
          />,

          // ENTERPRISE CASH REGISTER
          <Resource
            name="enterprise-cash-register"
            options={{
              label: "Fechamentos de Caixa",
              export: "cash-register",
              permissions: config.permissions.cash_registers,
            }}
            icon={EnterpriseCashRegisterIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.cash_registers.list),
              )
                ? EnterpriseCashRegisterList
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.cash_registers.show),
              )
                ? EnterpriseCashRegisterShow
                : null
            }
          />,

          // ENTERPRISE INVOICE
          <Resource
            name="enterprise-invoice"
            options={{
              label: "Contas",
              export: "invoice",
              permissions: config.permissions.invoices,
            }}
            icon={EnterpriseInvoiceIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.invoices.list),
              )
                ? EnterpriseInvoiceList
                : null
            }
            create={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.invoices.create),
              )
                ? EnterpriseInvoiceCreate
                : null
            }
            edit={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.invoices.edit),
              )
                ? EnterpriseInvoiceEdit
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.invoices.show),
              )
                ? EnterpriseInvoiceShow
                : null
            }
          />,

          // ENTERPRISE BANKING FLOW
          <Resource
            name="enterprise-banking-flow"
            options={{
              label: "Fluxo Bancário",
              export: "banking-flow",
              permissions: config.permissions.banking_flow,
            }}
            icon={EnterpriseBankingFlowIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.banking_flow.list),
              )
                ? EnterpriseBankingFlowList
                : null
            }
          />,

          // ENTERPRISE REPORT PAYMENT
          <Resource
            name="enterprise-report-payment"
            options={{
              label: "Relatório",
              export: "report-payment",
              permissions: config.permissions.report_payment,
            }}
            icon={EnterpriseReportPaymentIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.report_payment.list),
              )
                ? EnterpriseReportPaymentList
                : null
            }
          />,

          // ENTERPRISE LOG
          <Resource
            name="enterprise-log"
            options={{
              label: "Logs",
              export: "log",
              permissions: config.permissions.logs,
            }}
            icon={EnterpriseLogIcon}
            list={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.logs.list),
              )
                ? EnterpriseLogList
                : null
            }
            show={
              permissions.permissions.some((e) =>
                e.keys.some((k) => k.key === config.permissions.logs.show),
              )
                ? EnterpriseLogShow
                : null
            }
          />,

          <CustomRoutes>
            {permissions.permissions.some((e) =>
              e.keys.some((k) => k.key === config.permissions.charts.financial),
            ) ||
            permissions.permissions.some((e) =>
              e.keys.some((k) => k.key === config.permissions.charts.clients),
            ) ||
            permissions.permissions.some((e) =>
              e.keys.some((k) => k.key === config.permissions.charts.clients_per_region),
            ) ||
            permissions.permissions.some((e) =>
              e.keys.some((k) => k.key === config.permissions.charts.clients_per_niche),
            ) ||
            permissions.permissions.some((e) =>
              e.keys.some((k) => k.key === config.permissions.charts.contracts),
            ) ||
            permissions.permissions.some((e) =>
              e.keys.some(
                (k) => k.key === config.permissions.charts.contracts_per_period,
              ),
            ) ||
            permissions.permissions.some((e) =>
              e.keys.some(
                (k) => k.key === config.permissions.charts.withdrawals_per_type,
              ),
            ) ||
            permissions.permissions.some((e) =>
              e.keys.some(
                (k) => k.key === config.permissions.charts.withdrawals_per_customer,
              ),
            ) ||
            permissions.permissions.some((e) =>
              e.keys.some((k) => k.key === config.permissions.charts.invoices_per_type),
            ) ? (
              <Route path="/charts" element={<EnterpriseChartsMonthsDashboard />} />
            ) : null}
          </CustomRoutes>,
        ];
      }
      // CUSTOMER
      else if (permissions && permissions.type_user === config.type_user.customer)
      {
        return [
          // CUSTOMER WITHDRAWAL
          <Resource
            name="customer-withdrawal"
            options={{ label: "Retiradas" }}
            icon={CustomerWithdrawalIcon}
            list={CustomerWithdrawalList}
            create={CustomerWithdrawalCreate}
            show={CustomerWithdrawalShow}
          />,

          // CUSTOMER INSTALLMENT
          <Resource
            name="customer-installment"
            options={{ label: "Parcelas" }}
            icon={CustomerInstallmentIcon}
            list={CustomerInstallmentList}
          />,

          // CUSTOMER CLIENT
          <Resource
            name="customer-client"
            options={{ label: "Clientes", show: false }}
            icon={CustomerClientIcon}
            edit={CustomerClientEdit}
            show={CustomerClientShow}
          />,

          // CUSTOMER CLIENT PROSPECTION
          <Resource
            name="customer-client-prospection"
            options={{ label: "Clientes Agendados" }}
            icon={CustomerClientProspectionIcon}
            list={CustomerClientProspectionList}
            create={CustomerClientProspectionCreate}
            edit={CustomerClientProspectionEdit}
            show={CustomerClientProspectionShow}
          />,

          // CUSTOMER INSTALLMENT PENALTY
          <Resource
            name="customer-installment-penalty"
            options={{ label: "Multas" }}
            icon={CustomerInstallmentPenaltyIcon}
            list={CustomerInstallmentPenaltyList}
          />,

          // CUSTOMER ROUTE
          <Resource
            name="customer-route"
            options={{ label: "Rotas" }}
            icon={CustomerRouteIcon}
            list={CustomerRouteList}
          />,

          // CUSTOMER REPORT DAY
          <Resource
            name="customer-report-day"
            options={{ label: "Relatório Diário" }}
            icon={CustomerReportDayIcon}
            list={CustomerReportDayList}
            create={CustomerReportDayCreate}
            edit={CustomerReportDayEdit}
            show={CustomerReportDayShow}
          />,

          // CUSTOMER REPORT COMMISSION
          <Resource
            name="customer-report-commission"
            options={{ label: "Relatório de Comissão" }}
            icon={CustomerReportCommissionIcon}
            list={CustomerReportCommissionList}
          />,

          // CUSTOMER CASH REGISTER
          <Resource
            name="customer-cash-register"
            options={{ label: "Fechamento de Caixa" }}
            icon={CustomerCashRegisterIcon}
            list={CustomerCashRegisterList}
            create={CustomerCashRegisterCreate}
            show={CustomerCashRegisterShow}
          />,

          // CUSTOMER REPORT PAYMENT
          <Resource
            name="customer-report-payment"
            options={{ label: "Relatório Financeiro" }}
            icon={CustomerReportPaymentIcon}
            list={CustomerReportPaymentList}
          />,
        ];
      }
    }}
  </Admin>
);

export default App;
