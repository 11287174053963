import { useGetOne, useRefresh } from "react-admin";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Box } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SelectCountMonthsInpunt from "../inputs/select-count-months-input";
import { setFilterChartClients, getFilterChartClients } from "../../utils/api-params";
import config from "../../config";

const barBaseConfig =
{
  highlightScope: { faded: 'global', highlighted: 'item' },
  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
};

const barBaseSlotProps =
{
  legend:
  {
    direction: "row",
    position:
    {
      vertical: "bottom",
      horizontal: "middle"
    },
    padding: 0
  }
};

const ChartMonthsClients = () =>
{
  var currentCountMonths = getFilterChartClients();
  if (!currentCountMonths)
  {
    setFilterChartClients(config.chart.max_months);
    currentCountMonths = getFilterChartClients();
  }

  const valueFormatter = (value) => (!value ? 0 : value);
  const refresh = useRefresh();
  const { data, isLoading } = useGetOne("chart/months-clients", { id: currentCountMonths });

  const setSelectedCountMonths = (countMonths) =>
  {
    setFilterChartClients(countMonths);
    refresh();
  };

  if (!data || isLoading)
  {
    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" component="h1">
            Carregando...
          </Typography>
        </AccordionSummary>
      </Accordion>
    );
  }
  else
  {
    return (
      <Accordion
        defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            flexWrap="wrap"
            alignContent="center"
            justifyContent="flex-start"
            maxWidth="100wh"
            gap={1}>
            <Typography variant="h6" component="h1">
              Clientes
            </Typography>
            <Box
              sx={{
                width: { xs: "calc(100vw - 140px)", md: "calc(100vw - 360px)" },
                maxWidth: { xs: "100%", lg: "200px" }
              }}>
              <SelectCountMonthsInpunt
                currentCountMonths={currentCountMonths}
                setCurrentCountMonths={setSelectedCountMonths}
              />
            </Box>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={12}>
              <BarChart
                dataset={data.result}
                xAxis={[{ scaleType: "band", dataKey: 'month_year' }]}
                margin={{ top: 10, bottom: 70, left: 70, right: 70 }}
                slotProps={barBaseSlotProps}
                series={
                [
                  {
                    ...barBaseConfig,
                    dataKey: 'monthly_count_clients',
                    label: 'Clientes',
                    valueFormatter
                  },
                  {
                    ...barBaseConfig,
                    dataKey: 'monthly_count_clients_with_contract',
                    label: 'Clientes com contrato',
                    valueFormatter
                  },
                  {
                    ...barBaseConfig,
                    dataKey: 'monthly_count_clients_with_out_contract',
                    label: 'Clientes sem contrato',
                    valueFormatter
                  }
                ]}
                height={400}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
};

export default ChartMonthsClients;
