import { Stack, TextField, Button } from "@mui/material";
import IconSave from "@mui/icons-material/Save";
import { useEffect, useState } from "react";
import formatValue from "../../utils/currency";
import useForRequest from "../../ra/hook/useForRequest";

const BankingFlowBalanceInput = ({ totalValue, balanceValue, ...props }) =>
{
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonShow, setButtonShow] = useState(false);
  const [valueTotal, setValueTotal] = useState(parseFloat(totalValue));
  const [valueBalance, setValueBalance] = useState(parseFloat(balanceValue));
  const [initialValueBalance, setInitialValueBalance] = useState(parseFloat(balanceValue));
  const { action } = useForRequest();

  useEffect(() =>
  {
    setValueTotal((parseFloat(totalValue) + parseFloat(valueBalance)));
  }, [totalValue]);

  function handleChange(evt)
  {
    var value = evt.currentTarget.value;
    if (value !== null && value !== undefined)
    {
      setValueBalance(parseFloat(value));
      setValueTotal((parseFloat(totalValue) + parseFloat(value)));
      setButtonShow((parseFloat(value) !== parseFloat(initialValueBalance)));
      setButtonDisabled((parseFloat(value) === parseFloat(initialValueBalance)));
    }
    else
    {
      setValueBalance("");
      setValueTotal(parseFloat(totalValue));
      setButtonShow(false);
    }
  }

  async function onClickButtonSave(evt)
  {
    evt.stopPropagation();
    setInitialValueBalance(valueBalance)
    setButtonDisabled(true);

    await action(`enterprise-banking-flow/balance`, 'POST', { value_balance: valueBalance });
    setButtonShow(false);
  }

  return (
    <Stack direction="row" alignItems="center" spacing="10px">
      <TextField
        type="number"
        value={(valueBalance != null ? valueBalance : "")}
        label="Valor em conta"
        placeholder="Valor em conta"
        style={{ maxWidth: "200px" }}
        onChange={(evt) => handleChange(evt)} />
      <Button
        color="primary"
        startIcon={<IconSave />}
        disabled={buttonDisabled}
        style={{ display: buttonShow ? "flex" : "none" }}
        onClick={onClickButtonSave}>
        Salvar
      </Button>
      <TextField
        label="Saldo"
        style={{ maxWidth: "200px" }}
        value={formatValue(valueTotal)} />
    </Stack>
  );
};

export default BankingFlowBalanceInput;
