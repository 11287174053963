import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField
} from 'react-admin';

const EnterpriseBankAccountShow = () => (
  <Show
    title="Conta Bancária">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" label="Nome" />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default EnterpriseBankAccountShow;
