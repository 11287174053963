import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  FunctionField,
  ArrayField,
  Datagrid,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";
import LinkField from '../../../components/link-field';
import DialogDeleteEnterpriseVehicleMaintenanceFile from '../../../components/dialogs/dialog-delete-enterprise-vehicle-maintenance-file';
import formatValue from "../../../utils/currency";

const EnterpriseVehicleMaintenanceShow = () => (
  <Show
    title="Manutenção">
    <TabbedShowLayout>
      <Tab label="Dados">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="id" label="Id" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="enterprise_vehicle_name" label="Veículo" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="name" label="Nome" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value" label="Valor" render={record => `${formatValue(record.value)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="date_maintenance_format" label="Data Manutenção" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="user_enterprise_name" label="Criado Por" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={6}>
            <Labeled>
              <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </Tab>
      <Tab label="Arquivos">
        <ArrayField source="files" label="">
          <Datagrid
            rowClick={false}
            bulkActionButtons={false}>
            <TextField source="name" label="Nome" />
            <LinkField source="url" label="Arquivo" blank />
            <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            <DialogDeleteEnterpriseVehicleMaintenanceFile />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default EnterpriseVehicleMaintenanceShow;
