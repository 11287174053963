import { ReferenceInput, SelectInput } from "react-admin";

const FilterEnterpriseReportCommission = [
  <ReferenceInput label="Tipo de Comissão" source="type_enterprise_report_commission" reference="setup/customer/types-report-commission" alwaysOn>
    <SelectInput
      label="Tipo de Comissão"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>
];

export default FilterEnterpriseReportCommission;
