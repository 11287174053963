import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  FunctionField,
  DateField,
  ArrayField,
  Datagrid,
  Labeled
} from 'react-admin';
import { Grid } from "@mui/material";
import LinkField from '../../../components/link-field';
import DialogDeleteEnterpriseEmployeeFile from '../../../components/dialogs/dialog-delete-enterprise-employee-file';
import formatValue from "../../../utils/currency";

const EnterpriseEmployeeShow = () => (
  <Show
    title="Funcionário">
    <TabbedShowLayout>
      <Tab label="Dados">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="id" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="employee_situation_name" label="Situação" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="name" label="Nome" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="document" label="CPF" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="birthdate_format" label="Data de Nascimento" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="phone" label="Telefone" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="cellphone" label="Celular" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="work_document" label="Carteira de Trabalho" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="social_document" label="PIS ou PASEP" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="voter_document" label="Título de Eleitor" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="identifier_document" label="Identidade" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="key_transfer" label="Chave PIX" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="user_enterprise.name" label="Criado Por" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={8} />
        </Grid>
      </Tab>

      <Tab label="Contrato">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="contract_type" label="Tipo de Contrato" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="office" label="Cargo/Função" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_salary" label="Salário" render={record => `${formatValue(record.value_salary)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="workplace" label="Local de Trabalho" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="admission_date_format" label="Data de Admissão" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="working_day" label="Jornada de Trabalho" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </Tab>

      <Tab label="Endereço">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="address.city.name" label="Cidade" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="address.zip_code" label="CEP" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="address.street" label="Rua" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="address.number" label="Número" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="address.neighborhood" label="Bairro" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="address.complement" label="Complemento" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </Tab>

      <Tab label="Arquivos">
        <ArrayField source="files" label="">
          <Datagrid
            rowClick={false}
            bulkActionButtons={false}>
            <TextField source="name" label="Nome" />
            <LinkField source="url" label="Arquivo" blank />
            <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            <DialogDeleteEnterpriseEmployeeFile />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default EnterpriseEmployeeShow;
