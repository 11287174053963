import {
  Form,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { GroupAdd, Cancel, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import Button from "../buttons/button";

function DialogConvertClientProspection({ isEnterprise = true, isClient = true })
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { on, toggle } = useToggle();
  const { loading, action } = useForRequest();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleSubmit = async () =>
  {
    const { error } = await action(`client-prospection/${record.id}/remove/prospection`, 'PUT', {});
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    refresh();
    toggle();
    notify('Cliente agendado convertido com sucesso!', { type: "success" });
  };

  if (!isEnterprise || isClient)
    return null;
  else
    return (
      <>
        <Button onClick={handleClick} color="secondary" label="CONVERTER">
          <GroupAdd />
        </Button>

        <Dialog
          fullWidth
          open={on}
          onClose={handleCloseClick}
          aria-label="Converter Cliente Agendado">
          <Form onSubmit={handleSubmit}>
            <DialogTitle>
              Tem certeza que deseja converter esse cliente agendado?
            </DialogTitle>
            <DialogActions>
              <Button
                label="Cancelar"
                disabled={loading}
                color="error"
                onClick={handleCloseClick}>
                <Cancel />
              </Button>
              <Button
                label="Confirmar"
                color="success"
                type="submit"
                disabled={loading}>
                <Save />
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    );
}

export default DialogConvertClientProspection;
