import { useGetOne } from "react-admin";
import IconCardLabel from "../components/icon-card-label";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClientIcon from "@mui/icons-material/GroupAdd";
import ClientProspectionIcon from "@mui/icons-material/CalendarMonth";
import WithContractIcon from "@mui/icons-material/Handshake";
import WithOutContractIcon from "@mui/icons-material/SubtitlesOff";
import ContractIcon from "@mui/icons-material/Gavel";
import InDayIcon from "@mui/icons-material/AttachMoney";
import LossIcon from "@mui/icons-material/MoneyOff";
import OverdueIcon from "@mui/icons-material/WatchLater";
import FinishedIcon from "@mui/icons-material/DoneAll";
import CancelIcon from "@mui/icons-material/Cancel";
import ContractsValueIcon from "@mui/icons-material/Paid";
import ContractsReceiveValueIcon from "@mui/icons-material/Savings";

const EnterpriseDashboard = () =>
{
  const { data, isLoading } = useGetOne("user-enterprise/me", { id: 1 });

  if (!data || isLoading)
  {
    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" component="h1">
            Carregando...
          </Typography>
        </AccordionSummary>
      </Accordion>
    );
  }
  else
  {
    return (
      <Accordion
        defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Typography
            variant="h6"
            component="h1">
            Geral
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={3}>
              <IconCardLabel
                to='/client?displayedFilters={"activated":true}&filter={"activated":true}'
                icon={ClientIcon}
                title="Clientes"
                subtitle={data.enterprise.number_clients}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <IconCardLabel
                to='/client-prospection?displayedFilters={"activated":true}&filter={"activated":true}'
                icon={ClientProspectionIcon}
                title="Clientes agendados"
                subtitle={data.enterprise.number_clients_prospection}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <IconCardLabel
                to='/client?displayedFilters={"with_contract":true, "activated":true}&filter={"with_contract":true, "activated":true}'
                icon={WithContractIcon}
                title="Clientes com contrato"
                subtitle={data.enterprise.number_clients_with_contract}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <IconCardLabel
                to='/client?displayedFilters={"with_out_contract":true, "activated":true}&filter={"with_out_contract":true, "activated":true}'
                icon={WithOutContractIcon}
                title="Clientes sem contrato"
                subtitle={data.enterprise.number_clients_with_out_contract}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <IconCardLabel
                to="/contract?displayedFilters={}&filter={}"
                icon={ContractIcon}
                title="Contratos"
                subtitle={data.enterprise.number_contracts}
                color="#CC8400"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <IconCardLabel
                to='/contract?displayedFilters={"status_contract_type":true}&filter={"status_contract_type":0}'
                icon={InDayIcon}
                title="Em dia"
                subtitle={data.enterprise.number_contracts_in_day}
                color="#CC8400"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <IconCardLabel
                to='/contract?displayedFilters={"status_contract_type":true}&filter={"status_contract_type":1}'
                icon={OverdueIcon}
                title="Atrasados"
                subtitle={data.enterprise.number_contracts_overdue}
                color="#CC8400"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <IconCardLabel
                to='/contract?displayedFilters={"status_contract_type":true}&filter={"status_contract_type":2}'
                icon={FinishedIcon}
                title="Finalizados"
                subtitle={data.enterprise.number_contracts_finished}
                color="#CC8400"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <IconCardLabel
                to='/contract?displayedFilters={"status_contract_type":true}&filter={"status_contract_type":3}'
                icon={CancelIcon}
                title="Cancelados"
                subtitle={data.enterprise.number_contracts_canceled}
                color="#CC8400"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <IconCardLabel
                to='/contract?displayedFilters={"status_contract_type":true}&filter={"status_contract_type":4}'
                icon={LossIcon}
                title="Prejuízo"
                subtitle={data.enterprise.number_contracts_loss}
                color="#CC8400"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <IconCardLabel
                to="/contract?displayedFilters={}&filter={}"
                icon={ContractsValueIcon}
                title="Valor investido"
                subtitle={data.enterprise.total_value_contracts}
                color="#008000"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconCardLabel
                to="/contract?displayedFilters={}&filter={}"
                icon={ContractsReceiveValueIcon}
                title="Valor a receber"
                subtitle={data.enterprise.total_receive_value_contracts}
                color="#008000"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
};

export default EnterpriseDashboard;
