import Button from "./button";
import IconFilter from "@mui/icons-material/FilterAlt";

function ButtonRefreshChart({ disabled, onClick })
{
  return (
    <Button
      label="Filtrar"
      color="primary"
      disabled={disabled}
      onClick={onClick}>
      <IconFilter />
    </Button>
  );
}

export default ButtonRefreshChart;
