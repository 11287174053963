import {
  ReferenceInput,
  SelectInput,
  DateInput
} from "react-admin";
import { getCustomersId } from '../../utils/utils';
import config from '../../config';

const CustomFilterEnterpriseReportPayment = (permissions) =>
{
  var filters = [
    <ReferenceInput label="Forma de Pagamento" source="enterprise_payment_form_id" reference="setup/enterprise/payment-forms" alwaysOn>
      <SelectInput
        label="Forma de Pagamento"
        optionText="name"
        optionValue="id"
        variant="outlined"
        margin="none"
        sx={{ backgroundColor: "white" }}/>
    </ReferenceInput>,
    <ReferenceInput label="Status da Parcela" source="status_installment_type" reference="setup/enterprise/status-installment-report-payment" alwaysOn>
      <SelectInput
        label="Status da Parcela"
        optionText="name"
        optionValue="type"
        variant="outlined"
        margin="none"
        sx={{ backgroundColor: "white" }}/>
    </ReferenceInput>
  ];

  // VERIFY PERMISSION FILTER PER CUSTOMER
  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.report_payment.filter_per_customer)))
  {
    filters.unshift(
      <ReferenceInput
        label="Colaborador"
        source="customer_id"
        reference="setup/enterprise/customers"
        filter={{ customers: getCustomersId(permissions, config.permissions.report_payment.key) }}
        alwaysOn>
        <SelectInput
          label="Colaborador"
          optionText="name"
          optionValue="id"
          variant="outlined"
          margin="none"
          sx={{ backgroundColor: "white" }}/>
      </ReferenceInput>
    );
  }

  // VERIFY PERMISSION FILTER PER DATE
  if (permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.report_payment.filter_per_date)))
    filters.push(
      <DateInput source="date_init_report" label="Data de Início" alwaysOn />,
      <DateInput source="date_end_report" label="Data Final" alwaysOn />
    );

  return filters;
};

export default CustomFilterEnterpriseReportPayment;
