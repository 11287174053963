import { useRecordContext, useNotify } from 'react-admin';
import { Button } from "@mui/material";
import IconWhatsapp from '@mui/icons-material/WhatsApp';

function ButtonClientPhoneWhatsapp()
{
  const record = useRecordContext();
  const notify = useNotify();
  var cellphone;

  if (record.client)
  {
    if (record.client.cellphone)
      cellphone = record.client.cellphone.replace(/\D/g,'').replace(/ /g,'');
    else if (record.client.secondary_cellphone)
      cellphone = record.client.secondary_cellphone.replace(/\D/g,'').replace(/ /g,'');
  }
  else if (record.cellphone)
    cellphone = record.cellphone.replace(/\D/g,'').replace(/ /g,'');
  else if (record.secondary_cellphone)
    cellphone = record.secondary_cellphone.replace(/\D/g,'').replace(/ /g,'');

  const btnClick = (e) =>
  {
    e.stopPropagation();

    if (cellphone)
      window.open("https://wa.me/55" + cellphone);
    else
      notify("Cliente não tem Whatsapp cadastrado!", { type: "error" });
  };

  return (
    <Button
      color="primary"
      onClick={btnClick}>
      <IconWhatsapp />
    </Button>
  );
}

export default ButtonClientPhoneWhatsapp;
