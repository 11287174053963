import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  Datagrid,
  TextField,
  FunctionField,
  useListController,
  usePermissions
} from 'react-admin';
import { Card } from '@mui/material';
import FilterContract from "../../../filters/enterprise/contract";
import ActionsWithPermission from "../../../ra/list/actions-with-permission";
import ButtonsContract from "../../../components/group-buttons/buttons-contract";
import ContractPanel from '../../../panels/enterprise/contract-panel';
import rowContractStyle from '../../../ra/datagrid/row/contract';
import formatValue from "../../../utils/currency";
import config from '../../../config';
const ContractPagination = props => <Pagination rowsPerPageOptions={[ 10, 25, 50, 100, 250, 500, 1000 ]} {...props} />;

const SumValues = ({ data, permissions }) =>
{
  if (permissions.permissions.some((e) => e.keys.some(k => k.key === config.permissions.contracts.show_total_values)))
  {
    var totalInitial = 0;
    var totalCost = 0;
    var totalFees = 0;
    var total = 0;
    var totalPaid = 0;
    var totalExtended = 0;
    var totalCanceled = 0;
    var totalToReceive = 0;
    var totalPenalties = 0;
    var totalGain = 0;

    data.forEach((item, i) =>
    {
      totalInitial += parseFloat(item.value_initial);
      totalCost += parseFloat(item.value_cost);
      totalFees += parseFloat(item.value_fees);
      total += parseFloat(item.value_total);
      totalPaid += parseFloat(item.value_paid);
      totalExtended += parseFloat(item.value_extended);
      totalCanceled += parseFloat(item.value_canceled);
      totalToReceive += parseFloat(item.value_to_receive);
      totalPenalties += parseFloat(item.value_penalties);
      totalGain += parseFloat(item.value_gain);
    });

    return (
      <>
        <br/>
        <div>
          <b>
            Valor Total Inicial: { formatValue(totalInitial) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor Total Investido: { formatValue(totalCost) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor Total Juros: { formatValue(totalFees) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor Total: { formatValue(total) }
          </b>
        </div>
        <div>
          <b>
            Lucro: { formatValue(totalGain) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor Recebido: { formatValue(totalPaid) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor a Receber: { formatValue(totalToReceive) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor Prorrogado: { formatValue(totalExtended) }
          </b>
        </div>
        <div>
          <b>
            Valor Cancelado: { formatValue(totalCanceled) }
          </b>
          <b style={{ "marginLeft": "20px" }}>
            Valor em Multas: { formatValue(totalPenalties) }
          </b>
        </div>
      </>
    );
  }
  else
    return null;
}

const CustomList = ({ children, actions, filters, title, ...props }) =>
{
  const { data, loading } = useListController();
  const { permissions } = usePermissions();

  if (!loading && data)
  {
    return (
      <ListBase {...props}>
        <Title title={title}/>
        <ListToolbar filters={filters} actions={actions}/>
        <Card>
        {children}
        </Card>
        <SumValues data={data} permissions={permissions}/>
        <ContractPagination />
      </ListBase>
    );
  }
  else
    return null;
}

const ContractList = () => (
  <CustomList
    title="Contratos"
    sort={{ field: 'c.id', order: 'DESC' }}
    perPage={25}
    filters={ FilterContract }
    actions={ <ActionsWithPermission /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      expandSingle
      expand={<ContractPanel />}
      rowStyle={rowContractStyle()}>
      <TextField source="status_contract_name" label="Status" sortBy="sc.name" />
      <TextField source="client_name" label="Cliente" sortBy="cl.name" />
      <TextField source="name" label="Nome" sortBy="c.name" />
      <TextField source="enterprise_billing_period_name" label="Período de Pagamento" sortBy="ebp.name" />
      <TextField source="percentage" label="Porcentagem" sortBy="c.percentage" />
      <FunctionField source="value_cost" label="Valor Investido" render={record => `${formatValue(record.value_cost)}`} sortBy="value_cost" />
      <FunctionField source="value_expected" label="Valor Esperado" render={record => `${formatValue(record.value_expected)}`} sortBy="c.value_expected" />
      <FunctionField source="value_total" label="Valor Total" render={record => `${formatValue(record.value_total)}`} sortBy="value_total" />
      <FunctionField source="value_paid" label="Valor Pago" render={record => `${formatValue(record.value_paid)}`} sortBy="value_paid" />
      <FunctionField source="value_to_receive" label="Valor a Receber" render={record => `${formatValue(record.value_to_receive)}`} sortBy="value_to_receive" />
      <FunctionField source="value_extended" label="Valor Prorrogado" render={record => `${formatValue(record.value_extended)}`} sortBy="value_extended" />
      <FunctionField source="value_canceled" label="Valor Cancelado" render={record => `${formatValue(record.value_canceled)}`} sortBy="value_canceled" />
      <FunctionField source="value_penalties" label="Valor Multas" render={record => `${formatValue(record.value_penalties)}`} sortBy="value_penalties" />
      <FunctionField source="value_gain" label="Valor do Lucro" render={record => `${formatValue(record.value_gain)}`} sortBy="value_gain" />
      <TextField source="date_init_format" label="Data de Início" sortBy="c.date_init" />
      <ButtonsContract />
    </Datagrid>
  </CustomList>
);

export default ContractList;
