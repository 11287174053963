import { Select, MenuItem } from "@mui/material";
import { useState } from "react";
import config from "../../config";

function SelectCountMonthsInput({ currentCountMonths, setCurrentCountMonths })
{
  const [selectedCountMonths, setSelectedCountMonths] = useState(currentCountMonths);
  const data = [];

  for (var i = config.chart.max_months; i >= config.chart.min_months; i--)
  {
    if (i === 1)
      data.push({ id: i, value: `Mês atual` });
    else
      data.push({ id: i, value: `Últimos ${i} meses` });
  }

  const handleClick = (e) =>
  {
    e.stopPropagation();
  };

  const handleChange = (e) =>
  {
    e.stopPropagation();

    const countMonths = e.target.value;
    if (countMonths !== selectedCountMonths)
    {
      setCurrentCountMonths(countMonths);
      setSelectedCountMonths(countMonths);
    }
  };

  return (
    <Select
      size="small"
      variant="outlined"
      value={selectedCountMonths}
      sx={{ backgroundColor: "white", width: "100%" }}
      onClick={handleClick}
      onChange={handleChange}>
      {
        data.map((item, index) => (
          <MenuItem key={item.id} value={item.id}>
            {item.value}
          </MenuItem>
        ))
      }
    </Select>
  );
}

export default SelectCountMonthsInput;
